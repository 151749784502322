import { useState, useEffect } from "react";
import { Buffer } from "buffer";

const computeHashOfData = async (data: Buffer): Promise<string> => {
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const base64Hash = Buffer.from(hashBuffer).toString("base64");
  return base64Hash;
};

export default function useParagraphs(): {
  paragraphs: Array<{ index: number; text: string; tableNestingLevel: number }>;
  paragraphsHash: string;
  refreshParagraphs: () => Promise<void>;
} {
  const [paragraphs, setParagraphs] = useState<Array<{ index: number; text: string; tableNestingLevel: number }>>([]);
  const [paragraphsHash, setParagraphsHash] = useState("");

  const setParagraphsOfDocument = async (): Promise<void> => {
    const paragraphsArr = await Word.run(
      async (context): Promise<Array<{ index: number; text: string; tableNestingLevel: number }>> => {
        context.document.body.paragraphs.load("items");
        await context.sync();
        const paragraphs = context.document.body.paragraphs.items;
        const paraTextResults = [];
        for (const paragraph of paragraphs) {
          const result = paragraph.getText();
          paraTextResults.push(result);
        }
        await context.sync();
        const body: Array<{ index: number; text: string; tableNestingLevel: number }> = [];
        for (let i = 0; i < paraTextResults.length; i++) {
          body.push({ index: i, text: paraTextResults[i].value, tableNestingLevel: paragraphs[i].tableNestingLevel });
          i += 1;
        }
        return body;
      }
    );
    setParagraphs(paragraphsArr);
    const fileBuf = Buffer.from(JSON.stringify(paragraphsArr.map((para) => para.text)));
    const hash = await computeHashOfData(fileBuf);
    setParagraphsHash(hash);
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const callSetParagraphs = async (_e: Word.ParagraphChangedEventArgs): Promise<void> => {
      await setParagraphsOfDocument();
    };
    const fn = async () => {
      await setParagraphsOfDocument();
      await Word.run(async (context) => {
        context.document.onParagraphChanged.add(callSetParagraphs);
        context.document.onParagraphAdded.add(callSetParagraphs);
        context.document.onParagraphDeleted.add(callSetParagraphs);
      });
    };
    fn();
    return () => {
      Word.run(async (context) => {
        context.document.onParagraphChanged.remove(callSetParagraphs);
        context.document.onParagraphAdded.remove(callSetParagraphs);
        context.document.onParagraphDeleted.remove(callSetParagraphs);
      });
    };
  }, []);

  const refreshParagraphs = async (): Promise<void> => {
    await setParagraphsOfDocument();
  };

  return { paragraphs, paragraphsHash, refreshParagraphs };
}
