import React from "react";
import KebabMenuIcon from "../../icons/KebabMenuIcon";
import ResolveCommentIcon from "../../icons/ResolveCommentIcon";
import DeleteIcon from "../../icons/DeleteIcon";
import findCommentById from "../../utils/wordApi/findCommentById";
import HeadlessDropDown from "../../components/HeadlessDropDown";

export default function CommentOptions({
  commentId,
  // handleEditCommentProp,
  refreshComments,
  isResolved,
}: {
  commentId: string;
  handleEditCommentProp: () => Promise<void>;
  refreshComments: () => Promise<void>;
  isResolved: boolean;
}): JSX.Element {
  // const handleEditComment = async (): Promise<void> => {
  //   await handleEditCommentProp();
  // };

  const handleResolveThread = async (): Promise<void> => {
    await Word.run(async (context): Promise<void> => {
      const commentContext = await findCommentById(context, commentId);
      if (commentContext) {
        commentContext.resolved = true;
        commentContext.load();
        await context.sync();
      }
    });
    await refreshComments();
  };

  const handleDeleteComment = async (): Promise<void> => {
    await Word.run(async (context): Promise<void> => {
      const commentContext = await findCommentById(context, commentId);
      if (commentContext) {
        commentContext.delete();
        await context.sync();
      }
    });
    await refreshComments();
  };

  const optionsClasses = "p-[0.25em] flex gap-[0.5rem] hover:bg-[#F5F5F5]";

  const options = [
    // {
    //   children: (
    //     <>
    //       <EditIcon color="#333333" />
    //       <span className="text-[#333333] text-[0.875rem] leading-[1.25rem] whitespace-nowrap">Edit comment</span>
    //     </>
    //   ),
    //   clickHandler: handleEditComment,
    //   customClasses: optionsClasses,
    // },
    {
      children: (
        <>
          <ResolveCommentIcon color="#333333" />
          <span className="text-[#333333] text-[0.875rem] leading-[1.25rem] whitespace-nowrap">Resolve thread</span>
        </>
      ),
      clickHandler: handleResolveThread,
      customClasses: `${optionsClasses} ${isResolved ? "hidden" : ""}`,
    },
    {
      children: (
        <>
          <DeleteIcon color="#333333" />
          <span className="text-[#333333] text-[0.875rem] leading-[1.25rem] whitespace-nowrap">Delete thread</span>
        </>
      ),
      clickHandler: handleDeleteComment,
      customClasses: optionsClasses,
    },
  ];

  return (
    <HeadlessDropDown
      selectElement={<KebabMenuIcon fill="#616161" />}
      options={options}
      customContainerClasses="rounded px-[0.25rem] hover:bg-[#F5F5F5] flex items-center"
      customOptionsContainerClasses="top-[1.25rem] right-[calc(100%-15px)] bg-[#FFF] p-[0.5rem] shadow-sm rounded-[0.25rem] border-[1px] border-solid border-[#D9DBDD] gap-[0.25rem]"
    />
  );
}
