import React, { SVGProps } from "react";

const DuplicateIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1.25rem" height="1.25rem" fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill={props.fill}
        d="M12.623 2.568H4.517c-.743 0-1.35.608-1.35 1.351v9.457h1.35V3.918h8.106V2.57Zm2.026 2.702H7.22c-.743 0-1.35.608-1.35 1.351v9.456c0 .743.607 1.351 1.35 1.351h7.43c.743 0 1.351-.608 1.351-1.35V6.62c0-.743-.608-1.35-1.35-1.35Zm0 10.807H7.22V6.621h7.43v9.456Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default DuplicateIcon;
