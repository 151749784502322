import replaceAll from "string.prototype.replaceall";

function removeSpecialCharactersAndSpaces(str: string): string {
  // Check if the input is a string
  if (typeof str !== "string") {
    throw new TypeError("Expected a string as input");
  }

  // Use a regular expression to match and remove all non-alphanumeric characters
  return replaceAll(str, /[^a-zA-Z0-9]/g, "");
}

const getMatchingParagraph = async (
  context: Word.RequestContext,
  paraText: string,
  paraIndex: number
): Promise<Word.Paragraph | null> => {
  const paragraphs = context.document.body.paragraphs;
  paragraphs.load("items");
  await context.sync();
  const data = [];
  for (const paragraph of paragraphs.items) {
    data.push({ result: paragraph.getText(), tableNestingLevel: paragraph.tableNestingLevel, paragraph: paragraph });
  }
  await context.sync();
  const filteredList = data.filter(
    (d) => d.tableNestingLevel === 0 && Boolean(d.result.value.replace(/\r$/, "").trim())
  );
  // filtering table para's because ai is not parsing them
  const d = filteredList[paraIndex];
  if (!d) {
    return null;
  }
  // due to result.value having \r at the end, we are trimming before checking
  /* eslint-disable-next-line office-addins/load-object-before-read */
  if (removeSpecialCharactersAndSpaces(d.result.value) === removeSpecialCharactersAndSpaces(paraText)) {
    return d.paragraph;
  }
  return null;
};

export default getMatchingParagraph;
