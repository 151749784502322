import React from "react";

function NavBarItem({
  text,
  isSelected,
  onClick,
  icon,
}: {
  text: string;
  isSelected: boolean;
  onClick: () => void;
  icon?: JSX.Element;
}): JSX.Element {
  return (
    <button
      className={`h-full px-2 flex flex-col items-center justify-center relative ${
        isSelected ? "bg-[#FAFAFA]" : "hover:bg-[#FAFAFA]"
      }`}
      onClick={onClick}
    >
      <div className="flex flex-row items-center gap-2">
        {icon}
        <span className="text-[#242424] text-[14px] font-[600]">{text}</span>
      </div>
      {isSelected && <div className="absolute bottom-0 left-0 right-0 h-[2px] bg-[#0F6CBD]" />}
    </button>
  );
}

export default NavBarItem;
