import React from "react";
import DocumentVersion from "./DocumentVersion";
import { User } from "../Comments/interface";

export default function DocumentVersions({
  workflowId,
  documentVersions,
  users,
}: {
  workflowId: string;
  documentVersions: Array<{ _id: string; versionNo: number; createdAt: number; fileName: string; createdBy?: string }>;
  users: Array<User>;
}): JSX.Element {
  return (
    <div className="pt-[2rem] overflow-y-hidden inline-flex flex-col gap-[0.75rem]">
      <div className="pl-[0.125rem] flex">
        <span className="text-[#333333] text-[0.875rem] font-[600]">Document Versions</span>
      </div>
      <div className="flex flex-col gap-[0.5rem] overflow-y-auto no-scrollbar">
        {documentVersions.map(({ _id, fileName, versionNo, createdAt, createdBy }, i) => {
          const user = users.find(({ _id }) => _id === createdBy);
          let displayName: string;
          if (!user) {
            displayName = "HyperStart CLM";
          } else {
            displayName = `${user.firstName} ${user.lastName}`;
          }
          return (
            <DocumentVersion
              key={_id}
              _id={_id}
              workflowId={workflowId}
              fileName={fileName}
              versionNo={versionNo}
              createdAt={createdAt}
              createdBy={displayName}
              isOpenDoc={documentVersions[0]._id === _id}
              isLatest={i === 0}
            />
          );
        })}
      </div>
    </div>
  );
}
