import React from "react";
import Button from "../../components/Button";
import PlusIcon from "../../icons/PlusIcon";
import TableIcon from "../../icons/TableIcon";
import { useNavigationDispatch } from "../../features/Navigation/NavigationContext";

const NotFoundPage = () => {
  const setSelectedTab = useNavigationDispatch();
  return (
    <div className="flex flex-col items-center justify-center h-full text-center gap-4 px-5 bg-[#FAFAFA]">
      <div className="flex flex-col items-center justify-center gap-2">
        <TableIcon size="large" fill="#242424" width={28} height={28} />
        <h1 className="text-[1rem] font-[600]">Organise data using tables</h1>
        <p className="text-[1rem] font-[400]">Insert tables to organise data and present key information clearly.</p>
      </div>
      <Button
        variant="secondary"
        label="Create new table"
        handleClick={async () => {
          if (setSelectedTab) {
            setSelectedTab("CreateTable");
          }
        }}
        leftIcon={<PlusIcon stroke="#242424" />}
      />
    </div>
  );
};

export default NotFoundPage;
