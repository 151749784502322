import React from "react";

export default function Input({
  value,
  setValue,
  placeholder,
  type = "text",
  title = "search fields",
}: {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  placeholder: string;
  type?: string;
  title?: string;
}): JSX.Element {
  return (
    <form className="px-[0.625rem] bg-[#FFF] rounded-[0.25rem] border-[1px] border-solid border-[#D1D1D1] flex flex-row self-stretch gap-[0.625rem]">
      <input
        type={type}
        title={title}
        placeholder={placeholder}
        className="py-[0.25rem] focus:shadow-outline border-none appearence-none focus:outline-none placeholder:text-[#707070] text-[0.875rem] leading-[1.25rem] flex flex-grow"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </form>
  );
}
