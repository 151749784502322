import React, { createContext, useContext, useState, useEffect, SetStateAction, Dispatch } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { BACKEND_URL, NODE_ENV_OVERRIDE } from "../constants";
import FullPageSpinner from "../components/FullPageSpinner";
import { EventSourceMessage, EventStreamContentType, fetchEventSource } from "@microsoft/fetch-event-source";
import isObjectEmpty from "../utils/objectIsEmpty";

/* eslint-disable no-unused-vars */
export const FetchContext = createContext<
  | ((
      url: string,
      setIsLoading: Dispatch<SetStateAction<boolean>>,
      setError: Dispatch<SetStateAction<Error | null>>,
      config: RequestInit,
      controller: AbortController,
      onMessage: (msg: EventSourceMessage) => void
    ) => Promise<any>)
  | null
>(null);
/* eslint-disable no-unused-vars */

class RetriableError extends Error {}
class FatalError extends Error {}
class TokenExpiryError extends Error {}

function FetchSSEProvider({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element {
  const { getAccessTokenSilently } = useAuth0();

  const fetcher = async (
    url: string,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    setError: Dispatch<SetStateAction<Error | null>>,
    config: RequestInit,
    controller: AbortController,
    onMessage: (msg: EventSourceMessage) => void
  ) => {
    const token = await getAccessTokenSilently();
    fetchEventSource(url, {
      method: "GET",
      ...config,
      headers: {
        // Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        ...(NODE_ENV_OVERRIDE === "local" && { "ngrok-skip-browser-warning": "true" }),
      },
      signal: controller.signal,
      async onopen(response) {
        if (response.ok && response.headers.get("content-type") === EventStreamContentType) {
          return; // everything's good
        } else if (response.status == 401) {
          throw new TokenExpiryError();
        } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
          // client-side errors are usually non-retriable:
          throw new FatalError();
        } else {
          throw new RetriableError();
        }
      },
      onmessage(msg) {
        // if the server emits an error message, throw an exception
        // so it gets handled by the onerror callback below:
        if (msg.event === "FatalError") {
          controller.abort();
          throw new FatalError(msg.data);
        }
        setIsLoading(false);
        onMessage(msg);
      },
      onclose() {
        setIsLoading(false);
        // if the server closes the connection unexpectedly, retry:
        throw new RetriableError();
      },
      onerror(err) {
        setIsLoading(false);
        if (err instanceof FatalError) {
          controller.abort();
          setError(err);
          throw err; // rethrow to stop the operation
        } else if (err instanceof TokenExpiryError) {
          fetcher(url, setIsLoading, setError, config, controller, onMessage);
          setError(err);
          throw err; // rethrow to stop the operation
          //Read more on why this is needed: https://github.com/Azure/fetch-event-source/issues/24
        } else {
          controller.abort();
          // do nothing to automatically retry. You can also
          // return a specific retry interval here.
        }
      },
    })
      //Don't throw error to caller if it's a token expiry error, error's purpose is to stop the operation
      //Read more on why this is needed: https://github.com/Azure/fetch-event-source/issues/24
      .catch((error) => {
        if (!(error instanceof TokenExpiryError)) {
          throw error;
        }
        setIsLoading(false);
        setError(error);
      });
  };

  return <FetchContext.Provider value={fetcher}>{children}</FetchContext.Provider>;
}

// eslint-disable-next-line no-unused-vars
export function useSSEFetch(
  urlRelativePath: string,
  config: RequestInit,
  fallBackData: any
): { data: any; isLoading: boolean; error: Error | null; reload: () => void } {
  const fetcher = useContext(FetchContext);
  const [data, setData] = useState<any>(fallBackData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refetchCount, setRefetchCount] = useState<number>(0);
  const [error, setError] = useState<Error | null>(null);

  const onMessage = (msg: EventSourceMessage) => {
    const response = JSON.parse(msg.data);
    if (response && !isObjectEmpty(response)) {
      setData(response);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (fetcher) {
      fetcher(`${BACKEND_URL}/api/v1/${urlRelativePath}`, setIsLoading, setError, { ...config }, controller, onMessage);
    }

    return () => controller.abort();
  }, [refetchCount]);

  const reload = () => {
    setRefetchCount(refetchCount + 1);
  };

  return { data, isLoading, error, reload };
}

export default withAuthenticationRequired(FetchSSEProvider, {
  onRedirecting: FullPageSpinner,
});
