import React from "react";

export default function WorkflowDetailsRow({
  keyItem,
  value,
  valueIcon,
  isLast,
}: {
  keyItem: string;
  value: string;
  valueIcon?: JSX.Element;
  isLast: boolean;
}): JSX.Element {
  return (
    <div
      className={`p-[0.75rem] flex flex-row self-stretch gap-[1.25rem] ${
        isLast ? "" : "border-b-[1px] border-b-solid border-b-[#E9EDF5]"
      }`}
    >
      <div className="flex-grow basis-0">
        <span className="text-[#838383] text-[0.75rem] font-[400]">{keyItem}</span>
      </div>
      <div className="flex-grow basis-0 flex items-center gap-[0.25rem]">
        {valueIcon ? valueIcon : <></>}
        <span className="text-wrap text-[#242424] text-[0.75rem] font-[400] leading-[150%]">{value}</span>
      </div>
    </div>
  );
}
