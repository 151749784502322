import React from "react";
import TagViewBox from "./TagViewBox";
import { useContentControls } from "../../hooks/useContentControl";

export default function TagView({
  tags,
  allTags,
  render,
  templateId,
  reloadTags,
}: {
  tags: Array<any>;
  allTags: Array<any>;
  render: string;
  templateId: string;
  reloadTags: () => void;
}): JSX.Element {
  const { contentControls } = useContentControls({ availableTags: tags });
  const customTags = tags.filter((tag) => tag.isCustom);
  const defaultTags = tags.filter((tag) => !tag.isCustom);

  if (render === "tables") {
    return (
      <div className="flex flex-col gap-[0.5rem] flex-grow">
        <TagViewBox
          title={`Custom ${render}`}
          tags={customTags.filter((tag) => tag.type === "table")}
          contentControls={contentControls}
          render={render}
          templateId={templateId}
          reloadTags={reloadTags}
          allTags={allTags}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-[0.5rem] flex-grow">
      <TagViewBox
        title={`Custom ${render}`}
        tags={customTags.filter((tag) => tag.type !== "table")}
        contentControls={contentControls}
        render={render}
        templateId={templateId}
        reloadTags={reloadTags}
        allTags={allTags}
      />
      <TagViewBox
        title="Default fields"
        tags={defaultTags}
        contentControls={contentControls}
        render={render}
        templateId={templateId}
        reloadTags={reloadTags}
        allTags={allTags}
      />
    </div>
  );
}
