import React from "react";
import Button from "../../components/Button";
import { useNavigationDispatch } from "../../features/Navigation/NavigationContext";

export default function TagField(): JSX.Element {
  const setSelectedTab = useNavigationDispatch();
  return (
    <div className="py-[1.25rem] px-[1rem] border-b-[1px] border-b-solid border-b-[#E0E0E0] bg-[#FAFAFA]">
      <div className="flex flex-col gap-[1.25rem]">
        <div className="flex flex-col gap-[0.25rem]">
          <span className="text-[#171C26] text-[0.875rem] font-[600] leading-[1.25rem]">Tag field in document</span>
          <span className="text-[#333333] text-[0.875rem] leading-[1.25rem]">
            Highlight a clause on the document and click here
          </span>
        </div>
        <Button
          variant="primary"
          label="Add a tag"
          handleClick={async () => {
            if (setSelectedTab) {
              setSelectedTab("TagField?mode=field");
            }
          }}
          customClasses="w-full"
        />
      </div>
    </div>
  );
}
