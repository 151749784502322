import React, { useState } from "react";
import defaultTags from "../../utils/defaultTags";
import ArrowLeftIcon from "../../icons/ArrowleftIcon";
import SearchIcon from "../../icons/SearchIcon";
import TagFieldView from "./TagFieldView";
import Button from "../../components/Button";
import { useNavigationDispatch } from "../../features/Navigation/NavigationContext";

export const signerTagNames = [
  "Counterparty Signer Name",
  "Counterparty Signer Email",
  "Counterparty Sign",
  "Internalparty Signer Name",
  "Internalparty Signer Email",
  "Internalparty Sign",
];

export default function TagFieldPage({
  templateId,
  tags,
  reloadTags,
  url,
}: {
  templateId: string;
  tags: Array<any>;
  reloadTags: () => void;
  url: URL;
}): JSX.Element {
  const mode = url.searchParams.get("mode") as string;

  const setSelectedTab = useNavigationDispatch();
  const [searchValue, setSearchValue] = useState<string>("");
  const filteredTags = tags.filter((tag) => tag.name.toLowerCase().includes(searchValue.toLowerCase()));
  const tagsMap = new Map<string, any>();
  for (const tag of filteredTags.concat(defaultTags)) {
    if (!tagsMap.has(tag._id)) {
      tagsMap.set(tag._id, tag);
    }
  }
  const customTagsToRender = Array.from(tagsMap.values()).filter((tag) => tag.isCustom);
  const defaultTagsToRender = Array.from(tagsMap.values()).filter(
    (tag) => !tag.isCustom && !signerTagNames.includes(tag.name)
  );

  const signerTagsToRender = Array.from(tagsMap.values())
    .filter((tag) => signerTagNames.includes(tag.name))
    .map((tag) => {
      return {
        ...tag,
        isSignTag: true,
        signerType: tag.name.toLowerCase().includes("internal") ? "internalparty" : "counterparty",
      };
    });

  return (
    <>
      <div className="border-b-[1px] border-b-solid border-b-[#E6E6E6] px-[1rem] py-[0.75rem] flex flex-col gap-[1rem] bg-[#FAFAFA]">
        <div className="flex gap-[0.3rem]">
          <Button
            variant="secondary"
            label=""
            leftIcon={<ArrowLeftIcon fill="#171C26" />}
            handleClick={async () => {
              if (setSelectedTab) {
                setSelectedTab("Fields");
              }
            }}
            customClasses="border-none bg-transparent hover:bg-transparent"
          />
          <p className="text-[#171C26] font-[600] leading-[2rem]">Add a tag</p>
        </div>

        <form className="px-[0.625rem] bg-[#FFF] rounded-[0.25rem] border-[1px] border-solid border-[#D1D1D1] flex flex-row self-stretch gap-[0.625rem]">
          <div className="flex self-stretch items-center">
            <SearchIcon fill="#616161" />
          </div>
          <input
            type="text"
            title="search fields"
            placeholder="Search"
            className="py-[0.25rem] focus:shadow-outline border-none appearence-none focus:outline-none placeholder:text-[#707070] text-[0.875rem] leading-[1.25rem] flex flex-grow"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </form>
      </div>
      <div className="bg-[#FAFAFA] px-[0.75rem] pt-[0.5rem] flex flex-col flex-grow overflow-y-auto">
        {mode !== "table" && (
          <TagFieldView
            title="Signer Tags"
            tags={signerTagsToRender}
            templateTags={tags}
            templateId={templateId}
            reloadTags={reloadTags}
            mode={mode}
          />
        )}
        <TagFieldView
          title="Custom Fields"
          tags={customTagsToRender}
          templateTags={tags}
          templateId={templateId}
          reloadTags={reloadTags}
          mode={mode}
        />
        {mode !== "table" && (
          <TagFieldView
            title="Default Fields"
            tags={defaultTagsToRender}
            templateTags={tags}
            templateId={templateId}
            reloadTags={reloadTags}
            mode={mode}
          />
        )}
      </div>
    </>
  );
}
