import React from "react";

const FilledGreenCheckIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9.99955" cy="10" r="8.48881" fill="#14804A" />
      <path
        d="M6.99316 10.5305L8.58482 12.299L13.0061 7.70093"
        stroke="white"
        strokeWidth="1.27332"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FilledGreenCheckIcon;
