import React, { createContext, useContext } from "react";
import useParagraphs from "../hooks/useParagraphs";

const initialData = {
  paragraphs: [],
  paragraphsHash: "",
  refreshParagraphs: async () => {
    return;
  },
};

const ParagraphsContext = createContext<{
  paragraphs: Array<{ index: number; text: string; tableNestingLevel: number }>;
  paragraphsHash: string;
  refreshParagraphs: () => Promise<void>;
}>(initialData);

export function ParagraphsProvider({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element {
  const { paragraphs, paragraphsHash, refreshParagraphs } = useParagraphs();
  return (
    <ParagraphsContext.Provider value={{ paragraphs, paragraphsHash, refreshParagraphs }}>
      {children}
    </ParagraphsContext.Provider>
  );
}

export function useParagraphsData(): {
  paragraphs: Array<{ index: number; text: string; tableNestingLevel: number }>;
  paragraphsHash: string;
  refreshParagraphs: () => Promise<void>;
} {
  return useContext(ParagraphsContext);
}
