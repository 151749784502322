import React from "react";
import AppDescriptionList from "./AppDescriptionList";

const AppDescription = ({ isPopUpBlocked }: { isPopUpBlocked: boolean }): JSX.Element => {
  const AppDescriptions = ["Make edits & track changes", "Effortless review & negotiation", "AI you can trust"];
  return (
    <>
      <div className="flex flex-col items-center gap-[0.5rem]">
        <span className="text-[#333333] font-[600]">Welcome to HyperStart Assist</span>
        <span className="text-[#333333] text-center">
          {isPopUpBlocked
            ? "Popups seem to be blocked on your browser. Please click on Authenticate to start using HyperStart Assist on MS Word"
            : "Use HyperStart Assist to make your contract management seamless within MS Word"}
        </span>
      </div>
      <div className="flex flex-col items-start gap-[0.75rem]">
        {AppDescriptions.map((description) => (
          <AppDescriptionList description={description} key={description} />
        ))}
      </div>
    </>
  );
};

export default AppDescription;
