import React from "react";

import Menu from "./Menu";
import WorkflowDetails from "./WorkflowDetails";
import DocumentVersions from "./DocumentVersions";
import { counterPartyNameTagId } from "../../constants";
import { useWorkflowData } from "../../features/WorkflowDataProvider/WorkflowDataProvider";
import { useOrgData } from "../../features/OrgDataProvider/OrgDataProvider";

export default function Overview(): JSX.Element {
  const { data: orgData } = useOrgData();
  const { users } = orgData;

  const { data: workflowData } = useWorkflowData();
  const { workflowResponse } = workflowData;
  const { workflow } = workflowResponse;

  const workflowDetails = [
    {
      key: "Counterparty Name",
      value:
        workflow?.inputs
          ?.find((input: { tagId: string; value: string | number }) => input.tagId === counterPartyNameTagId)
          ?.value?.toString() || "",
    },
    {
      key: "Status",
      value: workflow?.status || "",
      valueIcon: (
        <div className="pt-[0.125rem] flex items-center gap-[0.625rem]">
          <div className="rounded-[0.125rem] bg-[#4945C6] w-[0.375rem] h-[0.375rem]"></div>
        </div>
      ),
    },
    {
      key: "Sub-status",
      value: workflow?.subStatus || "",
    },
  ];

  const { data } = useWorkflowData();
  const { documentVersionsResponse } = data;

  const documentVersions = documentVersionsResponse?.allDocuments || [];

  return (
    <div className="flex-grow overflow-y-hidden flex flex-col bg-[#FAFAFA] p-[0.75rem]">
      <Menu workflowId={workflow?._id || ""} />
      <WorkflowDetails workflowDetails={workflowDetails} workflowName={workflow?.name || ""} />
      <DocumentVersions workflowId={workflow._id} documentVersions={documentVersions} users={users} />
    </div>
  );
}
