import React, { useContext, useState } from "react";

import Author from "../Comments/Author";
import { User } from "../Comments/interface";
import { ClauseApproverAssigned } from "../RequestApproval/interface";
import { ClauseApprovalStatus } from "./interface";
import TickIcon from "../../icons/TickIcon";
import Button from "../../components/Button";
import { FetchContext } from "../../api/FetchContext";
import { useNotificationToastDispatch } from "../../features/NotificationToast/NotificationContext";
import findCommentById from "../../utils/wordApi/findCommentById";
import { BACKEND_URL } from "../../constants";
import ActionPendingIcon from "../../icons/ActionPendingIcon";
import LineIcon from "../../icons/LineIcon";
import CommentInput from "../Comments/CommentInput";
import CloseActionIcon from "../../icons/CloseActionIcon";
import PostIcon from "../../icons/PostIcon";
import convertMentionText from "../../utils/convertMentionText";
import CommentRenderer from "../Comments/CommentRenderer";
import useCurrentUser from "../../hooks/useCurrentUser";
import HeadlessDropDown from "../../components/HeadlessDropDown";
import KebabMenuIcon from "../../icons/KebabMenuIcon";
import DeleteIcon from "../../icons/DeleteIcon";
import getApproverName from "../../utils/getApproverName";

export default function ClauseApproval({
  workflowId,
  clauseApproval,
  users,
  groups,
  reloadApprovals,
}: {
  workflowId: string;
  clauseApproval: any;
  users: Array<User>;
  groups: Array<any>;
  reloadApprovals: () => Promise<void>;
}): JSX.Element {
  const fetcher = useContext(FetchContext);
  const notificationDispatch = useNotificationToastDispatch();
  const creator = users.find((user) => user._id === clauseApproval.createdBy) as User;
  const user = useCurrentUser({ users });
  const isApproved = (clauseApproval.status as ClauseApprovalStatus) === "approved";
  const isUserApprover = Boolean(
    clauseApproval.approvers.find((approver: ClauseApproverAssigned) => {
      switch (approver.type) {
        case "group": {
          return user.groups.find((group) => group === approver.approverId);
        }
        case "user": {
          return user._id === approver.approverId;
        }
      }
    })
  );
  const canUserDeleteClauseApproval =
    (clauseApproval.status as ClauseApprovalStatus) === "requested" &&
    (user.isAdmin || isUserApprover || user._id === creator._id);

  const [shouldShowFullClause, setShouldShowFullClause] = useState<boolean>(false);
  const [isReplying, setIsReplying] = useState<boolean>(false);
  const [replyText, setReplyText] = useState<string>("");

  const submitApproval = async (e: React.MouseEvent<HTMLElement>): Promise<void> => {
    e.preventDefault();
    if (fetcher) {
      const res = await fetcher(`${BACKEND_URL}/api/v1/clauseApproval/updateStatus`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          workflowId,
          clauseApprovalId: clauseApproval._id,
          status: "approved" as ClauseApprovalStatus,
        }),
      }).catch((e: unknown) => {
        if (notificationDispatch && e instanceof Error) {
          notificationDispatch({ type: "error", message: e.message || "Something went wrong" });
        }
      });

      if (res?.status === "success" && notificationDispatch) {
        if (clauseApproval.commentId) {
          await Word.run(async (context): Promise<void> => {
            const comment = await findCommentById(context, clauseApproval.commentId);
            if (comment && comment.resolved === false) {
              try {
                comment.resolved = true;
                comment.load();
                await comment.context.sync();
              } catch (e) {
                // comment.delete();
              }
            }
          });
        }
        notificationDispatch({ type: "success", message: "Approval recorded successfully" });
      }
      await reloadApprovals();
    }
  };

  const handleReply = async (e: React.MouseEvent<HTMLElement>): Promise<void> => {
    e.preventDefault();
    if (fetcher) {
      const transformedReplyText = convertMentionText(users, replyText);
      if (clauseApproval.commentId) {
        await Word.run(async (context): Promise<void> => {
          const comment = await findCommentById(context, clauseApproval.commentId);
          if (comment && comment.resolved === false) {
            comment.reply(transformedReplyText);
            comment.load();
            await context.sync();
          }
        });
      }
      const res = await fetcher(`${BACKEND_URL}/api/v1/clauseApproval/addMessage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          workflowId,
          clauseApprovalId: clauseApproval._id,
          message: transformedReplyText,
        }),
      }).catch((e: unknown) => {
        if (notificationDispatch && e instanceof Error) {
          notificationDispatch({ type: "error", message: e.message || "Something went wrong" });
        }
      });

      if (res?.status === "success" && notificationDispatch) {
        notificationDispatch({ type: "success", message: "Message added successfully" });
      }
      await reloadApprovals();
    }
  };

  const handleDeleteClauseApproval = async (): Promise<void> => {
    if (fetcher) {
      const res = await fetcher(`${BACKEND_URL}/api/v1/clauseApproval/updateStatus`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          workflowId,
          clauseApprovalId: clauseApproval._id,
          status: "deleted" as ClauseApprovalStatus,
        }),
      }).catch((e: unknown) => {
        if (notificationDispatch && e instanceof Error) {
          notificationDispatch({ type: "error", message: e.message || "Something went wrong" });
        }
      });

      if (res?.status === "success" && notificationDispatch) {
        await Word.run(async (context): Promise<void> => {
          const commentContext = await findCommentById(context, clauseApproval.commentId);
          if (commentContext) {
            commentContext.delete();
            await context.sync();
          }
        });
        notificationDispatch({ type: "success", message: "Approval deleted successfully" });
      }
      await reloadApprovals();
    }
  };

  const optionsClasses = "p-[0.25em] flex gap-[0.5rem] hover:bg-[#F5F5F5]";

  const options = [];

  if (canUserDeleteClauseApproval) {
    options.push({
      children: (
        <>
          <DeleteIcon color="#333333" />
          <span className="text-[#333333] text-[0.875rem] leading-[1.25rem] whitespace-nowrap">Delete thread</span>
        </>
      ),
      clickHandler: handleDeleteClauseApproval,
      customClasses: optionsClasses,
    });
  }

  return (
    <div
      className="cursor-pointer rounded-[0.25rem] border-[1px] border-solid border-[#D9DBDD] bg-[#FFF] p-[0.75rem] flex flex-col gap-[0.75rem]"
      onClick={async () => {
        await Word.run(async (context) => {
          const commentRef = await findCommentById(context, clauseApproval.commentId);
          if (!commentRef) {
            const body = context.document.body;
            body.load("text");
            await body.context.sync();
            const charRanges = body.search(clauseApproval.docText.split(".")[0], {
              matchWildcards: true,
              matchCase: false,
              ignorePunct: true,
              ignoreSpace: true,
            });
            charRanges.load();
            await charRanges.context.sync();
            if (!charRanges.isNullObject) {
              charRanges.getFirst().select();
            }
            return;
          }
          commentRef.getRange().select();
        });
      }}
    >
      <div className="flex flex-col self-stretch gap-[0.75rem]">
        <div className="flex justify-between self-stretch">
          <Author user={creator} creationDate={Math.floor(Date.parse(clauseApproval.createdAt) / 1000)} />
          {clauseApproval.status === "requested" && options.length > 0 ? (
            <HeadlessDropDown
              selectElement={<KebabMenuIcon fill="#616161" />}
              options={options}
              customContainerClasses="rounded px-[0.25rem] hover:bg-[#F5F5F5] flex items-center"
              customOptionsContainerClasses="top-[1.25rem] right-[calc(100%-15px)] bg-[#FFF] p-[0.5rem] shadow-sm rounded-[0.25rem] border-[1px] border-solid border-[#D9DBDD] gap-[0.25rem]"
            />
          ) : (
            <></>
          )}
        </div>
        <span className="text-[#333] text-[0.875rem] leading-[1.25rem]">{clauseApproval.description}</span>
      </div>
      <div className="flex flex-col gap-[0.75rem] self-stretch">
        <div className="rounded-[0.25rem] bg-[#F5FAFF] p-[0.5rem] flex flex-col gap-[0.75rem] self-stretch items-center justify-center">
          <div
            className="self-stretch cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setShouldShowFullClause((prev) => !prev);
            }}
          >
            <span
              className={`text-[#616161] text-[0.75rem] leading-[1.25rem] ${
                shouldShowFullClause ? "" : "line-clamp-3"
              }`}
            >
              {clauseApproval.docText}
            </span>
          </div>
          {isApproved ? (
            <div className="rounded-[0.25rem] shadow-md bg-[#fff] px-[0.375rem] py-[0.5rem] flex self-stretch items-center justify-center">
              <div className="flex gap-[0.25rem]">
                <TickIcon width="1.25rem" height="1.25rem" fillColor="#14804A" />
                <span className="text-[#616161] text-[0.875rem] leading-[1.25rem]">Approved</span>
              </div>
            </div>
          ) : isUserApprover ? (
            <div className="flex flex-grow self-stretch justify-center gap-[0.5rem]">
              <Button variant="primary" label="Approve" handleClick={submitApproval} />
              <Button variant="secondary" label="Suggest Changes" handleClick={async () => setIsReplying(true)} />
            </div>
          ) : (
            <div className="rounded-[0.25rem] shadow-md bg-[#FFF] px-[0.5rem] py-[0.75rem] flex flex-grow self-stretch justify-between items-center">
              <Author
                user={{
                  firstName: getApproverName({ approver: clauseApproval.approvers[0], users, groups }),
                  lastName: "",
                  imageUrl: "",
                }}
              />
              <div className="flex gap-[0.5rem]">
                <ActionPendingIcon stroke="#333" />
                <span className="text-[#616161] text-[0.875rem] leading-[1.25rem]">Approval pending</span>
              </div>
            </div>
          )}
        </div>
        <div className="self-stretch h-0 w-1 bg-[#E0E0E0]">
          <LineIcon stroke="#E0E0E0" height={"5px"} />
        </div>
        {isReplying ? (
          <>
            {clauseApproval.messages.map(
              (message: { author: string; createdAt: string; message: string }, i: number) => {
                const user = users.find((user) => user._id === message.author);
                return (
                  <CommentRenderer
                    key={i + 1}
                    authorName={user ? `${user.firstName} ${user.lastName}` : "Unknown Author"}
                    creationDate={Math.floor(Date.parse(message.createdAt) / 1000)}
                    text={message.message}
                    isLastComment={i + 1 === clauseApproval.messages.length}
                  />
                );
              }
            )}
            <CommentInput
              key="newReply"
              isNewComment={false}
              users={users}
              commentText={replyText}
              setCommentText={setReplyText}
              placeholder="Reply"
            />
            {replyText.length > 0 ? (
              <div className="self-stretch flex justify-end gap-[0.25rem]">
                <Button
                  key="clear"
                  variant="secondary"
                  label=""
                  leftIcon={<CloseActionIcon width="1.25rem" height="1.25rem" fill="#616161" />}
                  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                  handleClick={async (_e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                    setReplyText("");
                  }}
                  isDisabled={replyText === ""}
                />
                <Button
                  key="sendReply"
                  buttonType="submit"
                  variant="primary"
                  label=""
                  leftIcon={<PostIcon width="1.25rem" height="1.25rem" fill="none" />}
                  handleClick={handleReply}
                  isDisabled={replyText === ""}
                />
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <div
            className="cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setIsReplying((prev) => !prev);
            }}
          >
            <span className="text-[#0F6CBD] text-[0.875rem] leading-[1.3125rem]">Reply</span>
          </div>
        )}
      </div>
    </div>
  );
}
