/* eslint-disable no-unused-vars */
export enum IConditionType {
  AND = "AND",
  ANY = "ANY",
}
/* eslint-disable no-unused-vars */
export interface IConditionOption {
  field: {
    label: string;
    value: string;
  };
  relationship: {
    label: "Is Equal To" | "Is Not Equal To" | "Greater than or equal to" | "Less than or equal to";
    value: "equal" | "notEqual" | "greaterThanInclusive" | "lessThanInclusive";
  };
  value: string;
}

export interface IConditionTypeOption {
  label: "AND" | "OR";
  value: "all" | "any";
}
