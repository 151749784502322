import React, { SVGProps } from "react";
const GroupListIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" {...props}>
    <path
      fill={props.fill}
      d="M2.5 3a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 0-1H3V4h1.5a.5.5 0 0 0 0-1h-2Zm0 8a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 0-1H3v-4h1.5a.5.5 0 0 0 0-1h-2ZM6 4.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5Zm.5 7.5a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11ZM6 7.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5Zm.5 7.5a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11Z"
    />
  </svg>
);
export default GroupListIcon;
