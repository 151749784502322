import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import LoginPage from "./features/Auth/LoginPage";
import Progress from "./components/Progress";
import FetchProvider from "./api/FetchContext";
import FetchSSEProvider from "./api/FetchSSEContext";
import { Mode } from "./features/Navigation/NavigationContext";
import ProtectedRoutes from "./ProtectedRoutes";
import FullPageSpinner from "./components/FullPageSpinner";
import getWebDavPayload from "./utils/getWebDavPayload";
import { OrgDataProvider } from "./features/OrgDataProvider/OrgDataProvider";

export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

export default function Layout(props: AppProps): JSX.Element {
  const { isLoading, isAuthenticated } = useAuth0();
  const { title, isOfficeInitialized } = props;
  const shouldSendCode = sessionStorage.getItem("shouldSendCode") === "true";
  const isLoadedInsideWord = Office?.context?.document?.url;
  const notLoadedInIFrame = window.location === window.parent.location;
  const loadedInWebWithoutDocWithoutCode = !isLoadedInsideWord && notLoadedInIFrame && shouldSendCode;
  const shouldRedirectToLogin = loadedInWebWithoutDocWithoutCode || (!isLoading && !isAuthenticated);
  let mode: Mode = "Workflow";
  const payload = getWebDavPayload(Office?.context?.document?.url || "");

  if (payload.templateId) {
    mode = "Template";
  }

  if (isLoading) {
    <FullPageSpinner />;
  }

  return (
    <>
      {!isOfficeInitialized && (
        <Progress
          title={title}
          logo={require("./../../assets/logo-filled.png")}
          message="Please sideload your addin to see app body."
        />
      )}
      {shouldRedirectToLogin ? (
        <LoginPage />
      ) : (
        <FetchSSEProvider>
          <FetchProvider>
            <OrgDataProvider>
              <ProtectedRoutes mode={mode} />
            </OrgDataProvider>
          </FetchProvider>
        </FetchSSEProvider>
      )}
    </>
  );
}
