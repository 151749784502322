import React, { useState } from "react";
import Button from "../../components/Button";
import SearchIcon from "../../icons/SearchIcon";
import ShowConditions from "./ShowConditions";
import { useNavigationDispatch } from "../../features/Navigation/NavigationContext";
import NotFoundPage from "./NotFoundPage";

export default function Conditions({
  conditions,
  reloadConditions,
}: {
  conditions: Array<any>;
  reloadConditions: () => void;
}): JSX.Element {
  const setSelectedTab = useNavigationDispatch();
  const [searchValue, setSearchValue] = useState<string>("");
  const conditionsToRender = conditions?.filter((condition) =>
    condition.name.trim().toLowerCase().includes(searchValue.trim().toLowerCase())
  );

  if (!conditionsToRender || conditionsToRender?.length === 0) {
    return <NotFoundPage />;
  }

  return (
    <div className="overflow-y-auto flex flex-col bg-[#FAFAFA] flex-grow">
      <div className="px-[1rem] py-[1.25rem] border-b-[1px] border-b-solid border-b-[#E0E0E0] flex flex-col gap-[1.25rem] bg-[#FAFAFA]">
        <div className="flex flex-col gap-[0.25rem]">
          <span className="text-[#171C26] text-[0.875rem] font-[600] leading-[1.25rem]">Tag condition in document</span>
          <span className="self-stretch text-[0.875rem] leading-[1.25rem]">
            Highlight a clause on the document and click here
          </span>
        </div>
        <Button
          variant="primary"
          handleClick={async () => {
            if (setSelectedTab) {
              setSelectedTab("TagCondition");
            }
          }}
          label="Add a condition"
        />
      </div>
      <form className="p-[1rem] bg-[#FAFAFA] flex flex-col gap-[0.75rem]">
        <div className="px-[0.625rem] bg-[#FFF] rounded-[0.25rem] border-[1px] border-solid border-[#D1D1D1] flex flex-row self-stretch gap-[0.625rem]">
          <div className="flex self-stretch items-center">
            <SearchIcon fill="#616161" />
          </div>
          <input
            type="text"
            title="search fields"
            placeholder="Search"
            className="py-[0.25rem] focus:shadow-outline border-none appearence-none focus:outline-none placeholder:text-[#707070] text-[0.875rem] leading-[1.25rem] flex flex-grow"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <ShowConditions conditions={conditionsToRender} reloadConditions={reloadConditions} />
      </form>
    </div>
  );
}
