import React, { SVGProps } from "react";

const ChevronUpIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "1.25rem"}
    height={props.height || "1.25rem"}
    {...props}
  >
    <path
      fill={props.fill}
      d="M4.147 12.854a.5.5 0 0 1-.001-.707L9.61 6.663a.55.55 0 0 1 .779 0l5.465 5.484a.5.5 0 0 1-.708.706L10 7.688l-5.146 5.165a.5.5 0 0 1-.707.001Z"
    />
  </svg>
);

export default ChevronUpIcon;
