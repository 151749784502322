import React, { useContext, useEffect, useState } from "react";
import { DirectionalHint, TooltipHost } from "@fluentui/react";

import Button from "../../components/Button";
import { BACKEND_URL } from "../../constants";
// import getDocumentData from "../../utils/wordApi/getDocumentData";
import DismissIcon from "../../icons/DismissIcon";
import isDeviationCompliant from "../../utils/isCompliant";
import Deviation from "../Deviation";
import FilledGreenCheckIcon from "../../icons/FilledGreenCheckIcon";
import PlaybookEmptyState from "../../icons/PlaybookEmptyState";
import getMatchingParagraph from "../../utils/wordApi/getMatchingParagraph";
import { useWorkflowData } from "../../features/WorkflowDataProvider/WorkflowDataProvider";
import getFileAsyncHandler from "../../utils/wordApi/getFileAsyncHandler";
import { FetchContext } from "../../api/FetchContext";
import { useNotificationToastDispatch } from "../../features/NotificationToast/NotificationContext";
import PlaybookRunningLoader from "./PlaybookRunningLoader";
import { useParagraphsData } from "../../Providers/ParagraphsProvider";

export default function Playbook(): JSX.Element {
  const fetcher = useContext(FetchContext);
  const notificationDispatch = useNotificationToastDispatch();
  // const [paragraphs, setParagraphs] = useState<Array<any>>([]);
  const [activeDeviationId, setActiveDeviationId] = useState<string>("");

  const { data: workflowData, reload: reloadWorkflowData, isRefetchingData } = useWorkflowData();

  const { deviations: allDeviations, playbooks, workflowResponse } = workflowData;
  const { workflow } = workflowResponse;
  const [isPollingPlayBooks, setIsPollingPlayBooks] = useState<boolean>(false);
  // const { paragraphs, paragraphsHash, refreshParagraphs } = useParagraphsData();
  const { paragraphsHash } = useParagraphsData();

  const deviations = allDeviations.filter(
    (deviation) => deviation.documentHash && deviation.documentHash === workflow.playbookDocumentHash
  );

  const isDocHashEqual = !paragraphsHash || paragraphsHash === deviations[0]?.documentHash;

  let noOfCompliantDeviations = 0;
  let noOfNonCompliantDeviations = 0;

  const [filter, setFilter] = useState<"all" | "compliant" | "non-compliant">("all");

  const filteredDeviations = deviations.filter((deviation: any) => {
    if (filter === "all") return true;
    const isCompliant = isDeviationCompliant(deviation);
    return (filter === "compliant" && isCompliant) || (filter === "non-compliant" && !isCompliant);
  });

  for (const deviation of deviations) {
    if (isDeviationCompliant(deviation)) {
      noOfCompliantDeviations += 1;
    } else {
      noOfNonCompliantDeviations += 1;
    }
  }

  useEffect(() => {
    if (!isPollingPlayBooks) {
      if (!workflow.playbookTriggerAuthor) {
        return;
      }
      if (deviations.length === 0) {
        setIsPollingPlayBooks(true);
      }
      return;
    }
    if (deviations.length > 0) {
      setIsPollingPlayBooks(false);
      return;
    }
    const interval = setInterval(async () => {
      await reloadWorkflowData();
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [isPollingPlayBooks, deviations.length, workflow.playbookTriggerAuthor]);

  // useEffect(() => {
  //   const fn = async () => {
  //     const data = await Word.run(getDocumentData);
  //     setParagraphs(data);
  //   };
  //   fn();
  // }, []);

  // const runPlayBooks = async () => {
  //   if (fetcher && notificationDispatch) {
  //     const res = await fetcher(`${BACKEND_URL}/api/v1/deviation/runPlayBooks`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //       },
  //       body: JSON.stringify({
  //         workflowId: workflow._id,
  //         chunks: paragraphs.map((para) => para.text),
  //       }),
  //     }).catch((e: unknown) => {
  //       if (e instanceof Error) {
  //         notificationDispatch({ type: "error", message: e.message || "Something went wrong" });
  //       }
  //     });
  //     if (res) {
  //       reloadWorkflowData();
  //     }
  //   }
  // };

  const upload = async (fileBuf: Uint8Array, filename = "default.docx"): Promise<void> => {
    const formData = new FormData();
    formData.append(
      "file",
      new File(
        [new Blob([fileBuf], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" })],
        filename
      )
    );
    formData.append("paragraphsHash", paragraphsHash);
    if (fetcher && notificationDispatch) {
      const docUrl = new URL(Office?.context?.document?.url || "");
      const token = docUrl.pathname.split("/")?.at(-2) || "";
      await fetcher(`${BACKEND_URL}/api/v1/deviation/runPlaybooksV2`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          token: token,
        },
        body: formData,
      }).catch((e: unknown) => {
        if (e instanceof Error) {
          notificationDispatch({ type: "error", message: e.message || "Something went wrong" });
        }
      });
    }
  };

  const runPlayBooksV2 = async () => {
    const fileBuf = await new Promise<Uint8Array>((resolve) => {
      Office.context.document.getFileAsync(
        Office.FileType.Compressed,
        { sliceSize: 65536 /*64 KB*/ },
        async (result: Office.AsyncResult<Office.File>) => {
          const fileBuf = await getFileAsyncHandler(result);
          resolve(fileBuf);
        }
      );
    });
    await upload(fileBuf);
  };

  if (activeDeviationId) {
    return <Deviation deviationId={activeDeviationId} navigateBack={() => setActiveDeviationId("")} />;
  }

  if (isPollingPlayBooks) {
    return <PlaybookRunningLoader playbooksLength={playbooks.length} />;
  }

  return (
    <div
      className={`overflow-y-auto flex flex-col justify-between ${isRefetchingData ? "bg-white/40" : "bg-white/100"}`}
    >
      {/* <label htmlFor="textarea">abc</label>
      <Button label="refresh paragraphs" variant="primary" handleClick={refreshParagraphs}/>
      <textarea
        id="textarea"
        value={JSON.stringify(
          paragraphs.filter((d) => d.tableNestingLevel === 0 && Boolean(d.text.replace("\r", ""))).map((para, i) => ({...para, filteredIndex: i })),
          null,
          4,
        )}
      ></textarea> */}
      {deviations.length > 0 ? (
        <div className="overflow-y-auto flex-grow flex flex-col h-[100vh]">
          {isDocHashEqual ? (
            <></>
          ) : (
            <div className="border-[#9FD89F] border-b border-t bg-[#F1FAF1] text-[#242424] text-[12px] p-[12px] w-full flex items-center justify-center">
              Document has been edited since playbook was run, please rerun for correct predictions
            </div>
          )}
          <div className="p-[0.75rem] border-b-[1px] border-b-solid border-b-[#E0E0E0] flex gap-3">
            <TooltipHost
              content={<span className="text-[#FFF] text-[0.75rem]">See only compliant clauses</span>}
              directionalHint={DirectionalHint.bottomCenter}
              tooltipProps={{
                calloutProps: {
                  styles: {
                    beak: { background: "#333333" },
                    beakCurtain: { background: "#333333" },
                    calloutMain: { background: "#333333" },
                  },
                },
              }}
              hidden={noOfCompliantDeviations === 0}
            >
              <button
                className={`py-[0.25rem] px-[0.75rem] rounded-[4rem] hover:border-[1px] hover:border-solid hover:border-[#D9DBDD] ${
                  filter === "compliant" ? "border-[1px] border-solid border-[#0F6CBD]" : "bg-[#F8F8FA]"
                } flex gap-1 ${noOfCompliantDeviations === 0 ? "cursor-not-allowed" : "cursor-pointer"}`}
                onClick={() => setFilter(filter === "compliant" ? "all" : "compliant")}
                disabled={noOfCompliantDeviations === 0}
              >
                <FilledGreenCheckIcon />
                <span className="text-[0.75rem] font-[600] text-[#333333]">{`${noOfCompliantDeviations} Compliant`}</span>
              </button>
            </TooltipHost>
            <TooltipHost
              content={<span className="text-[#FFF] text-[0.75rem]">See only not compliant clauses</span>}
              directionalHint={DirectionalHint.bottomCenter}
              tooltipProps={{
                calloutProps: {
                  styles: {
                    beak: { background: "#333333" },
                    beakCurtain: { background: "#333333" },
                    calloutMain: { background: "#333333" },
                  },
                },
              }}
              hidden={noOfNonCompliantDeviations === 0}
            >
              <button
                className={`p-[0.25rem] rounded-[4rem] hover:border-[1px] hover:border-solid hover:border-[#D9DBDD] ${
                  filter === "non-compliant" ? "border-[1px] border-solid border-[#0F6CBD]" : "bg-[#F8F8FA]"
                } flex gap-1 ${noOfNonCompliantDeviations === 0 ? "cursor-not-allowed" : "cursor-pointer"}`}
                onClick={() => setFilter(filter === "non-compliant" ? "all" : "non-compliant")}
                disabled={noOfNonCompliantDeviations === 0}
              >
                <DismissIcon fill={"#C83532"} />
                <span className="text-[0.75rem] font-[600] text-[#333333]">{`${noOfNonCompliantDeviations} Not Compliant`}</span>
              </button>
            </TooltipHost>
          </div>
          <div className="p-[0.75rem] bg-[#FAFAFA] flex-grow flex self-stretch overflow-y-auto">
            <div className="self-stretch flex-grow flex flex-col gap-2">
              {filteredDeviations.map((deviation: any) => (
                <button
                  key={deviation._id}
                  className="shadow-md rounded-[0.25rem] bg-[#FFF] p-[0.75rem] self-stretch flex flex-col gap-2 active:bg-[#FAFAFA]"
                  onClick={async (e) => {
                    e.stopPropagation();
                    await Word.run(async (context) => {
                      const paragraph = await getMatchingParagraph(
                        context,
                        deviation.docText,
                        deviation.paragraphIndex
                      );
                      if (paragraph) {
                        paragraph.select();
                      }
                    });
                    setActiveDeviationId(deviation._id);
                  }}
                >
                  <div className="flex flex-col items-start gap-[0.375rem] self-stretch">
                    <div className="pl-[0.125rem]">
                      <span className="text-[#171C26] text-[0.875rem] font-[600]">
                        {deviation.playBookId.clauseType}
                      </span>
                    </div>
                    <div className="flex gap-2">
                      <div className="pl-[0.125rem] flex gap-1">
                        {isDeviationCompliant(deviation) ? <FilledGreenCheckIcon /> : <DismissIcon fill="#C83532" />}
                        {isDeviationCompliant(deviation) ? (
                          <span className="text-[#14804A] text-[0.75rem]">Compliant</span>
                        ) : (
                          <span className="text-[#C83532] text-[0.75rem]">Not compliant</span>
                        )}
                      </div>
                      <div className="bg-[#D9DBDD] h-full w-[1px]"></div>
                      <span className="text-[#616161] text-[0.75rem]">
                        {deviation.playBookId.complianceType === "present" ? "Mandatory clause" : "Prohibited Clause"}
                      </span>
                    </div>
                    <span className="text-[0.875rem]">{deviation.playBookId.description}</span>
                  </div>
                </button>
              ))}
            </div>
          </div>
          {isDocHashEqual ? (
            <></>
          ) : (
            <div className="px-4 pb-4 pt-1 bg-[#FAFAFA] flex justify-start items-start self-stretch">
              <Button
                variant="primary"
                label="Rerun Playbook"
                handleClick={async () => {
                  await runPlayBooksV2();
                  await reloadWorkflowData();
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col justify-center gap-[2rem] items-center w-full h-[100vh]">
          <PlaybookEmptyState className="w-[184px] h-[140px]" />
          <div className="flex flex-col gap-2 items-center justify-center">
            <div className="text-[#333333] text-[0.875rem] font-[600]">Review this contract with AI</div>
            <div className="text-[#616161] text-[14px] font-[400] text-center w-[300px]">
              Use the playbook to review this vendor agreement at light speed
            </div>
            <div className="flex justify-center items-center w-[120px]">
              <Button
                variant="primary"
                label="Run Playbook"
                handleClick={async () => {
                  await runPlayBooksV2();
                  await reloadWorkflowData();
                }}
              />
            </div>
          </div>
        </div>
      )}
      {playbooks.length === 0 ? <div>No playbooks found for workflow</div> : <></>}
    </div>
  );
}
