import React from "react";

interface ErrorRenderProps {
  errors: { [key: string]: string[] } | null;
  fieldName: string;
}

const RenderErrors: React.FC<ErrorRenderProps> = ({ errors, fieldName }) => {
  if (!errors || !errors[fieldName]) {
    return <></>;
  }

  return (
    <div className="flex flex-col gap-1">
      {errors[fieldName].map((error, index) => (
        <p key={`${fieldName}-${index}`} className="text-[#B10E1C] text-sm leading-5">
          {error}
        </p>
      ))}
    </div>
  );
};

export default RenderErrors;
