import React, { SVGProps } from "react";

const ChevronDownIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "1.25rem"}
    height={props.height || "1.25rem"}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill}
      d="M15.853 7.646a.5.5 0 0 1 .001.707l-5.465 5.484a.55.55 0 0 1-.78 0L4.146 8.353a.5.5 0 1 1 .708-.706L10 12.812l5.147-5.165a.5.5 0 0 1 .707-.001Z"
    />
  </svg>
);

export default ChevronDownIcon;
