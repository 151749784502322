import React, { createContext, useContext, useState } from "react";
import NavBar, { NavBarItemProps } from "../../components/NavBar";
import { useOrgData } from "../OrgDataProvider/OrgDataProvider";
import { useWorkflowData } from "../WorkflowDataProvider/WorkflowDataProvider";

export type Mode = "Workflow" | "Template";

export type WorkflowNavigationTabs =
  | "Overview"
  | "Comments"
  | "Clause Approvals"
  | "Create Clause Approval"
  | "Playbook"
  | "AI Assist";

export type TemplateNavigationTabs =
  | "Fields"
  | "Conditions"
  | "Tables"
  | `TagField?mode=${string}`
  | "TagCondition"
  | "CreateField"
  | `EditField?tagId=${string}`
  | "CreateCondition"
  | `EditCondition?conditionId=${string}`
  | "CreateTable"
  | `EditTable?tagId=${string}`;

export type NavigationTabs = WorkflowNavigationTabs | TemplateNavigationTabs;

const NavigationContext = createContext<NavigationTabs | null>(null);

const NavigationDispatchContext = createContext<React.Dispatch<NavigationTabs> | null>(null);

const getDefaultTab = (mode: Mode): NavigationTabs => {
  switch (mode) {
    case "Workflow":
      return (sessionStorage.getItem("tab") as NavigationTabs | null) || "Overview";
    case "Template":
      return (sessionStorage.getItem("tab") as NavigationTabs | null) || "Fields";
  }
};

const getTabs = (mode: Mode): Array<NavBarItemProps> => {
  const workflowTabs: Array<NavBarItemProps> = [
    { text: "Overview" },
    // { text: "Clause Approvals" },
    // { text: "Playbook" },
    { text: "Comments" },
    // { text: "AI Assist", icon: <HyperAssistIcon width="16" height="16" /> },
  ];

  const templateTabs: Array<NavBarItemProps> = [{ text: "Fields" }, { text: "Tables" }, { text: "Conditions" }];
  switch (mode) {
    case "Workflow":
      return workflowTabs;
    case "Template":
      return templateTabs;
  }
};

export function NavigationProvider({
  mode,
  children,
}: {
  mode: Mode;
  children: JSX.Element | JSX.Element[];
}): JSX.Element {
  const defaultTab = getDefaultTab(mode);
  let tabs = getTabs(mode);
  const [selectedTab, setSelectedTab] = useState<NavigationTabs>(defaultTab);
  const { data: orgData } = useOrgData();
  const { orgResponse } = orgData;
  const { data: workflowData } = useWorkflowData();
  const { playbooks } = workflowData;

  if (mode === "Workflow") {
    const isClauseApprovalEnabled = orgResponse.organisation.isClauseApprovalEnabled;
    const isPlaybookEnabled = playbooks.length !== 0;
    if (isClauseApprovalEnabled) {
      tabs = [{ text: "Clause Approvals" }];
    }
    if (isPlaybookEnabled) {
      tabs.push({ text: "Playbook" });
    }
  }

  return (
    <NavigationContext.Provider value={selectedTab}>
      <NavigationDispatchContext.Provider
        value={(tab) => {
          sessionStorage.setItem("tab", tab);
          setSelectedTab(tab);
        }}
      >
        <NavBar
          mode={mode}
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={(tab) => {
            sessionStorage.setItem("tab", tab as string);
            setSelectedTab(tab);
          }}
        />
        {children}
      </NavigationDispatchContext.Provider>
    </NavigationContext.Provider>
  );
}

export function useNavigation(): NavigationTabs | null {
  return useContext(NavigationContext);
}

export function useNavigationDispatch(): React.Dispatch<NavigationTabs> | null {
  return useContext(NavigationDispatchContext);
}
