import * as React from "react";

const SelectionIndicatorIcon = ({
  width,
  height,
  color,
}: {
  width: string;
  height: string;
  color: string;
}): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
    <rect width={56} height={3} x={12} fill={color} rx={1.5} />
  </svg>
);

export default SelectionIndicatorIcon;
