import React, { SVGProps } from "react";

const KebabMenuIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="4" fill="none" {...props}>
    <path
      fill="#616161"
      d="M5.238 2a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0ZM10 2a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0ZM.477 2a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0Z"
    />
  </svg>
);

export default KebabMenuIcon;
