import { User } from "@auth0/auth0-react";
import { ClauseApproverAssigned } from "../Pages/RequestApproval/interface";
import { ClauseApprovalStatus, ClauseApprovalsFilterOptions } from "../Pages/ClauseApprovals/interface";

export default function clauseFilterHelper({
  approval,
  user,
  currentFilterOption,
}: {
  approval: { status: ClauseApprovalStatus; approvers: Array<ClauseApproverAssigned> };
  user: User;
  currentFilterOption: ClauseApprovalsFilterOptions;
}): boolean {
  switch (currentFilterOption) {
    case "All Approvals": {
      return true;
    }
    case "Approved": {
      return (approval.status as ClauseApprovalStatus) === "approved";
    }
    case "Pending Approvals": {
      return (approval.status as ClauseApprovalStatus) === "requested";
    }
    case "For Your Approval": {
      if (approval.status !== "requested") {
        return false;
      }
      let canIApprove = false;
      for (const approver of approval.approvers as Array<ClauseApproverAssigned>) {
        switch (approver.type) {
          case "group": {
            if (user.groups.includes(approver.approverId)) {
              canIApprove = true;
              break;
            }
            break;
          }
          case "user": {
            if (user._id === approver.approverId) {
              canIApprove = true;
              break;
            }
            break;
          }
        }
      }
      return canIApprove;
    }
  }
}
