import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import { BACKEND_URL, NODE_ENV_OVERRIDE } from "../constants";
import FullPageSpinner from "../components/FullPageSpinner";

// eslint-disable-next-line no-unused-vars
export const FetchContext = createContext<((url: string, config: RequestInit) => Promise<any>) | null>(null);

function FetchProvider({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element {
  const { getAccessTokenSilently } = useAuth0();
  const fetcher = async (url: string, config: RequestInit) => {
    const accessToken = await getAccessTokenSilently();
    const res = await fetch(url, {
      ...config,
      headers: {
        ...(config.headers && config.headers),
        authorization: `Bearer ${accessToken}`,
        ...(NODE_ENV_OVERRIDE === "local" && { "ngrok-skip-browser-warning": "true" }),
      },
    });
    if (res.status === 204) {
      return "";
    }
    const result = await res.json();
    if (!res.ok) {
      throw new Error(result.message);
    }
    return result;
  };

  return <FetchContext.Provider value={fetcher}>{children}</FetchContext.Provider>;
}

// eslint-disable-next-line no-unused-vars
export function useFetch(
  urlRelativePath: string,
  config: RequestInit,
  fallBackData: any
): { data: any; isLoading: boolean; error: Error | null; reload: () => Promise<void>; isRefetchingData: boolean } {
  const fetcher = useContext(FetchContext);
  const [data, setData] = useState<any>(fallBackData);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isRefetchingData, setIsRefetchingData] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (fetcher && data === fallBackData) {
      setIsLoading(true);
      setError(null);
      fetcher(`${BACKEND_URL}/api/v1/${urlRelativePath}`, { ...config, signal })
        .then((res) => {
          setData(res);
        })
        .catch((e) => {
          setError(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    return () => {
      controller.abort();
    };
  }, []);

  const reload = async () => {
    if (fetcher) {
      setIsRefetchingData(true);
      setError(null);
      fetcher(`${BACKEND_URL}/api/v1/${urlRelativePath}`, { ...config })
        .then((res) => {
          setData(res);
        })
        .catch((e) => {
          setError(e);
        })
        .finally(() => {
          setIsRefetchingData(false);
        });
    }
  };

  return { data, isLoading, error, reload, isRefetchingData };
}

export default withAuthenticationRequired(FetchProvider, {
  onRedirecting: FullPageSpinner,
});
