import React, { useContext, useState } from "react";
import Input from "../../components/Input";
import { FetchContext } from "../../api/FetchContext";
import { BACKEND_URL } from "../../constants";
import { useNotificationToastDispatch } from "../../features/NotificationToast/NotificationContext";
import Button from "../../components/Button";
import { useNavigationDispatch } from "../../features/Navigation/NavigationContext";
import Dropdown from "../../components/Dropdown";
import TextArea from "../../components/TextArea";
import FieldTypes from "./FieldTypes";
import moment from "moment";
import { fieldSchema } from "./fieldSchema";
import formatJoiErrors, { FormattedJoiErrors } from "../../utils/formatJoiErrors";
import RenderErrors from "../../components/RenderErrors";
import OptionsBuilder from "./OptionsBuilder";

export const fieldTypeOptions = [
  { label: "Text", value: "text" },
  { label: "Number", value: "number" },
  { label: "Boolean", value: "boolean" },
  { label: "Email", value: "email" },
  { label: "Date", value: "date" },
  { label: "Dropdown", value: "dropdown" },
];

export interface DateFormatOption {
  label: JSX.Element;
  value: string;
}

export const getFieldTypeOptionLabel = (option: any) => {
  if (option?.label && typeof option.label === "string") {
    return option.label;
  }
  const fieldTypeOption = fieldTypeOptions.find((type) => type.label === option.label);
  return fieldTypeOption?.label;
};

export const dateFormatOptions = () => {
  // moment.js is in maintenance mode, but porting to date-fns has this constraint
  // date-fns doesn't support all the formats below, which which being used in both frontend and backend
  return [
    "DD/MM/YYYY",
    "MM/DD/YYYY",
    "MMMM Do YYYY",
    "MMMM D, YYYY",
    "Do MMMM YYYY",
    "D MMM YYYY",
    "Do [Day of] MMMM YYYY",
  ].map((format: string) => {
    return {
      label: (
        <div className="flex gap-1 justify-between px-1 flex-wrap">
          <p>{format}</p>
          <p>{moment().format(format)}</p>
        </div>
      ),
      value: format,
    };
  });
};

export default function CreateField({
  tags,
  templateId,
  reloadTags,
}: {
  tags: Array<any>;
  templateId: string;
  reloadTags: () => void;
}): JSX.Element {
  const [fieldName, setFieldName] = useState<string>("");
  const [fieldType, setFieldType] = useState<{
    label: string;
    value: string;
  }>({
    label: "Text",
    value: "text",
  });
  const [errors, setErrors] = useState<FormattedJoiErrors | null>(null);
  const [description, setDescription] = useState<string>("");
  const [defaultValue, setDefaultValue] = useState<any>("");
  const [options, setOptions] = useState<Array<string>>([""]);
  const [dateFormat, setDateFormat] = useState<DateFormatOption>({
    label: (
      <div className="flex gap-1 justify-between px-1 flex-wrap">
        <p>DD/MM/YYYY</p>
        <p>{moment().format("DD/MM/YYYY")}</p>
      </div>
    ),
    value: "DD/MM/YYYY",
  });

  const fetcher = useContext(FetchContext);
  const notificationDispatch = useNotificationToastDispatch();
  const setSelectedTab = useNavigationDispatch();

  const handleCreateTag = async () => {
    const data: any = {
      name: fieldName,
      type: fieldType.value,
      description,
      defaultValue: defaultValue?.value ? defaultValue.value : defaultValue,
      dateFormat: dateFormat.value,
    };
    if (fieldType.value == "dropdown") {
      data.options = options;
    }

    const { error } = fieldSchema.validate(data, {
      abortEarly: false,
    });
    if (error) {
      const errors = formatJoiErrors(error.details);
      setErrors(errors);
      return;
    }
    if (fetcher && notificationDispatch) {
      const res = await fetcher(`${BACKEND_URL}/api/v1/template/updateTags`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          templateId,
          tags: tags.concat([
            {
              ...data,
              formType: "question",
              isCustom: true,
            },
          ]),
        }),
      }).catch((e) => {
        if (e instanceof Error) {
          notificationDispatch({ type: "error", message: e.message });
        }
        return e;
      });
      if (!res) {
        reloadTags();
      }
    }
    if (setSelectedTab) {
      setSelectedTab("Fields");
    }
  };

  return (
    <div className="flex flex-col justify-between bg-[#FAFAFA] flex-grow">
      <div className="bg-[#FDFDFD] py-4 flex flex-col h-[95vh] overflow-y-auto">
        <div className="py-[0.625rem] px-[1rem]">
          <span className="text-[#171C26] text-[1.25rem] font-[600] leading-[2rem]">Create new field</span>
        </div>
        <div className="px-[1rem] py-[1rem] flex flex-col gap-[1.5rem]">
          <div className="flex flex-col gap-[1rem]">
            <div className="flex flex-col gap-[0.5rem]">
              <div className="flex gap-[0.25rem]">
                <span className="text-[0.875rem] leading-[1.25rem]">Field name</span>
                <span className="text-[#B10E1C] text-[0.875rem] leading-[1.25rem]">*</span>
              </div>
              <Input value={fieldName} setValue={setFieldName} placeholder="" title="Enter Filed Name" />
              <RenderErrors errors={errors} fieldName="name" />
            </div>
            <div className="flex flex-col gap-[0.5rem]">
              <div className="flex gap-[0.25rem]">
                <span className="text-[0.875rem] leading-[1.25rem]">Type</span>
                <span className="text-[#B10E1C] text-[0.875rem] leading-[1.25rem]">*</span>
              </div>
              <Dropdown
                width="w-full"
                option={getFieldTypeOptionLabel(fieldType)}
                setOption={(option) => {
                  setFieldType(option);
                  setDefaultValue("");
                }}
                options={fieldTypeOptions}
              />
              <RenderErrors errors={errors} fieldName="type" />
            </div>
          </div>
          {fieldType.value == "date" ? (
            <div className="flex flex-col gap-[0.5rem]">
              <div className="flex gap-[0.25rem]">
                <span className="text-[0.875rem] leading-[1.25rem]">Date format</span>
                <span className="text-[#B10E1C] text-[0.875rem] leading-[1.25rem]">*</span>
              </div>
              <Dropdown
                width="w-full"
                option={dateFormat.label}
                setOption={(option) => {
                  setDateFormat(option);
                }}
                options={dateFormatOptions()}
              />
              <RenderErrors errors={errors} fieldName="dateFormat" />
            </div>
          ) : (
            <></>
          )}
          {fieldType.value == "dropdown" ? (
            <OptionsBuilder options={options} setOptions={setOptions} errors={errors} />
          ) : (
            <></>
          )}
          <TextArea
            label="Field description"
            fieldDescription="Descriptions help your team members input the right values in fields while filling intake forms"
            description={description}
            setDescription={setDescription}
            errors={errors}
            fieldName="description"
          />
          <div className="flex flex-col gap-[0.5rem]">
            <div className="flex gap-[0.25rem]">
              <span className="text-[0.875rem] leading-[1.25rem]">Default value </span>
              <span className="text-[#838383] text-[0.875rem] leading-[1.25rem]">(Optional)</span>
            </div>
            <FieldTypes
              defaultValue={defaultValue}
              setDefaultValue={setDefaultValue}
              inputType={fieldType.value}
              options={options}
            />
            <RenderErrors errors={errors} fieldName="defaultValue" />
          </div>
        </div>
      </div>
      <div className="p-[1rem] flex gap-[0.5rem] border-t border-t-solid border-t-[#E0E0E0] bg-[#FAFAFA] fixed bottom-0 w-full">
        <Button variant="primary" label="Create" handleClick={handleCreateTag} />
        <Button
          variant="secondary"
          label="Cancel"
          handleClick={async () => {
            if (setSelectedTab) {
              setSelectedTab("Fields");
            }
          }}
        />
      </div>
    </div>
  );
}
