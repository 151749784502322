import React from "react";
import HeadlessDropDown from "./HeadlessDropDown";
import ChevronDownIcon from "../icons/ChevronDownIcon";

type IDropdown = {
  width: string;
  option?: string | IOption | JSX.Element;
  // eslint-disable-next-line no-unused-vars
  setOption: (option: any) => void;
  options: IOption[];
};

type IOption = {
  label: string | JSX.Element;
  value: string | any;
  _id?: string;
};

const Dropdown = ({ width, option, setOption, options }: IDropdown) => {
  return (
    <HeadlessDropDown
      selectElement={
        <div
          className={`rounded-[0.25rem] border-[1px] border-solid border-[#B3B3B3] py-[0.3125rem] px-[0.75rem] flex gap-[0.25rem] justify-between ${width}`}
        >
          {option ? (
            <span className={`text-[0.875rem] text-[#333333] font-[600] leading-[1.25rem]`}>{option}</span>
          ) : (
            <span className={`text-[0.875rem] text-[#333333] font-[600] leading-[1.25rem]`}>Select</span>
          )}
          <ChevronDownIcon fill="#242424" width={16} height={16} />
        </div>
      }
      customContainerClasses="active:bg-[#E0E0E0] focus:bg-[#E0E0E0] focus-within:bg-[#E0E0E0]"
      customOptionsContainerClasses={`rounded-[0.25rem] shadow-md border-[1px] border-solid border-[#D9DBDD] bg-[#FFF] ${width}`}
      options={options.map((option) => ({
        children: (
          <p className="text-[#333333] text-[0.875rem] leading-[1.25rem] whitespace-nowrap py-2">{option.label}</p>
        ),
        clickHandler: async () => setOption(option),
      }))}
    />
  );
};

export default Dropdown;
