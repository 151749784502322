import React, { SVGProps } from "react";
const TextTagTypeIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" {...props}>
    <path
      fill={props.fill}
      d="M3.2 3a.6.6 0 0 1 .6-.6h8a.6.6 0 0 1 .6.6v1.2a.6.6 0 0 1-1.2 0v-.6H8.4v8.8h1a.6.6 0 1 1 0 1.2H6.2a.6.6 0 1 1 0-1.2h1V3.6H4.4v.6a.6.6 0 0 1-1.2 0V3Z"
    />
  </svg>
);
export default TextTagTypeIcon;
