export default async function findCommentById(
  context: Word.RequestContext,
  commentId: string
): Promise<Word.Comment | undefined> {
  const body = context.document.body;
  body.load(["comment"]);
  await body.context.sync();
  const comments = body.getComments();
  comments.load("items");
  await comments.context.sync();
  for (const commentContext of comments.items) {
    if (commentContext.id === commentId) {
      return commentContext;
    }
  }
  return undefined;
}
