import * as React from "react";

const CommentWithTopPlusIcon = ({
  width,
  height,
  color,
}: {
  width: string;
  height: string;
  color: string;
}): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
    <path
      fill={color}
      d="M19.5 5.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-4-2a.5.5 0 0 0-1 0V5H13a.5.5 0 0 0 0 1h1.5v1.5a.5.5 0 0 0 1 0V6H17a.5.5 0 0 0 0-1h-1.5V3.5Zm2 8.776V10.4a5.507 5.507 0 0 0 1-.657v2.533c0 1.418-1.164 2.566-2.6 2.566h-4.59l-4.011 2.961a1.009 1.009 0 0 1-1.4-.199.978.978 0 0 1-.199-.59v-2.172h-.6c-1.436 0-2.6-1.149-2.6-2.566v-6.71C2.5 4.149 3.664 3 5.1 3h5a5.464 5.464 0 0 0-.393 1H5.1c-.896 0-1.6.713-1.6 1.566v6.71c0 .853.704 1.566 1.6 1.566h1.6V17h.003l.002-.001 4.276-3.157H15.9c.896 0 1.6-.713 1.6-1.566Z"
    />
  </svg>
);

export default CommentWithTopPlusIcon;
