import React, { useState } from "react";

import { ClauseApprovalsFilterOptions } from "./interface";
import CheckMarkIcon from "../../icons/CheckMarkIcon";
import HeadlessDropDown from "../../components/HeadlessDropDown";
import ChevronDownIcon from "../../icons/ChevronDownIcon";
import Button from "../../components/Button";
import PlusIcon from "../../icons/PlusIcon";
import { useNavigationDispatch } from "../../features/Navigation/NavigationContext";
import ClauseApproval from "./ClauseApproval";
import useCurrentUser from "../../hooks/useCurrentUser";
import clauseFilterHelper from "../../utils/clauseApprovalFilterHelper";
import { useWorkflowData } from "../../features/WorkflowDataProvider/WorkflowDataProvider";
import { useOrgData } from "../../features/OrgDataProvider/OrgDataProvider";

export default function ClauseApprovalsPage({ workflowId }: { workflowId: string }): JSX.Element {
  const { data: orgData } = useOrgData();
  const { users, groups } = orgData;
  const { data: workflowData, reload } = useWorkflowData();
  const { clauseApprovalsResponse } = workflowData;
  const clauseApprovals: Array<any> = clauseApprovalsResponse.clauseApprovals;
  const user = useCurrentUser({ users });
  const [currentFilterOption, setCurrentFilterOption] = useState<ClauseApprovalsFilterOptions>("All Approvals");
  const setSelectedTab = useNavigationDispatch();
  const options = (
    ["All Approvals", "Approved", "Pending Approvals", "For Your Approval"] as Array<ClauseApprovalsFilterOptions>
  ).map((text) => {
    return {
      children: (
        <>
          <span className="text-[#333333] text-[0.875rem] font-[400] leading-[1.25rem] whitespace-nowrap">{text}</span>
          {currentFilterOption === text ? <CheckMarkIcon fill="#212121" /> : <></>}
        </>
      ),
      clickHandler: async () => setCurrentFilterOption(text),
      customClasses: "px-[0.75rem] py-[0.25rem] justify-between flex",
    };
  });

  const filteredClauseApprovals = clauseApprovals.filter((approval): boolean =>
    clauseFilterHelper({ approval, user, currentFilterOption })
  );

  return (
    <div className="overflow-y-auto bg-[#FAFAFA] flex flex-col flex-grow justify-between">
      <div className="overflow-y-auto flex-grow flex flex-col gap-[0.5rem]">
        <div className="bg-[#FFF] border-b-[1px] border-b-solid border-b-[#E0E0E0] p-[0.75rem]">
          <HeadlessDropDown
            selectElement={
              <>
                <div className="px-[0.625rem] rounded-[0.25rem] border-[1px] border-solid border-[#D1D1D1] flex flex-row grow justify-between self-stretch">
                  <div className="p-[0.3125rem_0.125rem_0.4375rem_0.125rem]">
                    <span className="text-[#333333] text-[0.875rem] leading-[1.25rem]">{currentFilterOption}</span>
                  </div>
                  <div className="p-[0.375rem_0rem_0.375rem_0.125rem]">
                    <ChevronDownIcon width="1.25rem" height="1.25rem" fill="#616161" />
                  </div>
                </div>
                <div className="absolute bottom-[-1px] w-full h-[0.0625rem] flex justify-center">
                  <div className="bg-[#616161] w-[90%] h-[0.0625rem]"></div>
                </div>
              </>
            }
            options={options}
            customContainerClasses="shrink-0 w-[12.5rem]"
            customOptionsContainerClasses="w-[12.5rem] bg-[#FFF] top-[2.4rem] p-[0.5rem] shadow-md rounded-[0.25rem] border-[1px] border-solid border-[#D9DBDD] gap-[0.25rem]"
          />
        </div>
        {filteredClauseApprovals.length === 0 ? (
          <div className="p-[1rem] flex flex-grow items-center justify-center">
            No clause approvals found, select text in document and click + icon to create one
          </div>
        ) : (
          <div className="overflow-y-auto flex-grow flex flex-col px-[0.75rem] gap-[1rem]">
            {filteredClauseApprovals.map((clauseApproval) => (
              <ClauseApproval
                key={clauseApproval._id}
                workflowId={workflowId}
                reloadApprovals={reload}
                clauseApproval={clauseApproval}
                users={users}
                groups={groups}
              />
            ))}
          </div>
        )}
      </div>
      <div className="p-[0.5rem] flex flex-row justify-end">
        <Button
          variant="primary"
          label=""
          leftIcon={<PlusIcon stroke="#fff" />}
          handleClick={async () => {
            if (setSelectedTab) {
              setSelectedTab("Create Clause Approval");
            }
          }}
          customClasses="rounded-full"
        />
      </div>
    </div>
  );
}
