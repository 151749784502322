import React from "react";

const LogoutIcon = ({ color }: { color: string }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.125 7.29199L16.0417 10.0003L13.125 12.7087"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15.832 10H8.95703" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12.707 3.95801H5.6237C4.70322 3.95801 3.95703 4.7042 3.95703 5.62467V14.3747C3.95703 15.2952 4.70322 16.0413 5.6237 16.0413H12.707"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LogoutIcon;
