import React from "react";

const NumberIcon = ({ color }: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.83464 3.16797L5.16797 12.8346"
        stroke={color ? color : "#464F60"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8346 3.16797L9.16797 12.8346"
        stroke={color ? color : "#464F60"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.832 5.83203H3.83203"
        stroke={color ? color : "#464F60"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.168 10.168H3.16797"
        stroke={color ? color : "#464F60"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NumberIcon;
