import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";

import Table, { Annotation } from "./AnnotationTable";
import LoadingSpinner from "../../components/LoadingSpinner";
import { BACKEND_URL } from "../../constants";

export interface AppState {
  highlightLoading: boolean;
  uploadLoading: boolean;
  interval: any;
}

export default function ExtractTerms(): JSX.Element {
  const { getAccessTokenSilently } = useAuth0();

  const [state, setState] = useState<AppState>({
    highlightLoading: false,
    uploadLoading: false,
    interval: null,
  });

  const isUploadingOrProcessing = state.uploadLoading || state.highlightLoading;

  useEffect(() => {
    if (localStorage.getItem("timestamp")) {
      setState({
        ...state,
        interval: setInterval(() => {
          getTxtJson();
        }, 10000),
        highlightLoading: true,
      });
    }
    return () => {
      if (state.interval) {
        clearInterval(state.interval);
      }
    };
  }, []);

  const deleteFile = async (timestamp: string) => {
    await fetch(`${BACKEND_URL}/api/v1/document/delete`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        timestamp: timestamp,
      }),
    }).catch(() => {
      /* TODO */
    });
    localStorage.removeItem("timestamp");
    clearInterval(state.interval);
    setState({ ...state, interval: null });
  };

  const deleteComments = async (body: Word.Body) => {
    const pastCommentIdsStringified = localStorage.getItem(Office.context.document.url);
    if (pastCommentIdsStringified) {
      // eslint-disable-next-line office-addins/load-object-before-read
      const pastCommentIds: Array<string> = JSON.parse(pastCommentIdsStringified)?.commentIds;
      const comments = body.getComments();
      comments.load("items");
      await comments.context.sync();
      for (const comment of comments.items) {
        comment.load("id");
        await comment.context.sync();
        if (pastCommentIds.includes(comment.id)) {
          comment.delete();
        }
      }
    }
  };

  const getTxtJson = async () => {
    const timestamp = localStorage.getItem("timestamp");
    if (!timestamp) {
      return;
    }
    const res = (
      (await fetch(
        `${BACKEND_URL}/api/v1/document/aiTxtJson?` +
          new URLSearchParams({
            timestamp,
          }),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        }
      ).catch(() => {
        /* TODO */
      })) as Response
    ).json();
    res.then(async (response) => {
      // console.log(response);
      if (response.status === "error") {
        return;
      }
      await deleteFile(timestamp);
      const annotations = response.data;
      await Word.run(async (context) => {
        const body = context.document.body;
        body.load(["text", "comment"]);
        await body.context.sync();
        await deleteComments(body);
        const charRanges = body.search("?", { matchWildcards: true });
        charRanges.load();
        await charRanges.context.sync();
        const commentIds = [];
        const saveAnnotations: Annotation[] = [];
        for (const annotation in annotations) {
          const labels = [annotation];
          const result: Array<number> = annotations[annotation].span_indices[0];
          let start = result[0];
          const end = result[1];
          if (start === -1) {
            continue;
          }
          // console.log("response is", annotation, labels, start, end);
          while ([" ", "\r", ":"].includes(charRanges.items[start].text)) {
            // console.log("Enters check");
            start += 1;
          }
          const targetRange = charRanges.items[start].expandTo(charRanges.items[end - 1]);
          targetRange.load();
          for (const label of labels) {
            const comment = targetRange.insertComment(label);
            comment.load("id");
            await comment.context.sync();
            commentIds.push(comment.id);
            saveAnnotations.push({ commentId: comment.id, label, value: annotations[annotation].response });
          }
          await targetRange.context.sync();
        }
        localStorage.setItem(
          Office.context.document.url,
          JSON.stringify({
            commentIds,
            annotations: saveAnnotations,
          })
        );
        context.document.save();
      });
      setState({ ...state, highlightLoading: false });
    });
  };
  const uploadForHighlight = async (fileContent: string) => {
    const formData = new FormData();
    const file = new File([new Blob([fileContent], { type: "text/plain", endings: "transparent" })], "default.txt");
    formData.append("file", file);
    const res = (
      (await fetch(`${BACKEND_URL}/api/v1/document/uploadContractTxt`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
        body: formData,
      }).catch(() => {
        /* TODO */
      })) as Response
    ).json();
    res.then((data) => {
      localStorage.setItem("timestamp", data.id);
      setState({
        ...state,
        interval: setInterval(() => {
          getTxtJson();
        }, 10000),
        uploadLoading: false,
        highlightLoading: true,
      });
    });
  };

  const extractTerms = () => {
    setState({ ...state, uploadLoading: true });
    return Word.run(async (context) => {
      const body = context.document.body;
      body.load("text");
      await body.context.sync();

      const charRanges = body.search("?", { matchWildcards: true });
      charRanges.load();
      await charRanges.context.sync();

      let text = "";
      for (const item of charRanges.items) {
        if (!item.text) {
          text = text + " ";
        } else {
          if (["\u202F", "\u00A0", "\r"].includes(item.text)) {
            text = text + "\n";
          } else {
            text = text + item.text;
          }
        }
      }
      await uploadForHighlight(text);
    });
  };
  const annotationsStringified = localStorage.getItem(Office?.context?.document?.url);
  let annotations: Annotation[] = [];
  if (annotationsStringified) {
    annotations = JSON.parse(annotationsStringified)?.annotations;
  }
  return (
    <div className="flex flex-col justify-center gap-2">
      {annotations && <Table annotations={annotations} />}
      {/* <button className="primary-button" onClick={click} disabled={state.loading}>
          {state.loading ? <LoadingSpinner shade="Light" size="Small" /> : "Add to repository"}
        </button> */}
      {/* <button className="primary-button" onClick={open}>
          Open HyperStart
        </button> */}
      <div className="w-[100%] flex flex-col">
        <div className="w-[100%] flex items-center  justify-center">
          <button
            className="w-[100%] flex items-center justify-center p-[8px_16px] bg-[#554EF1] text-[#fff] text-[12px] font-[Lato] not-italic font-semibold rounded-[5px] mx-[16px] my-[8px] hover:bg-[#554ef1cc]"
            onClick={extractTerms}
            disabled={isUploadingOrProcessing}
          >
            {isUploadingOrProcessing ? <LoadingSpinner shade="Light" size="Small" /> : "Extract Terms"}
          </button>
        </div>
      </div>

      {state.uploadLoading && <span className="text-center">Uploading...</span>}
      {state.highlightLoading && <span className="text-center">processing...</span>}
    </div>
  );
}
