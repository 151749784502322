/* eslint-disable no-undef */

export const AUTH0_DOMAIN = process.env.AUTH0_DOMAIN || "";

export const AUTH0_CLIENT_ID = process.env.AUTH0_CLIENT_ID || "";

export const BACKEND_URL = process.env.BACKEND_URL || "";

export const WEB_APP_URL = process.env.WEB_APP_URL || "";

export const NODE_ENV_OVERRIDE = process.env.NODE_ENV_OVERRIDE || "";

export const counterPartyNameTagId = "645a4eba62c1395744b25bf1";

export const bgColorOptions = ["#5768D4", "#1B9898", "#F584AD", "#E19942"];

export const COUNTERPARTY_SIGN_TAGID = "6482c73812d31d40cdafcbf1";
export const COUNTERPARTY_SIGNER_EMAIL_TAGID = "6482c7562a8eeeded06454ba";
export const COUNTERPARTY_SIGNER_NAME_TAGID = "6483250abefc3834675ddcc2";
export const COUNTERPARTY_SIGNER_ANCHORTAG = "/signatureAnchorTag_1/";
export const INTERNALPARTY_SIGN_TAGID = "6482c781e4b5c90cde4c49bc";
export const INTERNALPARTY_SIGNER_EMAIL_TAGID = "6482c7653af4c123e5639e6c";
export const INTERNALPARTY_SIGNER_NAME_TAGID = "64832545b154961fef37df19";
export const INTERNALPARTY_SIGNER_ANCHORTAG = "/signatureAnchorTag_2/";
