import React, { SVGProps } from "react";
const DismissIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1.25rem" height="1.25rem" fill="none" {...props}>
    <path
      fill={props.fill}
      d="M10 2a8 8 0 1 1 0 16 8 8 0 0 1 0-16ZM7.81 7.114a.5.5 0 0 0-.638.058l-.058.069a.5.5 0 0 0 .058.638L9.292 10l-2.12 2.121-.058.07a.5.5 0 0 0 .058.637l.069.058a.5.5 0 0 0 .638-.058L10 10.708l2.121 2.12.07.058a.5.5 0 0 0 .637-.058l.058-.069a.5.5 0 0 0-.058-.638L10.708 10l2.12-2.121.058-.07a.5.5 0 0 0-.058-.637l-.069-.058a.5.5 0 0 0-.638.058L10 9.292l-2.121-2.12-.07-.058Z"
    />
  </svg>
);

export default DismissIcon;
