import React, { useRef, useState, useEffect } from "react";
import { WEB_APP_URL } from "../constants";
import ArrowExitIcon from "../icons/ArrowExitIcon";
import ArrowLeftIcon from "../icons/ArrowleftIcon";
import HamburgerIcon from "../icons/HamburgerIcon";
import OpenInBrowserIcon from "../icons/OpenInBrowserIcon";
import AvatarFallback from "./AvatarFallBack";
import { useOrgData } from "../features/OrgDataProvider/OrgDataProvider";
import useCurrentUser from "../hooks/useCurrentUser";
import { NavigationTabs } from "../features/Navigation/NavigationContext";
import { useAuth0 } from "@auth0/auth0-react";
import getWebDavPayload from "../utils/getWebDavPayload";
import { useWorkflowData } from "../features/WorkflowDataProvider/WorkflowDataProvider";

export default function NavBarHamburgerMenu({
  setSelectedTab,
}: {
  setSelectedTab: React.Dispatch<React.SetStateAction<NavigationTabs>>;
}): JSX.Element {
  const { data: orgData } = useOrgData();
  const { users, orgResponse } = orgData;
  const { organisation } = orgResponse;
  const user = useCurrentUser({ users });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { logout } = useAuth0();
  const { workflowId } = getWebDavPayload(Office?.context?.document?.url || "");
  const { data: workflowData } = useWorkflowData();
  const { playbooks } = workflowData;

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div className="relative h-full flex items-center" ref={menuRef}>
      <div className="px-2 pt-3 cursor-pointer flex items-center h-full" onClick={toggleMenu}>
        <HamburgerIcon width={24} height={24} />
      </div>
      {isMenuOpen && (
        <div className="absolute left-0 top-full mt-1 w-[260px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          <div className="flex flex-col justify-between h-[80vh]">
            <div className="flex flex-col gap-3 px-4 py-3">
              <div className="cursor-pointer" onClick={() => setIsMenuOpen(false)}>
                <ArrowLeftIcon fill="#171C26" />
              </div>
              <div className="flex flex-row gap-[12px] border-b border-[#E0E0E0] pb-[16px]">
                <AvatarFallback
                  avatarUrl={user?.imageUrl || ""}
                  size={"Medium"}
                  backgroundColor=""
                  username={user?.firstName}
                  onClick={undefined}
                />
                <div className="text-[#333333] text-[14px]">{user?.email} </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-[#838383] text-[11px] font-[600] "> CONTRACT INFORMATION</div>
                <div className="flex flex-col gap-2">
                  <div
                    className="text-[#242424] text-[14px] cursor-pointer hover:bg-[#FAFAFA] p-2"
                    onClick={() => {
                      setSelectedTab("Overview");
                      setIsMenuOpen(false);
                    }}
                  >
                    Overview
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-[#838383] text-[11px] font-[600] ">REVIEW & DRAFTING</div>
                <div className="flex flex-col gap-2">
                  <div
                    className="text-[#242424] text-[14px] cursor-pointer hover:bg-[#FAFAFA] p-2"
                    onClick={() => {
                      setSelectedTab("Comments");
                      setIsMenuOpen(false);
                    }}
                  >
                    Comments
                  </div>
                </div>
              </div>
              {!organisation.isClauseApprovalEnabled && playbooks.length === 0 ? (
                <></>
              ) : (
                <div className="flex flex-col gap-2">
                  <div className="text-[#838383] text-[11px] font-[600] ">PLAYBOOK</div>
                  {organisation.isClauseApprovalEnabled ? (
                    <div className="flex flex-col gap-2">
                      <div
                        className="text-[#242424] text-[14px] cursor-pointer hover:bg-[#FAFAFA] p-2"
                        onClick={() => {
                          setSelectedTab("Clause Approvals");
                          setIsMenuOpen(false);
                        }}
                      >
                        Clause Approvals
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {playbooks.length !== 0 ? (
                    <div className="flex flex-col gap-2">
                      <div
                        className="text-[#242424] text-[14px] cursor-pointer hover:bg-[#FAFAFA] p-2"
                        onClick={() => {
                          setSelectedTab("Playbook");
                          setIsMenuOpen(false);
                        }}
                      >
                        Playbook
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
            <div className="flex flex-col gap-3 px-4 py-3 border-t border-[#E0E0E0]">
              <div
                className="flex flex-row gap-2 cursor-pointer hover:bg-[#FAFAFA] p-2"
                onClick={(e) => {
                  e.stopPropagation();
                  if (workflowId) {
                    Office.context.ui.openBrowserWindow(`${WEB_APP_URL}/activity/${workflowId}`);
                  } else {
                    Office.context.ui.openBrowserWindow(`${WEB_APP_URL}/workflow`);
                  }
                }}
              >
                <OpenInBrowserIcon width="1.25rem" height="1.25rem" fill="#333" />
                <div className="text-[#333333] text-[14px]">Open in HyperStart </div>
              </div>
              <div
                className="flex flex-row gap-2 cursor-pointer hover:bg-[#FAFAFA] p-2"
                onClick={() => {
                  logout({ logoutParams: { returnTo: `${window.location.origin}/taskpane.html` } });
                  sessionStorage.clear();
                }}
              >
                <ArrowExitIcon width="1.25rem" height="1.25rem" fill="#C83532" />
                <div className="text-[#C83532] text-[14px]">Logout </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
