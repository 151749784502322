const changeTrackingMode = async (
  context: Word.RequestContext,
  mode: Word.ChangeTrackingMode | "TrackAll" | "Off" | "TrackMineOnly"
): Promise<void> => {
  const document = context.document;
  document.load("changeTrackingMode");
  await context.sync();
  document.changeTrackingMode = mode;
};

export default changeTrackingMode;
