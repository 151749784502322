import React, { useState } from "react";
import "./Calendar.css";

const Calendar = ({
  selectedDate,
  position,
  defaultDate,
}: {
  // eslint-disable-next-line no-unused-vars
  selectedDate: (date: any) => void;
  position: React.CSSProperties["position"];
  defaultDate: string;
}) => {
  const month = new Date().getMonth();
  const year = new Date().getFullYear();
  const startingYear = 1980;
  const endingYear = 2050;

  const months = [
    { monthNameShort: "Jan", monthNumber: 0, monthNameFull: "January" },
    { monthNameShort: "Feb", monthNumber: 1, monthNameFull: "February" },
    { monthNameShort: "Mar", monthNumber: 2, monthNameFull: "March" },
    { monthNameShort: "Apr", monthNumber: 3, monthNameFull: "April" },
    { monthNameShort: "May", monthNumber: 4, monthNameFull: "May" },
    { monthNameShort: "Jun", monthNumber: 5, monthNameFull: "June" },
    { monthNameShort: "Jul", monthNumber: 6, monthNameFull: "July" },
    { monthNameShort: "Aug", monthNumber: 7, monthNameFull: "August" },
    { monthNameShort: "Sep", monthNumber: 8, monthNameFull: "September" },
    { monthNameShort: "Oct", monthNumber: 9, monthNameFull: "October" },
    { monthNameShort: "Nov", monthNumber: 10, monthNameFull: "November" },
    { monthNameShort: "Dec", monthNumber: 11, monthNameFull: "December" },
  ];
  const [changeMonth, setChangeMonth] = useState(false);
  const [changeYear, setChangeYear] = useState(false);
  const [currentYear, setCurrentYear] = useState(year);
  const [selectDate, setSelectDate] = useState<any>(new Date(defaultDate).getDate());
  const [currentMonth, setCurrentMonth] = useState(months[month].monthNumber);
  const [currentMonthInWords, setCurrentMonthInWords] = useState(months[month].monthNameFull);
  const [hideCalendar] = useState(false);
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
  // days to skip the mapping thing add + 1
  // Because we are adding sunday so one
  // use setCurrent year to set the year
  const getDaysInMonth = (month: number, year: number) => {
    // Here January is 1 based
    //Day 0 is the last day in the previous month
    // look at what the month is here
    return new Date(year, month, 0).getDate();
  };

  const changeToNextMonthHandler = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
      setCurrentMonthInWords(months[0].monthNameFull);
    } else if (currentMonth < 12) {
      setCurrentMonth(currentMonth + 1);
      setCurrentMonthInWords(months[currentMonth + 1].monthNameFull);
    }
  };

  const changeToPreviousMonthHandler = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentMonthInWords(months[11].monthNameFull);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
      setCurrentMonthInWords(months[currentMonth - 1].monthNameFull);
    }
  };

  const changeMonthHandler = () => {
    setChangeMonth(true);
    setChangeYear(false);
  };

  const changeYearHandler = () => {
    setChangeYear(!changeYear);
    setChangeMonth(false);
  };

  const goBackButtonHandler = () => {
    setChangeYear(false);
    setChangeMonth(false);
  };

  const helper = [];
  for (let i = startingYear; i < endingYear; i++) {
    helper[i] = startingYear + i - startingYear;
  }
  const arrayOfYearsToBeShown = helper;

  const changeSelectedYearHandler = (selectedYear: any) => {
    setCurrentYear(selectedYear);
  };

  const selectDateHandler = (date: number) => {
    setSelectDate(date);
    if (date < 10 && date > 0) {
      if (currentMonth + 1 < 10 && currentMonth + 1 > 0) {
        selectedDate(`0${date}/0${currentMonth + 1}/${currentYear}`);
      } else {
        selectedDate(`0${date}/${currentMonth + 1}/${currentYear}`);
      }
    } else if (date >= 10) {
      if (currentMonth + 1 < 10 && currentMonth + 1 > 0) {
        selectedDate(`${date}/0${currentMonth + 1}/${currentYear}`);
      } else {
        selectedDate(`${date}/${currentMonth + 1}/${currentYear}`);
      }
    }
  };

  const changeCurrentMonthHandler = (month: any) => {
    setCurrentMonthInWords(month.monthNameFull);
    setCurrentMonth(month.monthNumber);
    setChangeMonth(false);
  };

  const daysDisplayed = [];
  for (let i = 0; i < getDaysInMonth(currentMonth + 1, currentYear) + firstDayOfMonth.getDay(); i++) {
    if (i < firstDayOfMonth.getDay()) {
      daysDisplayed[i] = "";
    } else {
      daysDisplayed[i] = i + 1 - firstDayOfMonth.getDay();
    }
  }

  return (
    <div
      style={{ position: position || "absolute" }}
      className={
        hideCalendar
          ? "hidden"
          : "w-[246px] z-10 h-[16.813rem] bg-[#FFFFFF] p-[14px] border-[0.5px] rounded-[10px] border-[#EEEEEE] calendar"
      }
    >
      {/* {changeMonth === false && ( */}
      <div className="flex flex-row justify-between items-center border-b-[1px] border-[#F4F4F4] font-[400] font-[Inter] not-italic text-[#272B30] text-[14px] leading-[16px] h-[2.563rem]">
        <button
          type="button"
          id="month-picker-button-main-calendar"
          className="Calendar-month flex items-center"
          onClick={changeMonthHandler}
        >
          {currentMonthInWords}
        </button>
        <button
          type="button"
          id="year-picker-button-main-calendar"
          className="Calendar-year flex items-center relative"
          onClick={changeYearHandler}
        >
          {currentYear}

          <div
            className={
              changeYear
                ? "absolute z-30 top-[25px] right-[-10px] flex flex-col items-center bg-[#ffffff] border-[0.5px] h-[250px] overflow-hidden overflow-y-scroll border-[#05055208] shadow-[0px_0px_15px_rgba(85,78,241,0.1)] year-select-calendar"
                : "hidden"
            }
          >
            {arrayOfYearsToBeShown.map((year: any, index: number) => {
              return (
                <div
                  key={year}
                  onClick={() => changeSelectedYearHandler(year)}
                  id={currentYear === year ? "current-active-year" : `${index}not-active-year`}
                  className={
                    currentYear === year
                      ? "px-[16px] py-[8px] bg-[#554EF1] text-[white] font-[Inter] not-italic font-[500] text-[14px]"
                      : "px-[16px] py-[8px] hover:bg-[#554EF1] hover:text-[white] font-[Inter] not-italic font-[500] text-[14px]"
                  }
                >
                  {year}
                </div>
              );
            })}
          </div>
        </button>

        <div className="flex flex-row items-center">
          <button
            type="button"
            onClick={changeToPreviousMonthHandler}
            className="p-[8px] rounded-[100px] hover:bg-[#F6F7FF]"
          >
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.73703 9.73843C6.05676 9.42235 6.0848 8.92873 5.82188 8.58148L5.73428 8.48203L2.18255 5.00118L5.73428 1.51797C6.05539 1.20325 6.0856 0.709749 5.8242 0.361395L5.73703 0.261567C5.41731 -0.0545187 4.91597 -0.0842572 4.56208 0.173053L4.46067 0.258858L0.265722 4.37044C-0.0563653 4.68613 -0.0856459 5.18144 0.17788 5.52979L0.265722 5.62956L4.46067 9.74114C4.81389 10.0873 5.38533 10.0861 5.73703 9.73843Z"
                fill="#554EF1"
              />
            </svg>
          </button>
          <button
            type="button"
            onClick={changeToNextMonthHandler}
            className="p-[8px] rounded-[100px] hover:bg-[#F6F7FF]"
          >
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.26297 9.73843C-0.0567559 9.42235 -0.0848036 8.92873 0.178117 8.58148L0.265721 8.48203L3.81745 5.00118L0.265721 1.51797C-0.055386 1.20325 -0.0855958 0.709749 0.175801 0.361395L0.262969 0.261567C0.582695 -0.0545197 1.08403 -0.0842572 1.43792 0.173053L1.53933 0.258858L5.73428 4.37044C6.05637 4.68613 6.08565 5.18144 5.82212 5.52979L5.73428 5.62956L1.53933 9.74114C1.18611 10.0873 0.614668 10.0861 0.26297 9.73843Z"
                fill="#554EF1"
              />
            </svg>
          </button>
        </div>
      </div>
      {/* )} */}

      {/* {changeMonth === false && ( */}
      <div className="flex flex-row items-center justify-center px-[4px] gap-[15px] w-[100%] mt-[11px] mb-[7px]">
        <div className="w-[100%] font-[Inter] not-italic font-[400] text-[14px] leading-[16px] flex items-center text-center text-[#323232]">
          Su
        </div>
        <div className="w-[100%] font-[Inter] not-italic font-[400] text-[14px] leading-[16px] flex items-center text-center text-[#323232]">
          Mo
        </div>
        <div className="w-[100%] font-[Inter] not-italic font-[400] text-[14px] leading-[16px] flex items-center text-center text-[#323232]">
          Tu
        </div>
        <div className="w-[100%] font-[Inter] not-italic font-[400] text-[14px] leading-[16px] flex items-center text-center text-[#323232]">
          We
        </div>
        <div className="w-[100%] font-[Inter] not-italic font-[400] text-[14px] leading-[16px] flex items-center text-center text-[#323232]">
          Th
        </div>
        <div className="w-[100%] font-[Inter] not-italic font-[400] text-[14px] leading-[16px] flex items-center text-center text-[#323232]">
          Fr
        </div>
        <div className="w-[100%] font-[Inter] not-italic font-[400] text-[14px] leading-[16px] flex items-center text-center text-[#323232]">
          Sa
        </div>
      </div>
      {/* )} */}

      {/* {changeMonth === false && ( */}
      <div className="flex flex-row items-center w-[100%] gap-[10px] flex-wrap">
        {daysDisplayed.map((element: any) => {
          return (
            <button
              key={element}
              type="button"
              onClick={() => {
                selectDateHandler(element);
              }}
              className={
                element === selectDate && currentMonth === month && currentYear === year
                  ? "w-[1.348rem] font-[Inter] font-[400] text-[14px] rounded-[100px] bg-[#554EF1] text-[white] text-center calendar-button"
                  : "w-[1.348rem] font-[Inter] font-[400] text-[14px] rounded-[100px] hover:bg-[#554EF1] hover:text-[white] calendar-button"
              }
            >
              {element}
            </button>
          );
        })}
      </div>
      {/* )} */}

      <div
        className={
          changeMonth
            ? "visible z-100 bg-[#ffffff] h-[16.813rem] w-[15.063rem] absolute left-0 top-0 py-[24px] px-[16px] rounded-[5px]"
            : "hidden"
        }
      >
        <div className="flex items-center justify-between border-b-[1px] border-[#EEEEEE]">
          <p className="text-[14px]">{currentMonthInWords}</p>
          <button type="button" onClick={goBackButtonHandler} className="p-[5px] hover:bg-[#F6F7FF] rounded-[100px]">
            <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 5L5 15" stroke="#ADADAD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M5 5L15 15" stroke="#ADADAD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>
        <div className="flex flex-row mt-[5px] gap-[5px] flex-wrap items-start justify-evenly show-all-months-calendar">
          {months.map((month) => {
            return (
              <button
                key={month.monthNumber}
                type="button"
                onClick={() => changeCurrentMonthHandler(month)}
                className={
                  month.monthNameFull === currentMonthInWords
                    ? "bg-[#554EF1] w-[50px] h-[50px] rounded-[100px] text-[13px] font-[700] text-[white] calendar-button"
                    : "hover:bg-[#554EF1] hover:text-[white] w-[50px] h-[50px] rounded-[100px] text-[13px] font-[700] calendar-button"
                }
              >
                {month.monthNameShort}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
