import React from "react";
import "./AvatarFallBack.css";

export interface AvatarProps {
  avatarUrl: string;
  size: "Small" | "Normal" | "Medium" | "Large" | "X-Large" | "XX-Large";
  backgroundColor?: string;
  username: string;
  onClick: any;
  other?: string;
  userId?: string;
  groupId?: string;
  email?: string;
  isExternal?: boolean;
  isDoclanding?: boolean;
}
const AvatarFallback = ({ backgroundColor, avatarUrl, username, size, onClick, other, isExternal }: AvatarProps) => {
  const avatarColor = backgroundColor;

  if (avatarUrl !== "") {
    return (
      <img
        data-testid="avatar-test-id"
        onClick={onClick}
        src={avatarUrl}
        alt="text"
        style={{ backgroundColor: avatarColor }}
        className={
          avatarUrl === "" && username === ""
            ? `loading-shimmer-${size}`
            : `avatar-image-${size} bg-[#E6E6E6] object-fill ${onClick ? "avatar-hover" : ""}`
        }
      />
    );
  } else {
    return (
      <button
        data-testid="avatar-test-id"
        style={{ backgroundColor: avatarColor }}
        onClick={onClick}
        className={
          username !== ""
            ? `default-avatar-${size} ${other ? "text-[black]" : "text-[white]"} justify-center ${
                isExternal ? "rounded-[2px]" : "rounded-[100px]"
              }  bg-[#2e8b57] flex flex-row items-center ${other ? "border border-[#E9EDF5]" : ""} p-[10px] uppercase ${
                size === "XX-Large" || !onClick ? "" : "avatar-hover"
              } `
            : `loading-shimmer-${size}`
        }
      >
        {other ? other : username ? username[0] : "?"}
      </button>
    );
  }
};
export default AvatarFallback;
