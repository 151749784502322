import React from "react";
import Input from "../../components/Input";
import { fieldTypeOptions } from "./index";
import Dropdown from "../../components/Dropdown";
import DateInput from "../../components/DateInput";

interface DefaultValueFieldProps {
  defaultValue: any;
  setDefaultValue: React.Dispatch<React.SetStateAction<string>>;
  inputType: (typeof fieldTypeOptions)[number]["value"];
  options: Array<string>;
}

const getLabelFromOption = (option: any, options: { label: string; value: string }[]) => {
  if (option?.label && typeof option.label === "string") {
    return option.label;
  }
  const fieldTypeOption = options.find((type) => type.value === option);
  let label = "Select";
  if (fieldTypeOption?.label) {
    label = fieldTypeOption.label;
  }
  return label;
};

const booleanFieldOptions = [
  { label: "True", value: "true" },
  { label: "False", value: "false" },
];

const FieldTypes = ({ defaultValue, setDefaultValue, inputType, options }: DefaultValueFieldProps): JSX.Element => {
  const dropdownOptions = options.map((option) => ({ label: option, value: option }));

  switch (inputType) {
    case "text":
      return <Input value={defaultValue} setValue={setDefaultValue} placeholder="Value" type="text" title="text" />;
    case "number":
      return <Input value={defaultValue} setValue={setDefaultValue} placeholder="Value" type="number" title="number" />;
    case "email":
      return <Input value={defaultValue} setValue={setDefaultValue} placeholder="Value" type="email" title="email" />;
    case "boolean":
      return (
        <Dropdown
          width="w-full"
          option={getLabelFromOption(defaultValue, booleanFieldOptions)}
          options={booleanFieldOptions}
          setOption={(option) => setDefaultValue(option)}
        />
      );
    case "date":
      return <DateInput value={defaultValue} setValue={setDefaultValue} />;
    case "dropdown":
      if (!options) {
        return <></>;
      }
      dropdownOptions.unshift({ label: "Select", value: "" });
      return (
        <Dropdown
          width="w-full"
          option={getLabelFromOption(defaultValue, dropdownOptions)}
          options={dropdownOptions}
          setOption={(option) => setDefaultValue(option)}
        />
      );
    default:
      return <div>Not implemented</div>;
  }
};

export default FieldTypes;
