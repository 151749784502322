import React, { SVGProps } from "react";
const ActionPendingIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1.25rem" height="1.25rem" fill="none" {...props}>
    <path
      stroke={props.stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.383}
      d="M10 2v1.656M15.659 4.342l-1.171 1.17M18 10h-1.655M15.659 15.658l-1.171-1.17M10 16.345V18M5.512 14.487l-1.17 1.17M3.653 10H1.998M5.512 5.512l-1.17-1.17"
    />
  </svg>
);

export default ActionPendingIcon;
