import React, { useState } from "react";
import TagField from "./AddField";
import TagView from "./TagView";
import SearchIcon from "../../icons/SearchIcon";

export default function Fields({
  tags,
  templateId,
  reloadTags,
}: {
  tags: Array<any>;
  templateId: string;
  reloadTags: () => void;
}): JSX.Element {
  const [searchValue, setSearchValue] = useState<string>("");
  const filteredTags = tags.filter((tag) => tag.name.toLowerCase().includes(searchValue.toLowerCase()));

  return (
    <>
      <TagField />
      <div className="overflow-y-auto bg-[#FAFAFA] p-[0.75rem] flex flex-col gap-[0.75rem] flex-grow">
        <form className="px-[0.625rem] bg-[#FFF] rounded-[0.25rem] border-[1px] border-solid border-[#D1D1D1] flex flex-row self-stretch gap-[0.625rem]">
          <div className="flex self-stretch items-center">
            <SearchIcon fill="#616161" />
          </div>
          <input
            type="text"
            title="search fields"
            placeholder="Search"
            className="py-[0.25rem] focus:shadow-outline border-none appearence-none focus:outline-none placeholder:text-[#707070] text-[0.875rem] leading-[1.25rem] flex flex-grow"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </form>
        <TagView tags={filteredTags} render="fields" templateId={templateId} reloadTags={reloadTags} allTags={tags} />
      </div>
    </>
  );
}
