import { Comment, CommentReply } from "../../Pages/Comments/interface";

export default async function getAllWordComments(context: Word.RequestContext): Promise<Array<Comment>> {
  const body = context.document.body;
  if (!body) {
    return [];
  }
  body.load(["text", "comment"]);
  await body.context.sync();
  const comments = body.getComments();
  comments.load("items");
  await comments.context.sync();
  const newCommentsState: Comment[] = [];
  const docTextRanges: Word.Range[] = [];
  for (const comment of comments.items) {
    comment.load(["id", "content", "authorName", "authorEmail", "resolved", "replies", "creationDate"]);
    await comment.replies.context.sync();
  }
  await comments.context.sync();
  for (const comment of comments.items) {
    const docTextRange = comment.getRange();
    docTextRange.load("text");
    docTextRanges.push(docTextRange);
    for (const reply of comment.replies.items) {
      reply.load(["id", "content", "authorName", "authorEmail", "creationDate"]);
    }
  }
  await context.sync();
  for (let i = 0; i < comments.items.length; i++) {
    const comment = comments.items[i];
    const repliesArray: CommentReply[] = [];
    for (const reply of comment.replies.items) {
      repliesArray.push({
        id: reply.id,
        content: reply.content,
        dateUtc: Math.floor(reply.creationDate.getTime() / 1000),
        author: {
          name: reply.authorName,
          email: reply.authorEmail,
        },
      });
    }
    const docText = docTextRanges[i].text;
    newCommentsState.push({
      id: comment.id,
      content: comment.content,
      author: { name: comment.authorName, email: comment.authorEmail },
      dateUtc: Math.floor((comment.creationDate?.getTime() || 0) / 1000),
      docText: docText,
      isResolved: comment.resolved,
      replies: repliesArray,
    });
  }
  return newCommentsState;
}
