import React, { useState, useLayoutEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import HyperStartIconWithText from "../../icons/HyperStartIconWithText";
import Button from "../../components/Button";
import AppDescription from "./AppDescription";

// reference for the login flow https://community.auth0.com/t/using-auth0-in-office-web-add-ins/58915/5
const LoginPage = (): JSX.Element => {
  const { loginWithRedirect, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  const notLoadedInIframe = window.location === window.parent.location;
  const isLoadedInsideWord = Office?.context?.document?.url;

  const [isPopUpBlocked, setIsPopUpBlocked] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (notLoadedInIframe && !isLoadedInsideWord) {
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get("code");
      if (!code) {
        loginWithRedirect({
          authorizationParams: {
            redirect_uri: `${window.location.origin}/taskpane.html`,
            audience: "https://clm.dev.hyperverge",
            scope: "openid profile email offline_access",
          },
        });
      }
    }
  }, []);

  const openAsyncDialogBox = async (): Promise<void> => {
    let dialog: Office.Dialog;
    Office.context.ui.displayDialogAsync(
      `${window.location.origin}/taskpane.html`,
      {
        height: 400,
        width: 200,
      },
      (asyncResult) => {
        dialog = asyncResult.value;
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
      }
    );
    const processMessage = async (
      arg: { message: string; origin: string | undefined } | { error: number }
    ): Promise<void> => {
      if ((arg as { message: string; origin: string | undefined }).message) {
        const messageFromDialog = JSON.parse((arg as { message: string; origin: string | undefined }).message);
        if (messageFromDialog.outcome === "success") {
          const searchParams = new URLSearchParams(location.search);
          searchParams.set("code", messageFromDialog.code);
          await getAccessTokenSilently().catch(async () => {
            return await getAccessTokenWithPopup().catch((e) => {
              console.error(e);
              setIsPopUpBlocked(true);
              return "";
            });
          });
        }
      }
      dialog.close();
    };
  };

  const handleClick = async (): Promise<void> => {
    if (notLoadedInIframe) {
      loginWithRedirect({
        authorizationParams: {
          redirect_uri: `${window.location.origin}/taskpane.html`,
          audience: "https://clm.dev.hyperverge",
          scope: "openid profile email offline_access",
        },
      });
    } else {
      await openAsyncDialogBox();
    }
  };

  if (isPopUpBlocked) {
    return (
      <div className="flex-grow flex flex-col justify-center items-center gap-8 pl-8 pr-8 pb-[20%]">
        <HyperStartIconWithText width="92" height="14" />
        <div className="flex flex-col items-center gap-[1.75rem]">
          <AppDescription isPopUpBlocked={true} />
          <Button
            variant="primary"
            customClasses="w-[80%]"
            label="Authenticate"
            handleClick={async () => {
              await getAccessTokenWithPopup();
              setIsPopUpBlocked(false);
            }}
            isDisabled={false}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex-grow flex flex-col justify-center items-center gap-8 pl-8 pr-8 pb-[20%]">
      <HyperStartIconWithText width="92" height="14" />
      <div className="flex flex-col items-center gap-[1.75rem]">
        <AppDescription isPopUpBlocked={false} />
        <Button variant="primary" customClasses="w-[80%]" label="Login" handleClick={handleClick} isDisabled={false} />
      </div>
    </div>
  );
};

export default LoginPage;
