import * as React from "react";
import { SVGProps } from "react";
const HamburgerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" {...props}>
    <path
      fill="#333"
      d="M1.25 11.635a.726.726 0 0 1-.534-.216.726.726 0 0 1-.216-.535c0-.212.072-.39.216-.534a.726.726 0 0 1 .534-.215h15.5c.212 0 .39.072.534.215a.726.726 0 0 1 .216.535c0 .213-.072.39-.216.534a.726.726 0 0 1-.534.216H1.25Zm0-4.885a.726.726 0 0 1-.534-.216A.726.726 0 0 1 .5 6c0-.213.072-.39.216-.535a.726.726 0 0 1 .534-.215h15.5c.212 0 .39.072.534.216A.726.726 0 0 1 17.5 6c0 .213-.072.391-.216.535a.726.726 0 0 1-.534.215H1.25Zm0-4.885a.726.726 0 0 1-.534-.215.726.726 0 0 1-.216-.535C.5.903.572.725.716.581A.726.726 0 0 1 1.25.365h15.5c.212 0 .39.072.534.216a.726.726 0 0 1 .216.535c0 .212-.072.39-.216.534a.726.726 0 0 1-.534.215H1.25Z"
    />
  </svg>
);
export default HamburgerIcon;
