import React, { useContext, useEffect, useState } from "react";
import Input from "../../components/Input";
import ColumnsBuilder from "./ColumnsBuilder";
import Button from "../../components/Button";
import { useNavigationDispatch } from "../../features/Navigation/NavigationContext";
import { useNotificationToastDispatch } from "../../features/NotificationToast/NotificationContext";
import { FetchContext } from "../../api/FetchContext";
import { BACKEND_URL } from "../../constants";
import { tableSchema } from "./tableSchema";
import formatJoiErrors, { FormattedJoiErrors } from "../../utils/formatJoiErrors";
import RenderErrors from "../../components/RenderErrors";

const EditTable = ({
  tags,
  templateId,
  reloadTags,
  url,
}: {
  tags: Array<any>;
  templateId: string;
  reloadTags: () => void;
  url: URL;
}) => {
  const [tableName, setTableName] = useState("");
  const [errors, setErrors] = useState<FormattedJoiErrors | null>(null);
  const setSelectedTab = useNavigationDispatch();
  const [columns, setColumns] = useState<Array<string>>([""]);
  const fetcher = useContext(FetchContext);
  const notificationDispatch = useNotificationToastDispatch();

  const handleEditTable = async () => {
    const data = {
      name: tableName,
      tableColumns: columns.map((column) => ({ value: column, type: "string" })),
    };
    const { error } = tableSchema.validate(data, {
      abortEarly: false,
    });
    if (error) {
      const formattedErrors = formatJoiErrors(error.details);
      setErrors(formattedErrors);
      return;
    }
    const newTags = tags.map((tag) => {
      if (tag._id === tagId) {
        return {
          ...tag,
          ...data,
        };
      }
      return tag;
    });
    if (fetcher && notificationDispatch) {
      const res = await fetcher(`${BACKEND_URL}/api/v1/template/updateTags`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          templateId,
          tags: newTags,
        }),
      }).catch((e) => {
        if (e instanceof Error) {
          notificationDispatch({ type: "error", message: e.message });
        }
        return e;
      });

      if (!res) {
        reloadTags();
      }
    }
    if (setSelectedTab) {
      setSelectedTab("Tables");
    }
  };
  const tagId = url.searchParams.get("tagId");
  useEffect(() => {
    if (tagId) {
      const tag = tags.find((tag) => tag._id === tagId);
      if (tag) {
        setTableName(tag.name);
        setColumns(tag.tableColumns.map((column: any) => column.value));
      }
    }
  }, []);
  return (
    <div className="overflow-hidden flex-grow flex flex-col justify-between bg-[#FAFAFA]">
      <div className="overflow-y-auto bg-[#FDFDFD] py-4 flex flex-col flex-grow">
        <div className="py-[0.5rem] px-[1rem]">
          <span className="text-[#171C26] text-[1.25rem] font-[600] leading-[2rem]">Edit table</span>
        </div>
        <div className="px-[1rem] py-[1rem] overflow-y-auto flex flex-col flex-grow">
          <div className="flex flex-col gap-[1rem] mb-[1.5rem]">
            <div className="flex flex-col gap-[0.5rem]">
              <div className="flex gap-[0.25rem]">
                <span className="text-[0.875rem] leading-[1.25rem]">Table name</span>
                <span className="text-[#B10E1C] text-[0.875rem] leading-[1.25rem]">*</span>
              </div>
              <Input value={tableName} setValue={setTableName} placeholder="" title="Enter Table Name" />
              <RenderErrors errors={errors} fieldName="name" />
            </div>
          </div>
          <ColumnsBuilder columns={columns} setColumns={setColumns} errors={errors} />
        </div>
      </div>
      <div className="p-[1rem] flex gap-[0.5rem] border-t border-t-solid border-t-[#E0E0E0] bg-[#FAFAFA]">
        <Button variant="primary" label="Edit" handleClick={handleEditTable} />
        <Button
          variant="secondary"
          label="Cancel"
          handleClick={async () => {
            if (setSelectedTab) {
              setSelectedTab("Tables");
            }
          }}
        />
      </div>
    </div>
  );
};

export default EditTable;
