import React, { useContext, useState } from "react";
import Button from "../../components/Button";
import ChevronDownIcon from "../../icons/ChevronDownIcon";
import ChevronUpIcon from "../../icons/ChevronUpIcon";
import JustificationBox from "./JustificationBox";
import { bgColorOptions } from "../../constants";
import isDeviationCompliant from "../../utils/isCompliant";
import getApproverName from "../../utils/getApproverName";
import { User } from "../Comments/interface";
import { useDetectClickOutside } from "react-detect-click-outside";
import GroupIconWhite from "../../icons/GroupIconWhite";
import AvatarFallback from "../../components/AvatarFallBack";
import "./FallbackPosition.css";
import { FetchContext } from "../../api/FetchContext";
import { insertPositionApi } from "./usePosition";
import { useWorkflowData } from "../../features/WorkflowDataProvider/WorkflowDataProvider";
import getMatchingParagraph from "../../utils/wordApi/getMatchingParagraph";
import { useOrgData } from "../../features/OrgDataProvider/OrgDataProvider";

export default function FallbackPosition({
  deviationId,
  isActionsDisabled,
}: {
  deviationId: any;
  isActionsDisabled: boolean;
}): JSX.Element {
  const fetcher = useContext(FetchContext);
  const { data: orgData } = useOrgData();
  const { users, groups } = orgData;

  const { data: workflowData, reload: reloadWorkflowData } = useWorkflowData();
  const workflow = workflowData.workflowResponse.workflow;
  const { deviations: allDeviations, playbooks } = workflowData;
  const deviation = allDeviations.find((deviation) => deviation._id === deviationId);
  const positions = playbooks.find((playbook: any) => playbook._id === deviation.playBookId._id)?.positions;
  const fallbackPositions = positions?.filter((position: any) => position.type === "fallback") || [];
  const fallbackPosition = fallbackPositions[0];

  const [shouldShowStandardPosition, setShouldShowStandardPosition] = useState<boolean>(false);
  const [shouldAddComment, setShouldAddComment] = useState<boolean>(true);
  const [showMoreAvatarClicked, setShowMoreAvatarClicked] = useState<Record<string, boolean>>({});
  const [selectedGroupToShow, setSelectedGroupToShow] = useState<any>({
    name: "",
    groupId: "",
  });
  const showMoreAvatarClickedRef = useDetectClickOutside({
    onTriggered: () => {
      setShowMoreAvatarClicked({});
      setSelectedGroupToShow({ name: "", groupId: "" });
    },
  });

  const renderApprover = (approvers: Array<any>) => {
    return (
      <div
        className={`flex w-max justify-between items-center pl-[12px] pr-[4px] py-[4px] ${
          approvers.length > 1 ? "border border-[#D9DBDD]" : ""
        } rounded-[64px]`}
      >
        <div className="flex flex-col gap-[8px]">
          <div className="flex gap-[-10px]">
            {" "}
            {approvers
              .filter((_user: any, index: number) => index < 3)
              .map((approver: any, index: number) => (
                <div
                  key={index}
                  className=" avatar-div cursor-pointer ml-[-10px] border-[2px] border-[#FFF] rounded-[50%] hover:z-10 hover:relative"
                >
                  <AvatarFallback
                    avatarUrl={""}
                    size={"Medium"}
                    backgroundColor={bgColorOptions[index % bgColorOptions.length]}
                    username={getApproverName({
                      approver,
                      users,
                      groups,
                    })}
                    onClick={undefined}
                  />
                  <span className="avatar-tooltiptext">
                    {(() => {
                      if (approver.type === "group") {
                        const userList = users.filter((userItem: User) => {
                          return userItem.groups?.includes(approver.approverId || "");
                        });

                        if (userList.length) {
                          return userList.map((user: User) => user.firstName + " " + user.lastName).join(", ");
                        }
                        return getApproverName({
                          approver,
                          users,
                          groups,
                        });
                      } else {
                        return getApproverName({
                          approver,
                          users,
                          groups,
                        });
                      }
                    })()}
                  </span>
                </div>
              ))}
            {approvers.filter((_user: any, index: number) => index >= 3).length > 0 && (
              <div ref={showMoreAvatarClickedRef}>
                <div
                  className="cursor-pointer ml-[-8px] border-[2px] border-[#D9DBDD] rounded-[50%] z-10 relative"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowMoreAvatarClicked((prevState: Record<string, boolean>) => ({
                      ...prevState,
                      [selectedGroupToShow.groupId]: !prevState[selectedGroupToShow.groupId],
                    }));
                  }}
                >
                  <p className="default-avatar-Medium text-[#616161] justify-center rounded-[100px] bg-[#E6E6E6] text-[12px] flex flex-row items-center  p-[10px] uppercase leading-[16px]">
                    +{approvers.filter((_user: any, index: number) => index >= 3).length}
                  </p>

                  {showMoreAvatarClicked[selectedGroupToShow.groupId] && (
                    <div className="tooltip-box-without-hover">
                      {!selectedGroupToShow?.name && (
                        <div>
                          {approvers
                            ?.filter((_user: any, index: number) => index >= 3)
                            .map((approver: any, index: number) => {
                              const value = getApproverName({
                                approver,
                                users,
                                groups,
                              });
                              return (
                                <div className="flex gap-1 items-center" key={index}>
                                  <span>{value}</span>
                                  {approver.type === "group" && <GroupIconWhite />}
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col">
      <div className="rounded-[0.25rem] bg-[#fff] border-[1px] border-solid border-[#D9DBDD] p-[0.75rem] flex flex-col">
        <div
          className="cursor-pointer self-stretch flex justify-between"
          onClick={(e) => {
            e.stopPropagation();
            setShouldShowStandardPosition((prev) => !prev);
          }}
        >
          <div className="px-[0.125rem]">
            <span className="text-[0.875rem] leading-[1.25rem] font-[600]">Fallback position</span>
          </div>
          {shouldShowStandardPosition ? <ChevronUpIcon fill="#616161" /> : <ChevronDownIcon fill="#616161" />}
        </div>
      </div>
      {shouldShowStandardPosition ? (
        <div className="rounded-b-[0.25rem] bg-[#fff] border-x-[1px] border-b-[1px] border-solid border-[#E6E6E6] p-[0.75rem] flex flex-col gap-4">
          <span className="text-[0.875rem] leading-[1.25rem]">{fallbackPosition.clause}</span>
          <JustificationBox
            note={fallbackPosition.note}
            justification={fallbackPosition.justification}
            shouldShowComment={!isDeviationCompliant(deviation)}
            shouldAddComment={shouldAddComment}
            setShouldAddComment={setShouldAddComment}
          />
          <div className="w-full h-[1px] bg-[#E6E6E6]"></div>
          <div className="flex flex-col gap-2">
            <span className="text-[#616161] text-[0.75rem] font-[600] leading-[1.125rem]">
              Approvers for this fallback position:
            </span>
            {renderApprover(fallbackPosition.approvers)}
          </div>
          <div className="w-full h-[1px] bg-[#E6E6E6]"></div>
          {deviation.isMet || isActionsDisabled ? (
            <div className="w-[180px]">
              <Button
                variant="secondary"
                label="Copy fallback position"
                handleClick={async () => {
                  navigator.clipboard.writeText(fallbackPosition.clause);
                }}
              />
            </div>
          ) : (
            <div className="w-[180px]">
              <Button
                variant="secondary"
                label="Use fallback position"
                handleClick={async () => {
                  if (!fetcher) {
                    return;
                  }
                  const commentId = await Word.run(async (context: Word.RequestContext): Promise<string> => {
                    const paragraph = await getMatchingParagraph(context, deviation.docText, deviation.paragraphIndex);
                    if (!paragraph) {
                      return "";
                    }
                    paragraph.insertText(fallbackPosition.clause, "Replace");
                    if (shouldAddComment) {
                      paragraph.getRange().insertComment(fallbackPosition.note);
                    }
                    const comment = paragraph
                      .getRange()
                      .insertComment(`(Internal) (approval) ${fallbackPosition.justification}`);
                    await context.sync();
                    return comment.id;
                  });
                  if (!commentId) {
                    return;
                  }
                  await insertPositionApi({
                    fetcher,
                    workflowId: workflow._id,
                    deviationId: deviation._id,
                    type: "fallback",
                    commentId,
                    positionId: fallbackPosition._id,
                  });
                  await reloadWorkflowData();
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
