import React, { createContext, useContext, useReducer } from "react";
import NotificationToast from "../../storybook/NotificationToast/NotificationToast";

export type NotificationType = "success" | "error" | "info";

export type NotificationData = {
  message: string;
  timeOut: number;
  type: NotificationType;
  key: string;
  color?: string;
  cta?: () => void;
  ctaMessage?: string;
};

export type NotificationDataAction = {
  message: string;
  timeOut?: number;
  type: NotificationType;
  key?: string;
  color?: string;
  cta?: () => void;
  ctaMessage?: string;
};

const initialNotificationData: NotificationData = {
  type: "info",
  message: "",
  timeOut: 3000,
  key: crypto.randomUUID(),
};

const NotificationToastContext = createContext<NotificationData | null>(null);

const NotificationToastDispatchContext = createContext<React.Dispatch<NotificationDataAction> | null>(null);

export function NotificationToastProvider({ children }: { children: JSX.Element }): JSX.Element {
  const [notificationData, dispatch] = useReducer(notificationToastReducer, initialNotificationData);

  return (
    <NotificationToastContext.Provider value={notificationData}>
      <NotificationToastDispatchContext.Provider value={dispatch}>
        {children}
        {notificationData.message && <NotificationToast {...notificationData} />}
      </NotificationToastDispatchContext.Provider>
    </NotificationToastContext.Provider>
  );
}

export function useNotificationToast(): NotificationData | null {
  return useContext(NotificationToastContext);
}

export function useNotificationToastDispatch(): React.Dispatch<NotificationDataAction> | null {
  return useContext(NotificationToastDispatchContext);
}

function notificationToastReducer(_state: NotificationData, action: NotificationDataAction): NotificationData {
  return {
    type: action.type,
    message: action.message,
    timeOut: action.timeOut || 3000,
    key: action.key || crypto.randomUUID(), // to force rerender for same error message
  };
}
