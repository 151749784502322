/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */

export type ClauseApproverAssigned = {
  type: "group" | "user";
  approverId: string;
};

export enum ClauseType {
  nonSolicit = "Non Solicitation Of Employees",
  toc = "Termination For Convenience",
  liability = "Liability",
  insurance = "Insurance",
  renewalTerm = "Renewal Term",
  governingLaw = "Governing Law",
  logoUsage = "Logo Usage",
  auditRights = "Audit Rights",
  antiAssignment = "Anti-Assignment",
  changeOfControl = "Change Of Control",
  securityBreachNotification = "Security Breach Notification",
  nonCompetition = "Non-Competition",
  terminationForCause = "Termination For Cause",
  indemnity = "Indemnity",
  sourceCOdeEscrow = "Source Code Escrow",
  returnOfConfidentialInformation = "Return Of Confidential Information",
  ipOwnership = "IP Ownership",
  paymentTerms = "Payment Terms",
  custom = "custom",
}
