import React from "react";
import Menu from "./Menu";
import { WEB_APP_URL } from "../../constants";

export default function TemplateNotFound({ templateId }: { templateId: string }): JSX.Element {
  return (
    <div className="p-[0.75rem] flex-grow flex flex-col justify-center items-center">
      <Menu url={templateId ? `${WEB_APP_URL}/template/create/${templateId}` : `${WEB_APP_URL}/template`} />
      <span>Template not found</span>
    </div>
  );
}
