import React, { useState } from "react";
import Calendar from "./Calendar/Calendar";
import { useDetectClickOutside } from "react-detect-click-outside";

const DateInput = ({
  customClasses,
  placeHolder,
  isModal,
  value,
  setValue,
}: {
  customClasses?: string;
  placeHolder?: string;
  isModal?: boolean;
  value: string;
  // eslint-disable-next-line no-unused-vars
  setValue: (date: any) => void;
}) => {
  const [openCalendar, setOpenCalendar] = useState(false);
  const markAsTouched = () => {
    setOpenCalendar(true);
  };
  const calendarRef = useDetectClickOutside({
    onTriggered: () => {
      setOpenCalendar(false);
    },
  });

  return (
    <div
      ref={calendarRef}
      onClick={() => {
        markAsTouched();
      }}
    >
      <div
        className={`
          w-full flex items-center rounded-[6px] p-[6px_8px] h-[36px] justify-between border-[1px] border-solid border-[#D1D1D1]
         ${customClasses ? customClasses : ""}`}
        onClick={() => {
          setOpenCalendar(true);
        }}
        data-testid="date-picker"
      >
        <input
          placeholder={placeHolder ? placeHolder : "DD/MM/YYYY"}
          value={value}
          readOnly={true}
          className={`py-[0.25rem] focus:shadow-outline border-none appearence-none focus:outline-none placeholder:text-[#707070] text-[0.875rem] leading-[1.25rem] placeholder:text-left`}
        />
      </div>

      <div className={openCalendar ? "" : "hidden"}>
        <Calendar
          defaultDate={value}
          selectedDate={(date: any) => {
            setOpenCalendar(false);
            setValue(date);
          }}
          position={isModal ? "sticky" : "absolute"}
        />
      </div>
    </div>
  );
};

export default DateInput;
