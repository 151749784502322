import React from "react";

const EditIcon = ({ color }: { color: string }): JSX.Element => {
  return (
    <div className="">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.95703 16.0415L7.4987 15.2082L15.2428 7.46413C15.5682 7.13869 15.5682 6.61106 15.2428 6.28562L13.7129 4.7558C13.3875 4.43036 12.8599 4.43036 12.5344 4.7558L4.79036 12.4999L3.95703 16.0415Z"
          stroke={color ? color : "#464F60"}
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0404 16.043H11.457"
          stroke={color ? color : "#464F60"}
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default EditIcon;
