import React from "react";
import Avatar from "../../storybook/Avatar";

export default function Author({
  user,
  creationDate,
}: {
  user: { firstName: string; lastName: string; imageUrl: string };
  creationDate?: number;
}): JSX.Element {
  let createdDateString = "";
  if (creationDate && creationDate > 0) {
    createdDateString = new Date(creationDate * 1000).toLocaleDateString(undefined, {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  }
  return (
    <div className="flex gap-[0.5rem]">
      <Avatar avatarUrl={user.imageUrl} username={user.firstName} backgroundColor="" size="Small" />
      <div className="flex flex-col gap-[0.125rem]">
        <span className="text-[#333333] font-[600] leading-[150%]">{`${user.firstName} ${user.lastName}`}</span>
        {createdDateString ? (
          <span className="text-[#838383] text-[0.75rem] font-[400] leading-[150%]">{createdDateString}</span>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
