import Input from "../../components/Input";
import PlusIcon from "../../icons/PlusIcon";
import React from "react";
import HeadlessButton from "../../components/HeadlessButton";
import SecondaryButton from "../../components/SecondaryButton";
import DeleteIcon from "../../icons/DeleteIcon";
import { FormattedJoiErrors } from "../../utils/formatJoiErrors";
import RenderErrors from "../../components/RenderErrors";

const ColumnsBuilder = ({
  columns,
  setColumns,
  errors,
}: {
  columns: Array<string>;
  setColumns: React.Dispatch<React.SetStateAction<Array<string>>>;
  errors: FormattedJoiErrors | null;
}) => {
  return (
    <>
      <div className="flex flex-col pb-[1rem]">
        <span className="text-[#171C26] text-[1rem] font-[600] leading-[2rem]">Fields in the table</span>
        <span className="">All columns will have fields associated with them.</span>
      </div>
      <div className="flex flex-col gap-[1rem]">
        {columns.map((column, i) => (
          <div key={i}>
            <div className="flex gap-[0.5rem] items-end w-full">
              <div className="flex flex-col gap-[0.25rem] flex-col w-full">
                <div className="flex gap-[0.25rem]">
                  <span className="text-[0.875rem] leading-[1.25rem]">Column {i + 1}</span>
                  <span className="text-[#B10E1C] text-[0.875rem] leading-[1.25rem]">*</span>
                </div>
                <Input
                  value={column}
                  setValue={(value) => {
                    setColumns((prev) => {
                      const newColumns = JSON.parse(JSON.stringify(prev));
                      newColumns[i] = value;
                      return newColumns;
                    });
                  }}
                  placeholder="Field name"
                  title={`Filed Name ${i}`}
                />
              </div>
              <HeadlessButton
                customClasses="border-none"
                handleClick={async () => {
                  if (columns.length == 1) {
                    return;
                  }
                  setColumns((prev: Array<string>) => {
                    const newColumns: Array<string> = JSON.parse(JSON.stringify(prev));
                    newColumns.splice(i, 1);
                    return newColumns;
                  });
                }}
              >
                <div className="rounded-[0.25rem] border-[1px] border-solid border-[#B3B3B3] w-[2rem] h-[2rem] flex items-center justify-center">
                  <DeleteIcon color="#B10E1C" />
                </div>
              </HeadlessButton>
            </div>
            <RenderErrors errors={errors} fieldName={`tableColumns.${i}.value`} />
          </div>
        ))}
        <SecondaryButton
          label="Add column"
          handleClick={async () => {
            setColumns((prev: Array<string>) => {
              const newColumns = JSON.parse(JSON.stringify(prev));
              newColumns.push("");
              return newColumns;
            });
          }}
          icon={<PlusIcon stroke="#0f6cbd" />}
        />
      </div>
    </>
  );
};

export default ColumnsBuilder;
