import joi from "joi";

export const fieldSchema = joi.object({
  name: joi.string().required(),
  type: joi.string().required(),
  description: joi.string().allow(""),
  defaultValue: joi.any().allow(""),
  dateFormat: joi.string().allow(""),
  options: joi.when("type", {
    is: "dropdown",
    then: joi.array().items(joi.string().required()).min(1),
  }),
});
