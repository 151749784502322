import React from "react";
import "./Avatar.css";

export interface AvatarProps {
  avatarUrl: string;
  size: "Small" | "Medium" | "Large" | "X-Large" | "XX-Large";
  backgroundColor: string;
  username: string;
  onClick?: () => void;
  other?: string;
}
const Avatar = ({ backgroundColor, avatarUrl, username, size, onClick, other }: AvatarProps): JSX.Element => {
  if (avatarUrl !== "") {
    return (
      <img
        data-testid="avatar-test-id"
        onClick={onClick}
        src={avatarUrl}
        alt="text"
        className={
          avatarUrl === "" && username === ""
            ? `loading-shimmer-${size}`
            : `avatar-image-${size} bg-[#E6E6E6] object-fill bg-[${backgroundColor}] ${onClick ? "avatar-hover" : ""}`
        }
      />
    );
  } else {
    return (
      <button
        type="button"
        data-testid="avatar-test-id"
        onClick={onClick}
        className={
          username !== ""
            ? `default-avatar-${size} ${
                other ? "text-[black]" : "text-[white]"
              } justify-center rounded-[100px] bg-[#2e8b57] flex flex-row items-center ${
                other ? "border border-[#E9EDF5]" : ""
              } p-[10px] uppercase ${size === "XX-Large" || !onClick ? "" : "avatar-hover"} `
            : `loading-shimmer-${size} bg-[${backgroundColor}]`
        }
      >
        {other ? other : username ? username[0] : "?"}
      </button>
    );
  }
};
export default Avatar;
