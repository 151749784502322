import React from "react";
import { useContentControls } from "../../hooks/useContentControl";
import RenderCondition from "./RenderCondition";

export default function ShowConditions({
  conditions,
  reloadConditions,
}: {
  conditions: Array<any>;
  reloadConditions: () => void;
}): JSX.Element {
  const { contentControls } = useContentControls({ availableTags: conditions });

  return (
    <div className="rounded-[0.25rem] border-[1px] border-solid border-[#D9DBDD] bg-[#FFF] p-[0.75rem] flex flex-col gap-[0.5rem] flex-grow">
      <span className="text-[#616161] text-[0.75rem] font-[600]">Custom conditions</span>
      {conditions.map((condition) => (
        <RenderCondition
          key={condition._id}
          condition={condition}
          contentControlProp={contentControls.get(condition._id) || []}
          reloadConditions={reloadConditions}
        />
      ))}
    </div>
  );
}
