import React, { useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";

import HeadlessButton from "./HeadlessButton";
import { useNotificationToastDispatch } from "../features/NotificationToast/NotificationContext";

export default function HeadlessDropDown({
  selectElement,
  options,
  customContainerClasses,
  customOptionsContainerClasses,
}: {
  selectElement: JSX.Element;
  options: Array<{ children: JSX.Element | JSX.Element[]; clickHandler: () => Promise<void>; customClasses?: string }>;
  customContainerClasses?: string;
  customOptionsContainerClasses?: string;
}): JSX.Element {
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false);
  const [shouldRemainOpen, setShouldRemainOpen] = useState<boolean>(false);
  const dropdownClickOutsideRef = useDetectClickOutside({
    onTriggered: () => {
      if (!shouldRemainOpen) {
        setIsDropDownOpen(false);
      }
    },
  });
  const notificationDispatch = useNotificationToastDispatch();

  return (
    <div
      className={`relative cursor-pointer ${customContainerClasses ? customContainerClasses : ""}`}
      ref={dropdownClickOutsideRef}
      onClick={() => setIsDropDownOpen((prev) => !prev)}
    >
      {selectElement}
      {isDropDownOpen ? (
        <div className={`absolute z-[1] inline-flex flex-col ${customOptionsContainerClasses}`}>
          {options.map(
            ({ children, clickHandler, customClasses }, i): JSX.Element => (
              <HeadlessButton
                key={i}
                handleClick={async () => {
                  setShouldRemainOpen(true);
                  try {
                    await clickHandler();
                  } catch (err) {
                    console.error(err);
                    if (notificationDispatch && err instanceof Error) {
                      notificationDispatch({ type: "error", message: err.message });
                    }
                  } finally {
                    setShouldRemainOpen(false);
                    setIsDropDownOpen(false);
                  }
                }}
                isDisabled={shouldRemainOpen}
                customClasses={customClasses}
              >
                {children}
              </HeadlessButton>
            )
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
