import React, { SVGProps } from "react";

const DateTagTypeIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" {...props}>
    <path
      fill={props.fill}
      d="M11.857 2c1.136 0 2.066.884 2.139 2.002l.004.141v7.715a2.143 2.143 0 0 1-2.002 2.138l-.14.005H4.142a2.143 2.143 0 0 1-2.139-2.002L2 11.858V4.143c0-1.136.884-2.066 2.002-2.138L4.142 2h7.715Zm1.286 3.429H2.857v6.429c0 .668.51 1.217 1.162 1.28l.124.006h7.714c.669 0 1.218-.51 1.28-1.162l.006-.124v-6.43ZM9.3 6.487c.457 0 .82.122 1.088.365.268.243.402.572.402.988 0 .239-.062.451-.184.638-.123.186-.29.333-.503.44.255.118.45.28.588.485.137.206.206.438.206.697 0 .428-.145.768-.435 1.02-.29.25-.677.376-1.159.376-.484 0-.872-.126-1.163-.378-.292-.252-.437-.592-.437-1.018 0-.261.069-.496.207-.703.139-.208.333-.368.583-.48a1.27 1.27 0 0 1-.497-.44 1.14 1.14 0 0 1-.183-.637c0-.416.134-.745.402-.988s.63-.365 1.085-.365Zm-2.59.05v4.892H5.9V7.515l-1.195.408v-.686l1.902-.7h.104Zm2.586 2.716c-.236 0-.425.073-.567.22-.142.148-.213.343-.213.587 0 .239.07.43.21.572.139.143.332.215.577.215.246 0 .437-.07.574-.208.138-.138.206-.332.206-.58 0-.24-.072-.435-.216-.584a.76.76 0 0 0-.57-.222ZM9.3 7.14a.637.637 0 0 0-.492.196c-.121.13-.181.308-.181.534 0 .223.06.4.182.532a.64.64 0 0 0 .494.198.64.64 0 0 0 .494-.198c.122-.131.182-.309.182-.532a.757.757 0 0 0-.184-.524.632.632 0 0 0-.495-.206Zm2.557-4.283H4.143c-.669 0-1.218.51-1.28 1.162l-.006.124v.429h10.286v-.429c0-.668-.51-1.218-1.162-1.28l-.124-.006Z"
    />
  </svg>
);

export default DateTagTypeIcon;
