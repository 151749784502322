import React from "react";

import HeadlessButton, { ButtonType } from "./HeadlessButton";

export type ButtonVariant = "primary" | "secondary";

type ButtonColors = {
  buttonContainerColors: string;
  textColors: string;
};

const getColorsForVariant = (variant: ButtonVariant): ButtonColors => {
  switch (variant) {
    case "primary":
      return {
        buttonContainerColors: "bg-[#0F6CBD] hover:bg-[#115EA3] active:bg-[#0C3B5E] disabled:bg-[#F0F0F0]",
        textColors: "text-[#FFFFFF] disabled:text-[#BDBDBD]",
      };
    case "secondary":
      return {
        buttonContainerColors:
          "bg-[#FFFFFF] hover:bg-[#F5F5F5] active:bg-[#E0E0E0] disabled:bg-[#F0F0F0] border-[1px] border-solid border-[#D1D1D1] hover:border-[#C7C7C7] active:border-[#B3B3B3] disabled:border-[#E0E0E0]",
        textColors: "text-[#242424] disabled:text-[#BDBDBD]",
      };
  }
};

function Button({
  variant,
  label,
  handleClick,
  isDisabled,
  customClasses,
  leftIcon,
  rightIcon,
  buttonType,
}: {
  variant: ButtonVariant;
  label: string;
  // eslint-disable-next-line no-unused-vars
  handleClick: (e: React.MouseEvent<HTMLElement>) => Promise<void>;
  isDisabled?: boolean;
  customClasses?: string;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  buttonType?: ButtonType;
}): JSX.Element {
  const { buttonContainerColors, textColors } = getColorsForVariant(variant);

  return (
    <HeadlessButton
      type={buttonType ? buttonType : "button"}
      // text colors applied on button itself so that disabled pseudo class is automatically applied
      customClasses={`flex items-center justify-center gap-[0.25rem] p-[0.3125rem_0.75rem] rounded-[0.25rem] ${buttonContainerColors} ${textColors} ${
        customClasses ? customClasses : ""
      }`}
      handleClick={handleClick}
      isDisabled={isDisabled}
    >
      {leftIcon ? leftIcon : <></>}
      {label ? (
        <div className="flex items-start pb-[0.125rem]">
          <span className={`leading-[1.25rem] font-[0.875rem]`}>{label}</span>
        </div>
      ) : (
        <></>
      )}
      {rightIcon ? rightIcon : <></>}
    </HeadlessButton>
  );
}

export default Button;
