import { ValidationErrorItem } from "joi";

export interface FormattedJoiErrors {
  [key: string]: string[];
}

function formatJoiErrors(errorDetails: ValidationErrorItem[]): FormattedJoiErrors {
  return errorDetails.reduce<FormattedJoiErrors>((acc, err) => {
    const fieldName = err.path.join(".");

    const cleanedMessage = err.message.replace(/["]+/g, "");

    if (!acc[fieldName]) {
      acc[fieldName] = [];
    }

    acc[fieldName].push(cleanedMessage);

    return acc;
  }, {});
}

export default formatJoiErrors;
