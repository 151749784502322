import React, { useState } from "react";

import Author from "./Author";
import { User } from "./interface";
import CloseActionIcon from "../../icons/CloseActionIcon";
import CommentInput from "./CommentInput";
import Button from "../../components/Button";
import PostIcon from "../../icons/PostIcon";
import convertMentionText from "../../utils/convertMentionText";
import useCurrentUser from "../../hooks/useCurrentUser";

export default function NewCommentBox({
  users,
  setCommentingDone,
}: {
  users: Array<User>;
  setCommentingDone: () => Promise<void>;
}): JSX.Element {
  const [commentText, setCommentText] = useState<string>("");
  const handleCreateComment = async (e: React.FormEvent) => {
    e.preventDefault();
    await Word.run(async (context) => {
      const doc = context.document;
      const body = context.document.body;
      body.load(["text"]);
      await context.sync();
      const originalRange = doc.getSelection();
      const transformedCommentText = convertMentionText(users, commentText);
      const comment = originalRange.insertComment(transformedCommentText);
      comment.load();
      await context.sync();
    });
    setCommentText("");
    await setCommentingDone();
  };

  const user = useCurrentUser({ users });

  return (
    <form className="bg-[#FFF] shadow-md rounded-[0.25rem] p-[0.75rem] flex flex-col items-end gap-[0.5rem]">
      <div className="flex flex-col gap-[0.75rem] self-stretch">
        <div className="flex justify-between self-stretch">
          <Author user={user} />
          <Button
            variant="secondary"
            label=""
            leftIcon={<CloseActionIcon width={"1.25rem"} height={"1.25rem"} fill="#616161" />}
            handleClick={setCommentingDone}
            customClasses="border-none"
          />
        </div>
        <CommentInput
          isNewComment={true}
          key="new comment"
          users={users}
          commentText={commentText}
          setCommentText={setCommentText}
          placeholder="use @ to mention someone"
        />
      </div>
      <div className="flex gap-[0.5rem]">
        <Button
          variant="secondary"
          label=""
          leftIcon={<CloseActionIcon width="1.25rem" height="1.25rem" fill="#616161" />}
          // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
          handleClick={async (_e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            setCommentText("");
          }}
          isDisabled={commentText === ""}
        />
        <Button
          buttonType="submit"
          variant="primary"
          label=""
          leftIcon={<PostIcon width="1.25rem" height="1.25rem" fill="none" />}
          handleClick={handleCreateComment}
          isDisabled={commentText === ""}
        />
      </div>
    </form>
  );
}
