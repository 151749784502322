import React, { SVGProps } from "react";

const CloseActionIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" fill="none" {...props}>
    <path
      fill={props.fill}
      d="M10 11.167 5.917 15.25a.79.79 0 0 1-.583.23.79.79 0 0 1-.584-.23.79.79 0 0 1-.229-.583.79.79 0 0 1 .23-.583L8.833 10 4.75 5.917a.79.79 0 0 1-.229-.583.79.79 0 0 1 .23-.584.79.79 0 0 1 .582-.229.79.79 0 0 1 .584.23L10 8.833l4.084-4.084a.79.79 0 0 1 .583-.229.79.79 0 0 1 .583.23.79.79 0 0 1 .23.582.79.79 0 0 1-.23.584L11.167 10l4.083 4.084a.79.79 0 0 1 .23.583.79.79 0 0 1-.23.583.79.79 0 0 1-.583.23.79.79 0 0 1-.583-.23L10 11.167Z"
    />
  </svg>
);

export default CloseActionIcon;
