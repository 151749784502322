import React, { useState } from "react";

import ChevronDownIcon from "../../icons/ChevronDownIcon";
import ChevronUpIcon from "../../icons/ChevronUpIcon";
import Tag from "./Tag";

export default function TagViewBox({
  title,
  tags,
  contentControls,
  render,
  templateId,
  reloadTags,
  allTags,
}: {
  title: string;
  tags: Array<any>;
  contentControls: Map<string, Array<any>>;
  render: string;
  templateId: string;
  reloadTags: () => void;
  allTags: Array<any>;
}): JSX.Element {
  const [isTagsVisible, setIsTagsVisible] = useState<boolean>(true);
  if (tags.length === 0) {
    return <></>;
  }
  return (
    <div className="p-[0.75rem] rounded-[0.25rem] border-[1px] border-solid border-[#D9DBDD] bg-[#FFF] flex flex-col gap-[0.5rem]">
      <div
        className="flex flex-row justify-between cursor-pointer hover:bg-[#FAFAFA] active:bg-[#E0E0E0]"
        onClick={(e) => {
          e.stopPropagation();
          setIsTagsVisible((prev) => !prev);
        }}
      >
        <span className="text-[#616161] text-[0.75rem] font-[600]">{title}</span>
        {isTagsVisible ? (
          <ChevronUpIcon width={20} height={20} fill="#616161" />
        ) : (
          <ChevronDownIcon width={20} height={20} fill="#616161" />
        )}
      </div>
      {isTagsVisible ? (
        <div className="flex flex-col gap-[0.25rem]">
          {tags.map((tag) => {
            if (render === "tables" && tag.type === "table") {
              return (
                <Tag
                  templateId={templateId}
                  tag={tag}
                  key={tag._id}
                  contentControlProp={contentControls.get(tag._id) || []}
                  type="table"
                  reloadTags={reloadTags}
                  allTags={allTags}
                />
              );
            } else if (render === "fields" && tag.type !== "table") {
              return (
                <Tag
                  templateId={templateId}
                  tag={tag}
                  key={tag._id}
                  contentControlProp={contentControls.get(tag._id) || []}
                  type="field"
                  reloadTags={reloadTags}
                  allTags={allTags}
                />
              );
            }
            return <></>;
          })}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
