import React, { useContext, useState } from "react";
import TagIcon from "./TagIcon";
import TableIcon from "../../icons/TableIcon";
import KebabMenuIcon from "../../icons/KebabMenuIcon";
import HeadlessDropDown from "../../components/HeadlessDropDown";
import EditIcon from "../../icons/Editcon";
import DeleteIcon from "../../icons/DeleteIcon";
import { FetchContext } from "../../api/FetchContext";
import { BACKEND_URL } from "../../constants";
import { useNotificationToastDispatch } from "../../features/NotificationToast/NotificationContext";
import { useNavigationDispatch } from "../../features/Navigation/NavigationContext";

export default function Tag({
  templateId,
  tag,
  contentControlProp,
  type,
  reloadTags,
  allTags,
}: {
  templateId: string;
  tag: any;
  contentControlProp: Array<any>;
  type: string;
  reloadTags: () => void;
  allTags: Array<any>;
}): JSX.Element {
  const [indexToScrollTo, setIndexToScrollTo] = useState<number>(0);
  const fetcher = useContext(FetchContext);
  const notificationDispatch = useNotificationToastDispatch();
  const setSelectedTab = useNavigationDispatch();
  const handleDeleteTag = async () => {
    if (fetcher && notificationDispatch) {
      const filteredTags = allTags.filter((t) => t._id !== tag._id);
      const res = await fetcher(`${BACKEND_URL}/api/v1/template/updateTags`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          templateId,
          tags: filteredTags,
        }),
      }).catch((e) => {
        if (e instanceof Error) {
          notificationDispatch({ type: "error", message: e.message });
        }
        return e;
      });

      if (!res) {
        reloadTags();
      }
    }
    if (setSelectedTab) {
      setSelectedTab("Fields");
    }
  };

  return (
    <div
      className={`py-[0.5rem] px-[0.25rem] flex hover:bg-[#FAFAFA] justify-between items-center ${
        contentControlProp.length > 0 ? "cursor-pointer active:bg-[#E0E0E0]" : "cursor-not-allowed"
      }`}
      onClick={async () => {
        if (contentControlProp.length > 0) {
          await Word.run(async (context) => {
            const contentControlId = contentControlProp[indexToScrollTo].id;
            const contentControl = context.document.contentControls.getById(contentControlId);
            await contentControl.context.sync();
            contentControl.getRange().select();
            setIndexToScrollTo((prev) => (prev + 1) % contentControlProp.length);
          });
        }
      }}
    >
      <div className="flex flex-row gap-[0.5rem]">
        <div className="flex self-stretch items-center">
          {type === "table" ? <TableIcon fill="#616161" /> : <TagIcon type={tag.type} color="#616161" />}
        </div>
        <span className="text-[#333333] text-[0.875rem] leading-[1.25rem]">{tag.name}</span>
      </div>
      {tag._id !== "645a4eba62c1395744b25bf1" && (
        <HeadlessDropDown
          selectElement={
            <div className="w-[2rem] h-[2rem] flex items-center justify-center">
              <KebabMenuIcon />
            </div>
          }
          customContainerClasses="active:bg-[#E0E0E0] focus:bg-[#E0E0E0] focus-within:bg-[#E0E0E0]"
          customOptionsContainerClasses="rounded-[0.25rem] shadow-md border-[1px] border-solid border-[#D9DBDD] bg-[#FFF] right-[calc(100%-32px)] w-[10rem]"
          options={[
            {
              children: (
                <div className="flex flex-row gap-[0.5rem] items-center py-[0.5rem] px-[0.75rem] hover:bg-[#FAFAFA]">
                  <EditIcon color="#212121" />
                  <span className="text-[#333333] text-[0.875rem] leading-[1.25rem] whitespace-nowrap">
                    Edit {type === "table" ? "Table" : "Field"}
                  </span>
                </div>
              ),
              clickHandler: async () => {
                if (setSelectedTab) {
                  if (type === "table") {
                    setSelectedTab(`EditTable?tagId=${tag._id}`);
                  } else {
                    setSelectedTab(`EditField?tagId=${tag._id}`);
                  }
                }
              },
            },
            {
              children: (
                <div className="flex flex-row gap-[0.5rem] items-center py-[0.5rem] px-[0.75rem] hover:bg-[#FAFAFA]">
                  <DeleteIcon color="#212121" />
                  <span className="text-[#333333] text-[0.875rem] leading-[1.25rem] whitespace-nowrap">
                    Delete {type === "table" ? "Table" : "Field"}
                  </span>
                </div>
              ),
              clickHandler: handleDeleteTag,
            },
          ]}
        />
      )}
    </div>
  );
}
