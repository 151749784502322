import * as React from "react";

export interface Annotation {
  label: string;
  value: string;
  commentId: string;
}

export default function Table({ annotations }: { annotations: Annotation[] }): JSX.Element {
  const focusComment = async (commentId: string) => {
    await Word.run(async (context) => {
      const body = context.document.body;
      body.load("comment");
      await body.context.sync();
      const comments = body.getComments();
      comments.load("items");
      await comments.context.sync();
      for (const comment of comments.items) {
        comment.load(["id", "content"]);
        await comment.context.sync();
        if (comment.id === commentId) {
          comment.getRange().select();
          comment.getRange().insertBookmark(comment.content);
        }
      }
    });
  };

  if (annotations.length === 0) {
    return <></>;
  }

  return (
    <table className="w-full text-center border-[1px] border-[#0505521A]">
      <tbody>
        {annotations.map(({ label, value, commentId }) => {
          return (
            <tr key={commentId} className="border-[1px] border-[#0505521A]">
              <td className="border-[1px] px-[16px] text-left border-[#0505521A] py-[8px] w-[50%]">
                <div
                  onClick={() => {
                    focusComment(commentId);
                  }}
                  className="text-[10px] text-[#050552] cursor-pointer"
                >
                  {label}
                </div>
              </td>
              <td className="border-[1px] text-left px-[16px] py-[8px] border-[#0505521A] text-[10px] text-[#05055299]">
                {value}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
