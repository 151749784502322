import * as React from "react";
import { SVGProps } from "react";
const PlaybookEmptyState = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" {...props}>
    <g clipPath="url(#a)">
      <rect width={184} height={140} x={0.5} fill="#F6F6F6" rx={8} />
      <g filter="url(#b)">
        <rect width={234.934} height={388.672} x={98.752} y={20.084} fill="#fff" rx={1.65} />
      </g>
      <rect width={202.392} height={6.383} x={120.857} y={76.819} fill="#EAEFFF" rx={1.03} />
      <rect width={84.485} height={6.383} x={120.857} y={112.272} fill="#EAEFFF" rx={1.03} />
      <rect width={202.392} height={6.383} x={120.857} y={66.18} fill="#EAEFFF" rx={1.03} />
      <rect width={202.392} height={6.383} x={120.857} y={101.634} fill="#EAEFFF" rx={1.03} />
      <rect width={202.392} height={6.383} x={120.857} y={41.366} fill="#EAEFFF" rx={1.03} />
      <mask id="c" fill="#fff">
        <path
          fillRule="evenodd"
          d="M25.5 31.722a4 4 0 0 0-4 4v18.111a4 4 0 0 0 4 4h87.942a4 4 0 0 0 4-4V50.2c.061-.023.12-.05.179-.085l7.445-4.298a1.2 1.2 0 0 0 0-2.079l-7.445-4.298a1.225 1.225 0 0 0-.179-.085v-3.633a4 4 0 0 0-4-4H25.5Z"
          clipRule="evenodd"
        />
      </mask>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M25.5 31.722a4 4 0 0 0-4 4v18.111a4 4 0 0 0 4 4h87.942a4 4 0 0 0 4-4V50.2c.061-.023.12-.05.179-.085l7.445-4.298a1.2 1.2 0 0 0 0-2.079l-7.445-4.298a1.225 1.225 0 0 0-.179-.085v-3.633a4 4 0 0 0-4-4H25.5Z"
        clipRule="evenodd"
      />
      <path
        fill="#E6E6E6"
        d="m117.442 50.2-.355-.934-.645.245v.69h1Zm.179-.085-.5-.866.5.866Zm7.445-4.298-.5-.866.5.866Zm0-2.079.5-.866-.5.866Zm-7.445-4.298-.5.866.5-.866Zm-.179-.085h-1v.689l.645.245.355-.934ZM22.5 35.722a3 3 0 0 1 3-3v-2a5 5 0 0 0-5 5h2Zm0 18.111v-18.11h-2v18.11h2Zm3 3a3 3 0 0 1-3-3h-2a5 5 0 0 0 5 5v-2Zm87.942 0H25.5v2h87.942v-2Zm3-3a3 3 0 0 1-3 3v2a5 5 0 0 0 5-5h-2Zm0-3.633v3.633h2V50.2h-2Zm.679-.95a.27.27 0 0 1-.034.016l.711 1.869c.111-.042.218-.093.323-.154l-1-1.732Zm7.445-4.3-7.445 4.3 1 1.731 7.445-4.298-1-1.732Zm0-.346a.2.2 0 0 1 0 .347l1 1.732c1.467-.847 1.467-2.964 0-3.81l-1 1.731Zm-7.445-4.298 7.445 4.298 1-1.732-7.445-4.298-1 1.732Zm-.034-.017a.27.27 0 0 1 .034.017l1-1.732a2.236 2.236 0 0 0-.323-.154l-.711 1.87Zm-.645-4.567v3.633h2v-3.633h-2Zm-3-3a3 3 0 0 1 3 3h2a5 5 0 0 0-5-5v2Zm-87.942 0h87.942v-2H25.5v2Z"
        mask="url(#c)"
      />
      <circle cx={36.567} cy={44.501} r={8.245} fill="#14804A" />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.237}
        d="m33.647 45.017 1.545 1.717 4.295-4.466"
      />
      <rect width={57.926} height={5.793} x={50.164} y={41.605} fill="#0F6CBD" fillOpacity={0.12} rx={1.448} />
      <mask id="d" fill="#fff">
        <path
          fillRule="evenodd"
          d="M25.5 91.833a4 4 0 0 0-4 4v18.111a4 4 0 0 0 4 4h87.942a4 4 0 0 0 4-4v-3.633c.061-.023.12-.051.179-.085l7.445-4.298a1.2 1.2 0 0 0 0-2.079l-7.445-4.298a1.225 1.225 0 0 0-.179-.085v-3.633a4 4 0 0 0-4-4H25.5Z"
          clipRule="evenodd"
        />
      </mask>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M25.5 91.833a4 4 0 0 0-4 4v18.111a4 4 0 0 0 4 4h87.942a4 4 0 0 0 4-4v-3.633c.061-.023.12-.051.179-.085l7.445-4.298a1.2 1.2 0 0 0 0-2.079l-7.445-4.298a1.225 1.225 0 0 0-.179-.085v-3.633a4 4 0 0 0-4-4H25.5Z"
        clipRule="evenodd"
      />
      <path
        fill="#E6E6E6"
        d="m117.442 110.311-.355-.934-.645.245v.689h1Zm.179-.085-.5-.866.5.866Zm7.445-4.298-.5-.866.5.866Zm0-2.079.5-.866-.5.866Zm-7.445-4.298-.5.866.5-.866Zm-.179-.085h-1v.689l.645.245.355-.934ZM22.5 95.833a3 3 0 0 1 3-3v-2a5 5 0 0 0-5 5h2Zm0 18.111V95.833h-2v18.111h2Zm3 3a3 3 0 0 1-3-3h-2a5 5 0 0 0 5 5v-2Zm87.942 0H25.5v2h87.942v-2Zm3-3a3 3 0 0 1-3 3v2a5 5 0 0 0 5-5h-2Zm0-3.633v3.633h2v-3.633h-2Zm.679-.951-.034.017.711 1.869c.111-.042.218-.093.323-.154l-1-1.732Zm7.445-4.298-7.445 4.298 1 1.732 7.445-4.298-1-1.732Zm0-.347a.2.2 0 0 1 0 .347l1 1.732c1.467-.847 1.467-2.964 0-3.811l-1 1.732Zm-7.445-4.298 7.445 4.298 1-1.732-7.445-4.298-1 1.732Zm-.034-.017c.01.004.021.009.034.017l1-1.732a2.235 2.235 0 0 0-.323-.154l-.711 1.869Zm-.645-4.567v3.633h2v-3.633h-2Zm-3-3a3 3 0 0 1 3 3h2a5 5 0 0 0-5-5v2Zm-87.942 0h87.942v-2H25.5v2Z"
        mask="url(#d)"
      />
      <path
        fill="#C83532"
        d="M36.112 96.943a7.77 7.77 0 1 1 0 15.54 7.77 7.77 0 0 1 0-15.54Zm-2.128 4.966a.485.485 0 0 0-.62.057l-.055.067a.485.485 0 0 0 .056.619l2.06 2.061-2.06 2.06-.056.068a.485.485 0 0 0 .056.619l.067.056c.19.131.451.113.62-.056l2.06-2.06 2.06 2.06.068.056c.189.131.45.113.62-.056l.056-.067a.486.486 0 0 0-.057-.62l-2.06-2.06 2.06-2.061.056-.067a.485.485 0 0 0-.056-.619l-.067-.057a.485.485 0 0 0-.62.057l-2.06 2.06-2.06-2.06-.068-.057Z"
      />
      <rect width={57.926} height={5.793} x={50.164} y={101.716} fill="#0F6CBD" fillOpacity={0.12} rx={1.448} />
    </g>
    <rect width={183} height={139} x={1} y={0.5} stroke="#E6E6E6" rx={7.5} />
    <defs>
      <clipPath id="a">
        <rect width={184} height={140} x={0.5} fill="#fff" rx={8} />
      </clipPath>
      <filter
        id="b"
        width={248.137}
        height={401.875}
        x={92.15}
        y={14.308}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={0.825} />
        <feGaussianBlur stdDeviation={3.301} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1023_4709" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_1023_4709" result="shape" />
      </filter>
    </defs>
  </svg>
);
export default PlaybookEmptyState;
