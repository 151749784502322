import React, { useContext, useEffect, useState } from "react";
import Input from "../../components/Input";
import { FetchContext } from "../../api/FetchContext";
import { BACKEND_URL } from "../../constants";
import { useNotificationToastDispatch } from "../../features/NotificationToast/NotificationContext";
import Button from "../../components/Button";
import { useNavigationDispatch } from "../../features/Navigation/NavigationContext";
import Dropdown from "../../components/Dropdown";
import TextArea from "../../components/TextArea";
import formatJoiErrors, { FormattedJoiErrors } from "../../utils/formatJoiErrors";
import { fieldSchema } from "./fieldSchema";
import RenderErrors from "../../components/RenderErrors";
import FieldTypes from "./FieldTypes";
import { DateFormatOption, dateFormatOptions, fieldTypeOptions, getFieldTypeOptionLabel } from "./index";
import moment from "moment";
import OptionsBuilder from "./OptionsBuilder";

export default function EditField({
  tags,
  templateId,
  reloadTags,
  url,
}: {
  tags: Array<any>;
  templateId: string;
  reloadTags: () => void;
  url: URL;
}): JSX.Element {
  const [fieldName, setFieldName] = useState<string>("");
  const [fieldType, setFieldType] = useState<{
    label: string;
    value: string;
  }>({
    label: "",
    value: "",
  });

  // moment.js is in maintenance mode, but porting to date-fns has this constraint
  // date-fns doesn't support all the formats below, which which being used in both frontend and backend
  const [dateFormat, setDateFormat] = useState<DateFormatOption>({
    label: (
      <div className="flex gap-1 justify-between px-1 flex-wrap">
        <p>DD/MM/YYYY</p>
        <p>{moment().format("DD/MM/YYYY")}</p>
      </div>
    ),
    value: "DD/MM/YYYY",
  });
  const [description, setDescription] = useState<string>("");
  const [defaultValue, setDefaultValue] = useState<string>("");
  const [options, setOptions] = useState<Array<string>>([""]);
  const [errors, setErrors] = useState<FormattedJoiErrors | null>(null);
  const tagId = url.searchParams.get("tagId");

  const fetcher = useContext(FetchContext);
  const notificationDispatch = useNotificationToastDispatch();
  const setSelectedTab = useNavigationDispatch();

  const handleEditTag = async () => {
    const data: any = {
      name: fieldName,
      type: fieldType.value,
      description,
      defaultValue,
    };
    if (fieldType.value === "dropdown") {
      data.options = options;
    }
    if (fieldType.value === "date") {
      const defaultDate = moment(defaultValue, dateFormat.value).toISOString();
      data.defaultValue = defaultDate;
    }
    const { error } = fieldSchema.validate(data, {
      abortEarly: false,
    });
    if (error) {
      const errors = formatJoiErrors(error.details);
      setErrors(errors);
      return;
    }

    const newTags = tags.map((tag) => {
      if (tag._id === tagId) {
        return {
          ...tag,
          name: fieldName,
          type: fieldType.value,
          description: description,
          formType: "question",
          dateFormat: dateFormat.value,
          defaultValue,
          options,
          //@vv possible bug
        };
      }
      return tag;
    });
    if (fetcher && notificationDispatch) {
      const res = await fetcher(`${BACKEND_URL}/api/v1/template/updateTags`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          templateId,
          tags: newTags,
        }),
      }).catch((e) => {
        if (e instanceof Error) {
          notificationDispatch({ type: "error", message: e.message });
        }
        return e;
      });

      if (!res) {
        reloadTags();
      }
    }
    if (setSelectedTab) {
      setSelectedTab("Fields");
    }
  };

  const getDateFormatOption = (value: string) => {
    const dateOptions = dateFormatOptions();
    if (!value) {
      return dateOptions[0];
    }
    const dateOption = dateOptions.find((option) => option.value === value);
    if (!dateOption) {
      return dateOptions[0];
    }
    return dateOption;
  };

  useEffect(() => {
    if (tagId) {
      const tag = tags.find((tag) => tag._id === tagId);
      const fieldType = fieldTypeOptions.find((type) => type.value === tag?.type);
      const dateFormatOption = getDateFormatOption(tag?.dateFormat);
      if (tag && fieldType) {
        setFieldName(tag.name);
        setFieldType(fieldType);
        setDescription(tag.description);
        setDefaultValue(tag.defaultValue);
        setDateFormat(dateFormatOption);
        setOptions(tag.options);
      }
    }
  }, []);

  return (
    <div className="flex-grow flex flex-col justify-between bg-[#FAFAFA]">
      <div className="bg-[#FDFDFD] py-4 flex flex-col flex-grow">
        <div className="py-[0.625rem] px-[1rem]">
          <span className="text-[#171C26] text-[1.25rem] font-[600] leading-[2rem]">Edit field</span>
        </div>
        <div className="px-[1rem] py-[1rem] flex flex-col gap-[1.5rem]">
          <div className="flex flex-col gap-[1rem]">
            <div className="flex flex-col gap-[0.5rem]">
              <div className="flex gap-[0.25rem]">
                <span className="text-[0.875rem] leading-[1.25rem]">Field name</span>
                <span className="text-[#B10E1C] text-[0.875rem] leading-[1.25rem]">*</span>
              </div>
              <Input value={fieldName} setValue={setFieldName} placeholder="" title="Enter Field Name" />
              <RenderErrors errors={errors} fieldName="name" />
            </div>
            <div className="flex flex-col gap-[0.5rem]">
              <div className="flex gap-[0.25rem]">
                <span className="text-[0.875rem] leading-[1.25rem]">Type</span>
                <span className="text-[#B10E1C] text-[0.875rem] leading-[1.25rem]">*</span>
              </div>
              <Dropdown
                width="w-full"
                option={getFieldTypeOptionLabel(fieldType)}
                setOption={(option) => {
                  setFieldType(option);
                  setDefaultValue("");
                }}
                options={fieldTypeOptions}
              />
              <RenderErrors errors={errors} fieldName="type" />
            </div>
          </div>
          {fieldType.value == "date" ? (
            <div className="flex flex-col gap-[0.5rem]">
              <div className="flex gap-[0.25rem]">
                <span className="text-[0.875rem] leading-[1.25rem]">Date format</span>
                <span className="text-[#B10E1C] text-[0.875rem] leading-[1.25rem]">*</span>
              </div>
              <Dropdown
                width="w-full"
                option={dateFormat.label}
                setOption={(option) => {
                  setDateFormat(option);
                }}
                options={dateFormatOptions()}
              />
              <RenderErrors errors={errors} fieldName="dateFormat" />
            </div>
          ) : (
            <></>
          )}
          {fieldType.value == "dropdown" ? (
            <OptionsBuilder options={options} setOptions={setOptions} errors={errors} />
          ) : (
            <></>
          )}
          <TextArea
            errors={errors}
            fieldName="description"
            label="Field description"
            fieldDescription="Descriptions help your team members input the right values in fields while filling intake forms"
            description={description}
            setDescription={setDescription}
          />
          <FieldTypes
            defaultValue={defaultValue}
            setDefaultValue={setDefaultValue}
            inputType={fieldType.value}
            options={options}
          />
          <RenderErrors errors={errors} fieldName="defaultValue" />
        </div>
      </div>
      <div className="p-[1rem] flex gap-[0.5rem] border-t border-t-solid border-t-[#E0E0E0] bg-[#FAFAFA]">
        <Button variant="primary" label="Edit" handleClick={handleEditTag} />
        <Button
          variant="secondary"
          label="Cancel"
          handleClick={async () => {
            if (setSelectedTab) {
              setSelectedTab("Fields");
            }
          }}
        />
      </div>
    </div>
  );
}
