import React, { Dispatch, SetStateAction } from "react";
import { MentionsInput, Mention, SuggestionDataItem } from "react-mentions";

import { User } from "./interface";
import Avatar from "../../storybook/Avatar";

import "./ReactMentions.css";

export default function CommentInput({
  users,
  commentText,
  setCommentText,
  placeholder,
  isNewComment,
}: {
  users: Array<User>;
  commentText: string;
  setCommentText: Dispatch<SetStateAction<string>>;
  placeholder: string;
  isNewComment: boolean;
}): JSX.Element {
  return (
    <MentionsInput
      autoFocus={isNewComment}
      spellCheck={true}
      allowSpaceInQuery={true}
      className="input-box-mentions-input"
      name="commentBox"
      title="comment box"
      value={commentText}
      placeholder={placeholder}
      onChange={(e) => setCommentText(e.target.value)}
    >
      <Mention
        displayTransform={(id: string) => {
          return `@${id}`;
        }}
        // below markup is word's default comment mention, js api does not have support for this yet.
        // markup='HYPERLINK "mailto:__id__"@__display__'
        // Note:- messing with markup messes the display css, better to parse before sending to backend/word. time spent debugging this: 3 hours.
        markup="@(__id__)"
        className="mentions__mention"
        appendSpaceOnAdd={true}
        trigger={"@"}
        data={(search, callback) => {
          const newUsersList = users
            .filter((user: User) => {
              return (
                user.firstName.toLowerCase().includes(search.toLowerCase()) ||
                user.lastName.toLowerCase().includes(search.toLowerCase()) ||
                user.email.toLowerCase().includes(search.toLowerCase())
              );
            })
            .map((user: User) => ({ id: user.email, display: `${user.firstName} ${user.lastName}` }));
          callback(newUsersList);
        }}
        renderSuggestion={(a: SuggestionDataItem) => {
          const user = users.find((user) => user.email === a.id);
          return (
            <div className="flex overflow-x-auto max-w-[200px]">
              <div className="mt-[5px] mr-[5px]">
                <Avatar
                  avatarUrl={user?.imageUrl || ""}
                  username={a.display ? a.display : "H"}
                  backgroundColor=""
                  size="Small"
                />
              </div>{" "}
              <p className="overflow-x-auto ml-[5px] text-[#333333] text-[12px] font-medium">
                {a.display ? a.display : <></>}
                <span className="text-[#616161] text-[10px] font-normal">
                  <br></br>
                  {a.id}
                </span>
              </p>
            </div>
          );
        }}
      />
    </MentionsInput>
  );
}
