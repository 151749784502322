import React, { useContext, useState } from "react";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { useNavigationDispatch } from "../../features/Navigation/NavigationContext";
import PlusIcon from "../../icons/PlusIcon";
import SecondaryButton from "../../components/SecondaryButton";
import Dropdown from "../../components/Dropdown";
import { BACKEND_URL } from "../../constants";
import ConditionBuilder from "./CondtionsBuilder";
import { FetchContext } from "../../api/FetchContext";
import { useNotificationToastDispatch } from "../../features/NotificationToast/NotificationContext";
import { conditionSchema } from "./conditionSchema";
import formatJoiErrors, { FormattedJoiErrors } from "../../utils/formatJoiErrors";
import RenderErrors from "../../components/RenderErrors";
import { IConditionOption, IConditionType, IConditionTypeOption } from "./interface";

const CreateCondition = ({
  tags,
  templateId,
  reloadConditions,
}: {
  tags: Array<any>;
  templateId: string;
  reloadConditions: () => void;
}) => {
  const [conditionType, setConditionType] = useState<IConditionTypeOption>({ label: "AND", value: "all" });
  const [conditionName, setConditionName] = useState("");
  const [errors, setErrors] = useState<FormattedJoiErrors | null>(null);
  const [conditions, setConditions] = useState<IConditionOption[]>([
    {
      field: {
        label: "",
        value: "",
      },
      relationship: { label: "Is Equal To", value: "equal" },
      value: "",
    },
  ]);

  const fetcher = useContext(FetchContext);
  const notificationDispatch = useNotificationToastDispatch();
  const setSelectedTab = useNavigationDispatch();

  const conditionTypeOptions = [
    { label: "AND", value: "all" },
    { label: "OR", value: "any" },
  ];

  const handleCreateCondition = async () => {
    const newConditions = conditions.map((condition) => {
      return {
        fact: condition.field.value,
        operator: condition.relationship.value,
        value: condition.value,
      };
    });
    const { error } = conditionSchema.validate(
      {
        name: conditionName,
        conditions: newConditions,
      },
      {
        abortEarly: false,
      }
    );
    if (error) {
      const formattedErrors = formatJoiErrors(error.details);
      setErrors(formattedErrors);
      return;
    }

    if (fetcher && notificationDispatch) {
      await fetcher(`${BACKEND_URL}/api/v1/condition/createCondition`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          templateId,
          name: conditionName,
          conditions: newConditions,
          aggregator: conditionType.value,
        }),
      }).catch((e) => {
        if (e instanceof Error) {
          notificationDispatch({ type: "error", message: e.message });
        }
        return e;
      });
    }
    if (reloadConditions && setSelectedTab) {
      reloadConditions();
      setSelectedTab("Conditions");
    }
  };

  const addCondition = async () => {
    setConditions([
      ...conditions,
      {
        field: {
          label: "",
          value: "",
        },
        relationship: { label: "Is Equal To", value: "equal" },
        value: "",
      },
    ]);
  };

  return (
    <div className="overflow-y-auto flex-grow flex flex-col justify-between bg-[#FAFAFA]">
      <div className="bg-[#FDFDFD] py-4 flex flex-col flex-grow">
        <div className="py-[0.5rem] px-[1rem]">
          <span className="text-[#171C26] text-[1.25rem] font-[600] leading-[2rem]">Create new condition</span>
        </div>
        <div className="px-[1rem] py-[1rem] overflow-y-auto flex flex-col border-b border-b-solid border-b-[#E0E0E0]">
          <div className="flex flex-col gap-[1rem]">
            <div className="flex flex-col gap-[0.5rem]">
              <div className="flex gap-[0.25rem]">
                <span className="text-[0.875rem] leading-[1.25rem]">Condition name</span>
                <span className="text-[#B10E1C] text-[0.875rem] leading-[1.25rem]">*</span>
              </div>
              <Input value={conditionName} setValue={setConditionName} placeholder="" title="Enter Condition Name" />
              <RenderErrors errors={errors} fieldName="name" />
            </div>
          </div>
        </div>
        <div className="px-[12px] py-[12px] text-[#333333] text-[12px] flex justify-start flex-col gap-4">
          <div className="flex w-full gap-[0.5rem] px-[1rem] justify-between">
            <Dropdown
              width="w-[5rem]"
              option={conditionType.label}
              setOption={setConditionType}
              options={conditionTypeOptions}
            />
            <SecondaryButton label="Add rule" handleClick={addCondition} icon={<PlusIcon stroke="#0f6cbd" />} />
          </div>
          <ConditionBuilder
            conditions={conditions}
            setConditions={setConditions}
            tags={tags}
            conditionType={conditionType.label == "AND" ? IConditionType.AND : IConditionType.ANY}
            errors={errors}
          />
          <SecondaryButton label="Add rule" handleClick={addCondition} icon={<PlusIcon stroke="#0f6cbd" />} />
        </div>
      </div>

      <div className="p-[1rem] flex gap-[0.5rem] border-t border-t-solid border-t-[#E0E0E0] bg-[#FDFDFD]">
        <Button variant="primary" label="Create" handleClick={handleCreateCondition} />
        <Button
          variant="secondary"
          label="Cancel"
          handleClick={async () => {
            if (setSelectedTab) {
              setSelectedTab("Conditions");
            }
          }}
        />
      </div>
    </div>
  );
};

export default CreateCondition;
