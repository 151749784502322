import React, { SVGProps } from "react";

const FrameIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1.25rem" height="1.25rem" fill="none" {...props}>
    <path
      fill={props.fill}
      d="M13.333 15.833a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Zm-6.667 0a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Zm6.667-6.667a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Zm-6.667 0a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Z"
    />
  </svg>
);

export default FrameIcon;
