import React from "react";
import { Diff } from "diff-match-patch";

export default function DiffRenderer({ diffs }: { diffs: Diff[] }): JSX.Element {
  return (
    <div>
      {diffs.map((diff, i) => {
        switch (diff[0]) {
          case 0:
            return <span key={i}>{diff[1]}</span>;
          case 1:
            return (
              <span key={i} className="bg-[#139652] bg-opacity-[0.12] font-[500]">
                {diff[1]}
              </span>
            );
          case -1:
            return (
              <span key={i} className="line-through bg-opacity-[0.12] bg-[#C83532] mr-1">
                {diff[1]}
              </span>
            );
          default:
            return <></>;
        }
      })}
    </div>
  );
}
