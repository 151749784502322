import React from "react";
import BranchIcon from "../../icons/BranchIcon";
import Button from "../../components/Button";
import replaceAll from "string.prototype.replaceall";

export default function TagCondition({
  condition,
  onTaggingCompleted,
  shouldShowContent,
}: {
  condition: any;
  onTaggingCompleted: () => Promise<void>;
  shouldShowContent: boolean;
}): JSX.Element {
  const addCondition = async (condition: any): Promise<void> => {
    await Word.run(async (context): Promise<void> => {
      const selection = context.document.getSelection();
      const name = replaceAll(condition.name, ` `, `_`);
      selection.insertText(`{{#HS_CONDITION_${name} === "${shouldShowContent}"}}`, "Before");
      selection.insertText(`{{/}}`, "After");
      const cc = selection.insertContentControl();
      cc.tag = condition._id;
      cc.title = condition.name;
      cc.color = "#2268D1";
      cc.appearance = "Tags";
      await context.sync();
    });
  };
  return (
    <Button
      variant="secondary"
      label={condition.name}
      leftIcon={<BranchIcon fill="#212121" />}
      handleClick={async () => {
        await addCondition(condition);
        await onTaggingCompleted();
      }}
    />
  );
}
