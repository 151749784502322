import React, { SVGProps } from "react";

const PlusIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="none" {...props}>
    <path
      stroke={props.stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 5.75v12.5M18.25 12H5.75"
    />
  </svg>
);

export default PlusIcon;
