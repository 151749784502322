const defaultTags = [
  {
    _id: "645a4eba62c1395744b25bf1",
    name: "Counterparty Name",
    type: "text",
    formType: "question",
    isCustom: false,
    description: "",
    defaultValue: "",
    options: [],
  },
  {
    _id: "6482c7562a8eeeded06454ba",
    name: "Counterparty Signer Email",
    type: "email",
    formType: "question",
    isCustom: false,
    description: "",
    defaultValue: "",
    options: [],
  },
  {
    _id: "6483250abefc3834675ddcc2",
    name: "Counterparty Signer Name",
    type: "text",
    formType: "question",
    isCustom: false,
    description: "",
    defaultValue: "",
    options: [],
  },
  {
    _id: "6482c73812d31d40cdafcbf1",
    name: "Counterparty Sign",
    type: "signature",
    formType: "question",
    isCustom: false,
    description: "",
    defaultValue: "",
    options: [],
  },

  {
    _id: "648323b385aaf247294c69fc",
    name: "Internalparty Name",
    type: "text",
    formType: "question",
    isCustom: false,
    description: "",
    defaultValue: "",
    options: [],
  },
  {
    _id: "64832545b154961fef37df19",
    name: "Internalparty Signer Name",
    type: "text",
    formType: "question",
    isCustom: false,
    description: "",
    defaultValue: "",
    options: [],
  },
  {
    _id: "6482c7653af4c123e5639e6c",
    name: "Internalparty Signer Email",
    type: "email",
    formType: "question",
    isCustom: false,
    description: "",
    defaultValue: "",
    options: [],
  },
  {
    _id: "6482c781e4b5c90cde4c49bc",
    name: "Internalparty Sign",
    type: "signature",
    formType: "question",
    isCustom: false,
    description: "",
    defaultValue: "",
    options: [],
  },
  {
    _id: "645a4f7662c1395744b25bf2",
    name: "Effective Date",
    type: "date",
    formType: "question",
    isCustom: false,
    description: "",
    defaultValue: "",
    options: [],
  },
  {
    _id: "645a4fa762c1395744b25bf3",
    name: "Agreement Date",
    type: "date",
    formType: "question",
    isCustom: false,
    description: "",
    defaultValue: "",
    options: [],
  },
  {
    _id: "645a4fda62c1395744b25bf4",
    name: "Expiration Date",
    type: "date",
    formType: "question",
    isCustom: false,
    description: "",
    defaultValue: "",
    options: [],
  },
  {
    _id: "645a500d62c1395744b25bf5",
    name: "Governing Law",
    type: "text",
    formType: "question",
    isCustom: false,
    description: "",
    defaultValue: "",
    options: [],
  },
  {
    _id: "645a50d907f0fb3b085b48c0",
    name: "Non Solicitation Of Employees",
    type: "text",
    formType: "question",
    isCustom: false,
    description: "",
    defaultValue: "",
    options: [],
  },
  {
    _id: "645a510707f0fb3b085b48c1",
    name: "Payment Days",
    type: "number",
    formType: "question",
    isCustom: false,
    description: "",
    defaultValue: "",
    options: [],
  },
  {
    _id: "645a51b507f0fb3b085b48c2",
    name: "Logo Usage",
    type: "text",
    formType: "question",
    isCustom: false,
    description: "",
    defaultValue: "",
    options: [],
  },
  {
    _id: "645a85fa583b505579b254d6",
    name: "Termination For Convenience",
    type: "text",
    formType: "question",
    isCustom: false,
    description: "",
    defaultValue: "",
    options: [],
  },
];

export const relatedInternalPartySignerTags = [
  "64832545b154961fef37df19", //name
  "6482c7653af4c123e5639e6c", //email
];

export const relatedCounterPartySingerTags = [
  "6482c7562a8eeeded06454ba", // name
  "6483250abefc3834675ddcc2", // email
];

export default defaultTags;
