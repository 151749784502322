import { User } from "../Pages/Comments/interface";
import { ClauseApproverAssigned } from "../Pages/RequestApproval/interface";

const getApproverName = ({
  approver,
  users,
  groups,
}: {
  approver: ClauseApproverAssigned;
  users: Array<User>;
  groups: Array<any>;
}): string => {
  switch (approver.type) {
    case "group": {
      return groups.find((group) => group._id === approver.approverId)?.name || "";
    }
    case "user": {
      return users.find((user) => user._id === approver.approverId)?.firstName || "";
    }
  }
};

export default getApproverName;
