import React from "react";
import getWebDavPayload from "./utils/getWebDavPayload";
import WorkflowNotFound from "./Pages/Overview/WorkflowNotFound";

export default function WorkflowIdValidator({ children }: { children: JSX.Element }): JSX.Element {
  const { workflowId } = getWebDavPayload(Office?.context?.document?.url || "");
  if (!workflowId) {
    return <WorkflowNotFound workflowId={workflowId} />;
  }
  return children;
}
