import React from "react";
import "./LoadingSpinner.css";

export interface LoadingSpinnerProps {
  shade: "Dark" | "Light";
  size: "Small" | "Medium" | "Large";
}
const LoadingSpinner = ({ shade, size }: LoadingSpinnerProps): JSX.Element => {
  return (
    <div
      data-testid="loading-spinner-[test-id]"
      className={shade === "Dark" ? `spinner-${size}` : `light-spinner-${size}`}
    ></div>
  );
};

export default LoadingSpinner;
