import { Comment, CommentFilterOptions, User } from "../Pages/Comments/interface";

export default function commentFilterHelper(
  comment: Comment,
  user: User | undefined,
  currentFilterOption: CommentFilterOptions
): boolean {
  switch (currentFilterOption) {
    case "Active comments":
      return !comment.isResolved;
    case "Resolved":
      return comment.isResolved;
    case "For you": {
      if (comment.isResolved) {
        return false;
      }
      if (!user) {
        return false;
      }
      if (comment.author.email === user.email) {
        return true;
      }
      if (comment.content.includes(`@${user.email}`)) {
        return true;
      }
      for (const reply of comment.replies) {
        if (reply.author.email === user?.email) {
          return true;
        }
        if (reply.content.includes(`@${user.email}`)) {
          return true;
        }
      }
      return false;
    }
  }
}
