import React from "react";

const BooleanIcon = ({ color }: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.16797 11.5003C8.16797 12.2367 7.5605 12.8503 6.85037 12.6554C4.77593 12.0861 3.16797 10.2175 3.16797 8.00026C3.16797 5.78304 4.77593 3.91451 6.85037 3.34513C7.5605 3.15023 8.16797 3.76389 8.16797 4.50027V11.5003Z"
        stroke={color ? color : "#464F60"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5043 11.6094C11.0418 12.1314 10.4752 12.551 9.83203 12.8328"
        stroke={color ? color : "#464F60"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6484 6.55469C12.7681 7.01337 12.8316 7.49777 12.8316 7.99863C12.8316 8.49957 12.7681 8.98397 12.6484 9.44263"
        stroke={color ? color : "#464F60"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.83203 3.16797C10.4752 3.4498 11.0418 3.86926 11.5043 4.39137"
        stroke={color ? color : "#464F60"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default BooleanIcon;
