import React, { SVGProps } from "react";

type IconSizes = "small" | "large";

const getIconForSize = (variant: IconSizes, fill: string | undefined): JSX.Element => {
  switch (variant) {
    case "small":
      return (
        <path
          fill={fill}
          d="M6.5 3a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm.547 3.94A2.5 2.5 0 1 0 6 6.95v6.1a2.5 2.5 0 1 0 1 0V8.852A5.78 5.78 0 0 0 8.312 9.87c1.126.652 2.505 1.038 3.735 1.115a2.5 2.5 0 1 0 .006-1.001c-1.066-.077-2.27-.417-3.24-.98-.95-.55-1.594-1.257-1.766-2.064ZM13 10.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM6.5 14a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"
        />
      );
    case "large":
      return (
        <path
          d="M5.3501 2.6998C6.92411 2.6998 8.2001 3.97579 8.2001 5.5498C8.2001 7.12382 6.92411 8.3998 5.3501 8.3998C3.77609 8.3998 2.5001 7.12382 2.5001 5.5498C2.5001 3.97579 3.77609 2.6998 5.3501 2.6998ZM6.38859 10.1859C8.51248 9.71227 10.1001 7.81644 10.1001 5.5498C10.1001 2.92645 7.97345 0.799805 5.3501 0.799805C2.72675 0.799805 0.600098 2.92645 0.600098 5.5498C0.600098 7.84781 2.23197 9.76468 4.4001 10.2048V21.7948C2.23197 22.2349 0.600098 24.1518 0.600098 26.4498C0.600098 29.0732 2.72675 31.1998 5.3501 31.1998C7.97345 31.1998 10.1001 29.0732 10.1001 26.4498C10.1001 24.1518 8.46823 22.2349 6.3001 21.7948V13.8191C7.01317 14.576 7.87502 15.2218 8.79286 15.7532C10.9314 16.9913 13.5517 17.7246 15.8896 17.8721C16.3189 20.054 18.2423 21.6998 20.5501 21.6998C23.1735 21.6998 25.3001 19.5732 25.3001 16.9498C25.3001 14.3265 23.1735 12.1998 20.5501 12.1998C18.2631 12.1998 16.3536 13.8161 15.9015 15.9687C13.8759 15.8238 11.589 15.1766 9.74483 14.1089C7.93918 13.0635 6.71677 11.7193 6.38859 10.1859ZM17.7001 16.9498C17.7001 15.3758 18.9761 14.0998 20.5501 14.0998C22.1241 14.0998 23.4001 15.3758 23.4001 16.9498C23.4001 18.5238 22.1241 19.7998 20.5501 19.7998C18.9761 19.7998 17.7001 18.5238 17.7001 16.9498ZM5.3501 23.5998C6.92411 23.5998 8.2001 24.8758 8.2001 26.4498C8.2001 28.0238 6.92411 29.2998 5.3501 29.2998C3.77609 29.2998 2.5001 28.0238 2.5001 26.4498C2.5001 24.8758 3.77609 23.5998 5.3501 23.5998Z"
          fill={fill}
        />
      );
  }
};

const BranchIcon = ({ size = "small", ...props }: SVGProps<SVGSVGElement> & { size?: IconSizes }): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width={props.width || 20}
      height={props.height || 20}
      {...props}
    >
      {getIconForSize(size, props.fill)}
    </svg>
  );
};

export default BranchIcon;
