import * as React from "react";
import { SVGProps } from "react";
const TrashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" {...props}>
    <path
      fill="#333"
      d="M11.409 4.348a1.409 1.409 0 1 0-2.819 0h-.939a2.348 2.348 0 0 1 4.697 0h4.227a.47.47 0 0 1 0 .94h-.52l-1.218 10.549a1.879 1.879 0 0 1-1.866 1.663H7.028a1.879 1.879 0 0 1-1.866-1.663L3.943 5.287h-.52a.47.47 0 0 1-.461-.384l-.008-.085c0-.26.21-.47.47-.47h7.985Zm3.699.94H4.89l1.205 10.44a.94.94 0 0 0 .933.833h5.943a.94.94 0 0 0 .933-.832l1.204-10.441ZM8.59 7.636c.231 0 .423.146.463.337l.007.074v5.754c0 .227-.21.411-.47.411-.23 0-.422-.145-.462-.337l-.007-.074V8.047c0-.227.21-.41.47-.41Zm2.819 0c.23 0 .422.146.462.337l.007.074v5.754c0 .227-.21.411-.47.411-.23 0-.422-.145-.461-.337l-.008-.074V8.047c0-.227.21-.41.47-.41Z"
    />
  </svg>
);
export default TrashIcon;
