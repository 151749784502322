import React, { SVGProps } from "react";

const PeopleIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" {...props}>
    <path
      fill={props.fill}
      d="M.667 11.467c0-.378.097-.725.291-1.042.195-.316.453-.558.775-.725a9.898 9.898 0 0 1 2.1-.775 9.172 9.172 0 0 1 4.334 0c.71.173 1.41.43 2.1.775.322.167.58.409.775.725.194.317.291.664.291 1.042V12c0 .367-.13.68-.391.942a1.284 1.284 0 0 1-.942.392H2c-.367 0-.68-.13-.942-.392A1.284 1.284 0 0 1 .667 12v-.533ZM12.3 13.334c.122-.2.214-.414.275-.642.061-.228.092-.458.092-.692v-.666c0-.49-.136-.959-.409-1.409-.272-.45-.658-.836-1.158-1.158a8.413 8.413 0 0 1 3 .933c.4.223.706.47.917.742.21.272.316.57.316.892V12c0 .367-.13.68-.391.942a1.284 1.284 0 0 1-.942.392h-1.7ZM6 8a2.568 2.568 0 0 1-1.883-.783 2.568 2.568 0 0 1-.784-1.883c0-.734.262-1.361.784-1.884A2.568 2.568 0 0 1 6 2.667c.733 0 1.361.261 1.883.783.523.523.784 1.15.784 1.884 0 .733-.261 1.36-.784 1.883A2.568 2.568 0 0 1 6 8Zm6.667-2.666c0 .733-.261 1.36-.784 1.883A2.568 2.568 0 0 1 10 8c-.122 0-.278-.014-.467-.041a4.082 4.082 0 0 1-.466-.092c.3-.356.53-.75.691-1.183C9.92 6.25 10 5.8 10 5.334c0-.467-.08-.917-.242-1.35A3.964 3.964 0 0 0 9.067 2.8c.155-.055.31-.091.466-.108A4.38 4.38 0 0 1 10 2.667c.733 0 1.361.261 1.883.783.523.523.784 1.15.784 1.884Z"
    />
  </svg>
);

export default PeopleIcon;
