import React, { useEffect, useState } from "react";

import { CommentFilterOptions, Comment } from "./interface";
import CommentIcon from "../../icons/CommentIcon";
import Button from "../../components/Button";
import CommentWithTopPlusIcon from "../../icons/CommentWithTopPlusIcon";
import ChevronDownIcon from "../../icons/ChevronDownIcon";
import CheckMarkIcon from "../../icons/CheckMarkIcon";
import ArrowRepeatAllIcon from "../../icons/ArrowRepeatAllIcon";
import NewCommentBox from "./NewCommentBox";
import CommentBox from "./CommentBox";
import AddCommentIcon from "../../icons/AddCommentIcon";
import commentFilterHelper from "../../utils/commentFilterHelper";
import getAllWordComments from "../../utils/wordApi/getAllWordComments";
import HeadlessDropDown from "../../components/HeadlessDropDown";
import { DirectionalHint, TooltipHost } from "@fluentui/react";
import FullPageSpinner from "../../components/FullPageSpinner";
import useCurrentUser from "../../hooks/useCurrentUser";
import { useOrgData } from "../../features/OrgDataProvider/OrgDataProvider";

export default function Comments(): JSX.Element {
  const { data: orgData } = useOrgData();
  const { users } = orgData;
  const user = useCurrentUser({ users });

  const [isAddingComment, setIsAddingComment] = useState<boolean>(false);

  const [currentFilterOption, setCurrentFilterOption] = useState<CommentFilterOptions>("Active comments");
  const [comments, setComments] = useState<Array<Comment>>([]);

  const filteredComments = comments.filter((comment): boolean =>
    commentFilterHelper(comment, user, currentFilterOption)
  );

  const [isLoadingComments, setIsLoadingCommets] = useState<boolean>(false);

  const getAllDocumentComments = async () => {
    setIsLoadingCommets(true);
    try {
      const newCommentsState: Comment[] = await Word.run(getAllWordComments);
      setComments(newCommentsState);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingCommets(false);
    }
  };

  useEffect(() => {
    getAllDocumentComments();
  }, []);

  const commentFilterOptions: Array<CommentFilterOptions> = ["Active comments", "For you", "Resolved"];

  if (Office.context?.document?.mode === Office.DocumentMode.ReadOnly) {
    return (
      <div className="flex flex-grow justify-center items-center">
        Open Document in Edit mode to view and interact with comments
      </div>
    );
  }

  const options = commentFilterOptions.map((text) => {
    return {
      children: (
        <>
          <span className="text-[#333333] text-[0.875rem] font-[400] leading-[1.25rem] whitespace-nowrap">{text}</span>
          {currentFilterOption === text ? <CheckMarkIcon fill="#212121" /> : <></>}
        </>
      ),
      clickHandler: async () => setCurrentFilterOption(text),
      customClasses: "px-[0.75rem] py-[0.25rem] justify-between flex",
    };
  });

  return (
    <div className="bg-[#FAFAFA] overflow-hidden flex-grow flex flex-col">
      <div className="bg-[#FFF] border-b-[1px] border-solid border-[#E0E0E0] p-[0.75rem] flex justify-between">
        <HeadlessDropDown
          selectElement={
            <>
              <div className="px-[0.625rem] rounded-[0.25rem] border-[1px] border-solid border-[#D1D1D1] flex flex-row grow justify-between self-stretch">
                <div className="p-[0.3125rem_0.125rem_0.4375rem_0.125rem]">
                  <span className="text-[#333333] text-[0.875rem] leading-[1.25rem]">{currentFilterOption}</span>
                </div>
                <div className="p-[0.375rem_0rem_0.375rem_0.125rem]">
                  <ChevronDownIcon width="1.25rem" height="1.25rem" fill="#616161" />
                </div>
              </div>
              <div className="absolute bottom-[-1px] w-full h-[0.0625rem] flex justify-center">
                <div className="bg-[#616161] w-[90%] h-[0.0625rem]"></div>
              </div>
            </>
          }
          options={options}
          customContainerClasses="shrink-0 w-[12.5rem]"
          customOptionsContainerClasses="w-[12.5rem] bg-[#FFF] top-[2.4rem] p-[0.5rem] shadow-md rounded-[0.25rem] border-[1px] border-solid border-[#D9DBDD] gap-[0.25rem]"
        />
        <div
          onClick={async (e) => {
            e.stopPropagation();
            await getAllDocumentComments();
          }}
          className="cursor-pointer"
        >
          <TooltipHost
            content={<span className="text-[#FFF] text-[0.75rem]">Reload comments</span>}
            directionalHint={DirectionalHint.leftCenter}
            tooltipProps={{
              calloutProps: {
                styles: {
                  beak: { background: "#333333" },
                  beakCurtain: { background: "#333333" },
                  calloutMain: { background: "#333333" },
                },
              },
            }}
          >
            <ArrowRepeatAllIcon
              id="reloadCommentsIcon"
              className={`${isLoadingComments ? "animate-spin" : ""}`}
              width="1.5rem"
              height="1.5rem"
              fill="none"
            />
          </TooltipHost>
        </div>
      </div>
      {isLoadingComments && filteredComments.length === 0 ? (
        <FullPageSpinner />
      ) : (
        <>
          {isAddingComment || filteredComments.length > 0 ? (
            <div
              className={`p-[0.75rem] flex-grow flex flex-col gap-[1rem] overflow-y-auto ${
                isLoadingComments ? "opacity-50 pointer-events-none cursor-not-allowed" : ""
              }`}
            >
              {isAddingComment ? (
                <div className="p-[0.75rem]">
                  <NewCommentBox
                    users={users}
                    setCommentingDone={async () => {
                      setIsAddingComment(false);
                      getAllDocumentComments();
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
              {filteredComments.length > 0 ? (
                <>
                  {filteredComments.map((comment) => (
                    <CommentBox
                      key={comment.id}
                      users={users}
                      comment={comment}
                      reloadComments={getAllDocumentComments}
                    />
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
          {filteredComments.length > 0 && !isAddingComment ? (
            <div className="px-[1rem] pb-[1.75rem] pt-[1rem] flex justify-end">
              <Button
                variant="primary"
                label="Comment"
                leftIcon={<AddCommentIcon width="1.25rem" height="1.25rem" fill="#fff" />}
                // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                handleClick={async (_e) => {
                  setIsAddingComment(true);
                }}
                isDisabled={isLoadingComments}
              />
            </div>
          ) : (
            <></>
          )}
          {filteredComments.length === 0 && !isAddingComment ? (
            <div className="px-[3.875rem] flex-grow flex flex-col items-center justify-center gap-[0.5rem]">
              <CommentIcon width={"36"} height={"36"} color={"#838383"} />
              <div className="flex flex-col items-center gap-[1.5rem]">
                <div className="flex flex-col items-center gap-[0.25rem]">
                  <span className="text-[#171C26] text-[0.875rem] font-[600] leading-[150%]">No comments yet</span>
                  <span className="text-[#616161] text-[center] text-[0.875rem] font-[400] leading-[150%]">
                    Highlight text on the contract and click on the button below
                  </span>
                </div>
                <Button
                  variant="secondary"
                  label="add comment"
                  isDisabled={!user}
                  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                  handleClick={async (_e: React.MouseEvent<HTMLElement>) => {
                    setIsAddingComment(true);
                  }}
                  leftIcon={<CommentWithTopPlusIcon width="20" height="20" color="#333" />}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
}
