import React from "react";
import Menu from "./Menu";

export default function WorkflowNotFound({ workflowId }: { workflowId: string }): JSX.Element {
  return (
    <div className="p-[0.75rem] flex-grow flex flex-col justify-center items-center">
      <Menu workflowId={workflowId} />
      <span>Workflow not found</span>
    </div>
  );
}
