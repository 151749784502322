import React, { SVGProps } from "react";

const DeleteIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" {...props}>
    <path
      fill={props.color}
      d="M11.41 4.348a1.41 1.41 0 1 0-2.82 0h-.938a2.348 2.348 0 1 1 4.696 0h4.228a.47.47 0 0 1 0 .94h-.52l-1.218 10.549a1.879 1.879 0 0 1-1.866 1.663H7.029a1.879 1.879 0 0 1-1.867-1.663L3.944 5.287h-.52a.47.47 0 0 1-.462-.384l-.007-.085c0-.26.21-.47.47-.47h7.984Zm3.698.94H4.891l1.204 10.44a.94.94 0 0 0 .934.833h5.943a.94.94 0 0 0 .933-.832l1.204-10.441ZM8.591 7.636c.23 0 .422.146.462.337l.008.074v5.754c0 .227-.21.411-.47.411-.23 0-.422-.145-.462-.337l-.008-.074V8.047c0-.227.21-.41.47-.41Zm2.818 0c.23 0 .422.146.462.337l.008.074v5.754c0 .227-.21.411-.47.411-.23 0-.422-.145-.462-.337l-.008-.074V8.047c0-.227.21-.41.47-.41Z"
    />
  </svg>
);

export default DeleteIcon;
