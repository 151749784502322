import React from "react";

const GroupIconWhite = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 8.6C0.5 8.31667 0.572917 8.05625 0.71875 7.81875C0.864583 7.58125 1.05833 7.4 1.3 7.275C1.81667 7.01667 2.34167 6.82292 2.875 6.69375C3.40833 6.56458 3.95 6.5 4.5 6.5C5.05 6.5 5.59167 6.56458 6.125 6.69375C6.65833 6.82292 7.18333 7.01667 7.7 7.275C7.94167 7.4 8.13542 7.58125 8.28125 7.81875C8.42709 8.05625 8.5 8.31667 8.5 8.6V9C8.5 9.275 8.40209 9.51042 8.20625 9.70625C8.01042 9.90208 7.775 10 7.5 10H1.5C1.225 10 0.989583 9.90208 0.79375 9.70625C0.597917 9.51042 0.5 9.275 0.5 9V8.6ZM9.225 10C9.31667 9.85 9.38542 9.68958 9.43125 9.51875C9.47709 9.34792 9.5 9.175 9.5 9V8.5C9.5 8.13333 9.39792 7.78125 9.19375 7.44375C8.98958 7.10625 8.7 6.81667 8.325 6.575C8.75 6.625 9.15 6.71042 9.525 6.83125C9.9 6.95208 10.25 7.1 10.575 7.275C10.875 7.44167 11.1042 7.62708 11.2625 7.83125C11.4208 8.03542 11.5 8.25833 11.5 8.5V9C11.5 9.275 11.4021 9.51042 11.2063 9.70625C11.0104 9.90208 10.775 10 10.5 10H9.225ZM4.5 6C3.95 6 3.47917 5.80417 3.0875 5.4125C2.69583 5.02083 2.5 4.55 2.5 4C2.5 3.45 2.69583 2.97917 3.0875 2.5875C3.47917 2.19583 3.95 2 4.5 2C5.05 2 5.52083 2.19583 5.9125 2.5875C6.30417 2.97917 6.5 3.45 6.5 4C6.5 4.55 6.30417 5.02083 5.9125 5.4125C5.52083 5.80417 5.05 6 4.5 6ZM9.5 4C9.5 4.55 9.30417 5.02083 8.9125 5.4125C8.52083 5.80417 8.05 6 7.5 6C7.40834 6 7.29167 5.98958 7.15 5.96875C7.00834 5.94792 6.89167 5.925 6.8 5.9C7.025 5.63333 7.19792 5.3375 7.31875 5.0125C7.43959 4.6875 7.5 4.35 7.5 4C7.5 3.65 7.43959 3.3125 7.31875 2.9875C7.19792 2.6625 7.025 2.36667 6.8 2.1C6.91667 2.05833 7.03333 2.03125 7.15 2.01875C7.26667 2.00625 7.38334 2 7.5 2C8.05 2 8.52083 2.19583 8.9125 2.5875C9.30417 2.97917 9.5 3.45 9.5 4Z"
        fill="white"
      />
    </svg>
  );
};

export default GroupIconWhite;
