import { getDiff } from "../diffMatchPatch";
import getMatchingParagraph from "./getMatchingParagraph";

const diffReplace = async (
  context: Word.RequestContext,
  paraText: string,
  correction: string,
  paraIndex: number
): Promise<void> => {
  const paragraph = await getMatchingParagraph(context, paraText, paraIndex);
  if (!paragraph) {
    throw new Error("Clause not found");
  }
  const diffs = getDiff({ current: paraText, correction });
  const letters = paragraph.search("?", { matchWildcards: true });
  letters.load("text");
  let current = 0;
  await context.sync();
  for (let i = 0; i < diffs.length; i++) {
    const [operation, text] = diffs[i];
    if (operation === 1) {
      letters.items[current].insertText(text, Word.InsertLocation.start);
    } else {
      for (const letter of text) {
        if (letter === letters.items[current].text) {
          switch (operation) {
            case 0: {
              current += 1;
              break;
            }
            case -1: {
              letters.items[current].delete();
              current += 1;
              break;
            }
          }
        }
      }
    }
  }
  await context.sync();
};

export default diffReplace;
