import * as React from "react";

const CommentIcon = ({ width, height, color }: { width: string; height: string; color: string }): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
    <path
      fill={color}
      d="M8.143 8.155v-.34a5.09 5.09 0 0 1 5.09-5.088h14.25a7.804 7.804 0 0 1 7.803 7.803v9.487a5.09 5.09 0 0 1-5.09 5.09h-.339v.339a5.09 5.09 0 0 1-5.09 5.09H16.63l-5.762 4.39c-1.116.85-2.724.053-2.724-1.35v-3.04h-.34a5.09 5.09 0 0 1-5.089-5.09V13.245a5.09 5.09 0 0 1 5.09-5.09h.339Zm2.036-.34v.34h14.589a5.09 5.09 0 0 1 5.09 5.09v9.825h.338a3.054 3.054 0 0 0 3.054-3.053V10.53a5.768 5.768 0 0 0-5.768-5.768h-14.25a3.054 3.054 0 0 0-3.053 3.054Zm-5.429 5.43v12.2A3.054 3.054 0 0 0 7.804 28.5H9.16c.562 0 1.018.456 1.018 1.018v3.374l5.49-4.183c.177-.135.394-.209.617-.209h8.482a3.054 3.054 0 0 0 3.053-3.053V13.245a3.054 3.054 0 0 0-3.053-3.054H7.804a3.054 3.054 0 0 0-3.054 3.054Z"
    />
  </svg>
);

export default CommentIcon;
