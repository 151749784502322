import React, { useContext } from "react";
import TagIcon from "../Fields/TagIcon";
import { useContentControls } from "../../hooks/useContentControl";
import { useNavigationDispatch } from "../../features/Navigation/NavigationContext";
import Button from "../../components/Button";
import { FetchContext } from "../../api/FetchContext";
import { useNotificationToastDispatch } from "../../features/NotificationToast/NotificationContext";
import replaceAll from "string.prototype.replaceall";
import {
  BACKEND_URL,
  COUNTERPARTY_SIGN_TAGID,
  COUNTERPARTY_SIGNER_ANCHORTAG,
  INTERNALPARTY_SIGN_TAGID,
  INTERNALPARTY_SIGNER_ANCHORTAG,
} from "../../constants";
import defaultTags, { relatedCounterPartySingerTags, relatedInternalPartySignerTags } from "../../utils/defaultTags";

export default function TagFieldView({
  title,
  tags,
  templateTags,
  templateId,
  reloadTags,
  mode,
}: {
  title: string;
  tags: Array<any>;
  templateTags: Array<any>;
  templateId: string;
  reloadTags: () => void;
  mode: string;
}): JSX.Element {
  const fetcher = useContext(FetchContext);
  const notificationDispatch = useNotificationToastDispatch();
  const setSelectedTab = useNavigationDispatch();
  const { refreshContentControls } = useContentControls({ availableTags: tags });

  const getMissingTags = (tags: Array<any>, tag: Array<any>): Array<any> => {
    const relatedTags = getRelatatedSignerTags(tag);
    const defaultSignerTags = defaultTags.filter((tag) => relatedTags.includes(tag._id));
    const apiTagNames = tags.map((tag) => tag.name);
    return defaultSignerTags.filter((tag) => !apiTagNames.includes(tag.name));
  };

  const getRelatatedSignerTags = (tag: any): Array<any> => {
    switch (tag?._id) {
      case COUNTERPARTY_SIGN_TAGID:
        return relatedCounterPartySingerTags;
      case INTERNALPARTY_SIGN_TAGID:
        return relatedInternalPartySignerTags;
      default:
        return [];
    }
  };

  const addTag = async (tag: any): Promise<void> => {
    await Word.run(async (context): Promise<void> => {
      const selection = context.document.getSelection();
      const name = replaceAll(tag.name, ` `, `_`);

      if (tag.name === "Internalparty Sign") {
        selection.insertText(`${INTERNALPARTY_SIGNER_ANCHORTAG}`, "Replace");
        selection.font.color = "#FFFFFF";
        const cc = selection.insertContentControl();
        cc.tag = tag._id;
        cc.title = tag.name;
        cc.color = "#14804A";
        cc.appearance = "BoundingBox";
        await context.sync();
      } else if (tag.name === "Counterparty Sign") {
        selection.insertText(`${COUNTERPARTY_SIGNER_ANCHORTAG}`, "Replace");
        selection.font.color = "#FFFFFF";
        const cc = selection.insertContentControl();
        cc.tag = tag._id;
        cc.title = tag.name;
        cc.color = "#14804A";
        cc.appearance = "BoundingBox";
        await context.sync();
      } else if (tag.type === "table") {
        const fillList: Array<string> = tag.tableColumns.map(
          (column: any) => `{{HS_COLUMN_FIELD_${replaceAll(column.value, ` `, "_")}}}`
        );
        fillList[0] = `{{#HS_TABLE_${name}}}${fillList[0]}`;
        fillList[fillList.length - 1] = `${fillList[fillList.length - 1]}{{/}}`;
        const table = selection.insertTable(2, tag.tableColumns.length, "Before", [
          tag.tableColumns.map((column: any) => column.value),
          fillList,
        ]);
        selection.font.color = "#000000";
        const cc = table.insertContentControl();
        cc.tag = tag._id;
        cc.title = tag.name;
        cc.color = "#14804A";
        cc.appearance = "BoundingBox";
        await context.sync();
      } else {
        selection.insertText(`{{HS_FIELD_${name}}}`, "Replace");
        const cc = selection.insertContentControl();
        selection.font.color = "#000000";
        cc.tag = tag._id;
        cc.title = tag.name;
        cc.color = "#14804A";
        cc.appearance = "BoundingBox";
        await context.sync();
      }
    });
    await refreshContentControls();
    const tagInTemplate = templateTags.find((templateTag) => templateTag._id === tag._id);

    const missingTags = getMissingTags(templateTags, tag);

    if (!tagInTemplate && fetcher && notificationDispatch) {
      await fetcher(`${BACKEND_URL}/api/v1/template/updateTags`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          templateId,
          tags: templateTags.concat(tag).concat(missingTags),
        }),
      }).catch((e) => {
        if (e instanceof Error) {
          notificationDispatch({ type: "error", message: e.message });
        }
      });
      reloadTags();
    }
    if (setSelectedTab) {
      setSelectedTab("Fields");
    }
  };

  if (tags.length === 0) {
    return <></>;
  }
  return (
    <div className="p-[0.75rem] bg-[#FAFAFA] flex flex-col gap-[0.5rem]">
      <div className="flex flex-row justify-between">
        <span className="text-[#616161] text-[0.75rem] font-[600]">{title}</span>
      </div>
      <div className="flex flex-col gap-[0.5rem] overflow-y-auto">
        {tags.map((tag) => {
          if ((mode === "table" && tag.type !== "table") || (mode === "field" && tag.type === "table")) {
            return "";
          }
          return (
            <Button
              variant="secondary"
              key={tag._id}
              handleClick={async () => addTag(tag)}
              isDisabled={false}
              label={tag.name}
              leftIcon={
                <div className="pb-[0.125rem]">
                  <TagIcon type={tag.type} color="#616161" />
                </div>
              }
            />
          );
        })}
      </div>
    </div>
  );
}
