import * as React from "react";
const TickIcon = ({ width, height, fillColor }: { width: string; height: string; fillColor: string }): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
    <path
      fill={fillColor}
      d="M10.5 20.615c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10Zm4.207-11.793-4.5 4.5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9.5 11.201l3.793-3.793a1 1 0 1 1 1.414 1.414Z"
    />
  </svg>
);
export default TickIcon;
