import React, { SVGProps } from "react";

const CheckMarkIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={"1.25rem"} height={"1.25rem"} fill="none" {...props}>
    <path
      fill={props.fill}
      d="M13.864 3.656a.5.5 0 0 1-.021.707l-7.93 7.474a.6.6 0 0 1-.839-.016l-2.68-2.72a.5.5 0 0 1 .712-.702l2.406 2.442 7.645-7.206a.5.5 0 0 1 .707.021Z"
    />
  </svg>
);

export default CheckMarkIcon;
