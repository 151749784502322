import React from "react";
import TextTagTypeIcon from "../../icons/TextTagTypeIcon";
import EmailTagTypeIcon from "../../icons/EmailTagTypeIcon";
import DateTagTypeIcon from "../../icons/DateTagTypeIcon";
import SignIcon from "../../icons/SignIcon";
import NumberIcon from "../../icons/NumberIcon";
import BooleanIcon from "../../icons/BooleanIcon";
import { DropdownDownIcon } from "../../icons/DropdownIcon";

export default function TagIcon({ type, color }: { type: string; color: string }): JSX.Element {
  switch (type) {
    case "text":
      return <TextTagTypeIcon fill={color} />;
    case "email":
      return <EmailTagTypeIcon fill={color} />;
    case "date":
      return <DateTagTypeIcon fill={color} />;
    case "signature":
      return <SignIcon fill={color} />;
    case "number":
      return <NumberIcon color={color} />;
    case "boolean":
      return <BooleanIcon color={color} />;
    case "dropdown":
      return <DropdownDownIcon color={color} />;
    default:
      return <TextTagTypeIcon fill={color} />;
  }
}
