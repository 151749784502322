import { useState, useEffect } from "react";
import getAllContentControls from "../utils/wordApi/getAllContentControls";

export function useContentControls({ availableTags }: { availableTags: Array<any> }): {
  contentControls: Map<string, Array<any>>;
  refreshContentControls: () => Promise<void>;
  isLoadingContentControls: boolean;
} {
  const [contentControls, setContentControls] = useState<Map<string, Array<any>>>(new Map<string, Array<any>>());
  const [isLoadingContentControls, setIsLoadingContentControls] = useState<boolean>(false);

  const loadAllContentControls = async (): Promise<void> => {
    setIsLoadingContentControls(true);
    const newContentControls = await Word.run(async (context) => {
      return getAllContentControls(context, availableTags);
    });
    setContentControls(newContentControls);
    setIsLoadingContentControls(false);
  };

  const refreshContentControls = async (): Promise<void> => {
    await loadAllContentControls();
  };

  useEffect(() => {
    loadAllContentControls();
  }, []);

  return { contentControls, refreshContentControls, isLoadingContentControls };
}
