import React from "react";

import { Mode, NavigationProvider } from "./features/Navigation/NavigationContext";
import WorkflowRoutes from "./WorkflowRoutes";
import TemplateRoutes from "./TemplateRoutes";
import WorkflowIdValidator from "./WorkflowIdValidator";
import { WorkflowDataProvider } from "./features/WorkflowDataProvider/WorkflowDataProvider";

function ProtectedRoutes({ mode }: { mode: Mode }): JSX.Element {
  switch (mode) {
    case "Workflow":
      return (
        <WorkflowIdValidator>
          <WorkflowDataProvider>
            <NavigationProvider mode={mode}>
              <WorkflowRoutes />
            </NavigationProvider>
          </WorkflowDataProvider>
        </WorkflowIdValidator>
      );
    case "Template":
      return (
        <NavigationProvider mode={mode}>
          <TemplateRoutes />
        </NavigationProvider>
      );
    default:
      return <div>not found</div>;
  }
}

export default ProtectedRoutes;
