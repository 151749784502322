import React from "react";
import LoadingSpinner from "./LoadingSpinner";

export default function FullPageSpinner(): JSX.Element {
  return (
    <div className="flex flex-grow justify-center items-center">
      <LoadingSpinner shade="Light" size="Large" />
    </div>
  );
}
