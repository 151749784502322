import React, { useState } from "react";
import { Radio, RadioGroup } from "@fluentui/react-radio";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";

import ArrowLeftIcon from "../../icons/ArrowleftIcon";
import Button from "../../components/Button";
import { useNavigationDispatch } from "../../features/Navigation/NavigationContext";
import SearchIcon from "../../icons/SearchIcon";
import TagCondition from "./TagCondition";
import { useContentControls } from "../../hooks/useContentControl";
import LineIcon from "../../icons/LineIcon";

export default function TagConditionPage({ conditions }: { conditions: Array<any> }): JSX.Element {
  const setSelectedTab = useNavigationDispatch();
  const [searchValue, setSearchValue] = useState<string>("");
  const [shouldShowContent, setShouldShowContent] = useState<boolean>(true);
  const conditionsToRender = conditions.filter((condition) =>
    condition.name.trim().toLowerCase().includes(searchValue.trim().toLowerCase())
  );
  const { refreshContentControls } = useContentControls({ availableTags: conditions });
  return (
    <div className="overflow-y-auto flex-grow flex flex-col">
      <div className="px-[1rem] py-[0.75rem] flex flex-col bg-[#FFFFFF] gap-[0.5rem] border-b-[1px] border-b-solid border-b-[#E6E6E6]">
        <div className="flex gap-[0.2rem]">
          <Button
            variant="secondary"
            label=""
            leftIcon={<ArrowLeftIcon fill="#171C26" />}
            handleClick={async () => {
              if (setSelectedTab) {
                setSelectedTab("Conditions");
              }
            }}
            customClasses="border-none bg-transparent hover:bg-transparent"
          />
          <span className="text-[#171C26] font-[600] leading-[2rem]">Add a condition</span>
        </div>
        <div className="self-stretch h-0">
          <LineIcon stroke="#E0E0E0" height={"5px"} />
        </div>
        <FluentProvider theme={webLightTheme} className="!bg-transparent">
          <RadioGroup
            className="flex flex-col gap-[0.125rem]"
            value={shouldShowContent.toString()}
            onChange={(_, data) => setShouldShowContent(JSON.parse(data.value))}
          >
            <Radio value={"true"} label="Show content if condition is true" />
            <Radio value={"false"} label="Hide content if condition is true" />
          </RadioGroup>
        </FluentProvider>
        <div className="flex flex-col gap-[0.5rem] py-[0.25rem]">
          <form className="px-[0.625rem] bg-[#FFF] rounded-[0.25rem] border-[1px] border-solid border-[#D1D1D1] flex flex-row self-stretch gap-[0.625rem]">
            <div className="flex self-stretch items-center">
              <SearchIcon fill="#616161" />
            </div>
            <input
              type="text"
              title="search fields"
              placeholder="Search"
              className="py-[0.25rem] focus:shadow-outline border-none appearence-none focus:outline-none placeholder:text-[#707070] text-[0.875rem] leading-[1.25rem] flex flex-grow"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </form>
        </div>
      </div>
      <div className="px-[1rem] py-[0.75rem] flex flex-col bg-[#FAFAFA] flex-grow">
        <div className="flex flex-col gap-[0.5rem]">
          <div className="pl-[0.25rem]">
            <span className="text-[#616161] text-[0.75rem] font-[600]">Custom conditions</span>
          </div>
          <div className="flex flex-col gap-[0.25rem]">
            {conditionsToRender.map((condition) => (
              <TagCondition
                key={condition._id}
                condition={condition}
                onTaggingCompleted={async () => {
                  await refreshContentControls();
                  if (setSelectedTab) {
                    setSelectedTab("Conditions");
                  }
                }}
                shouldShowContent={shouldShowContent}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
