import React, { useContext, useEffect, useState } from "react";
import { Diff } from "diff-match-patch";
import { Buffer } from "buffer";

import Button from "../../components/Button";
import ArrowLeftIcon from "../../icons/ArrowleftIcon";
import isDeviationCompliant from "../../utils/isCompliant";
import DismissIcon from "../../icons/DismissIcon";
import ChevronDownIcon from "../../icons/ChevronDownIcon";
import FrameIcon from "../../icons/FrameIcon";
import StandardPosition from "./StandardPosition";
import FallbackPosition from "./FallbackPosition";
import FilledGreenCheckIcon from "../../icons/FilledGreenCheckIcon";
import Drawer from "../../components/Drawer";
import TrashIcon from "../../icons/TrashIcon";
import NonCompliantIcon from "../../icons/NonCompliantIcon";
import Modal from "../../components/Modal";
import { getDiff } from "../../utils/diffMatchPatch";
import DiffRenderer from "./DiffRenderer";
import diffReplace from "../../utils/wordApi/diffReplace";
import useDocumentSelection from "../../hooks/useDocumentSelection";
import { Checkbox } from "@fluentui/react";
import { AcceptIcon } from "../../icons/AcceptIcon";
import { RequestApprovalIcon } from "../../icons/RequestApprovalcon";
import { ApprovalPendingIcon } from "../../icons/ApprovalPendingIcon";
import { FetchContext } from "../../api/FetchContext";
import { BACKEND_URL } from "../../constants";
import { useWorkflowData } from "../../features/WorkflowDataProvider/WorkflowDataProvider";
import { ClauseApprovalStatus } from "../ClauseApprovals/interface";
import { insertPositionApi } from "./usePosition";
import getMatchingParagraph from "../../utils/wordApi/getMatchingParagraph";
import { useParagraphsData } from "../../Providers/ParagraphsProvider";

export default function Deviation({
  deviationId,
  navigateBack,
}: {
  deviationId: string;
  navigateBack: () => void;
}): JSX.Element {
  const fetcher = useContext(FetchContext);
  const { data: workflowData, reload: reloadWorkflowData, isRefetchingData } = useWorkflowData();
  const { deviations: allDeviations, playbooks } = workflowData;
  const deviation = allDeviations.find((deviation) => deviation._id === deviationId);
  const positions = playbooks.find((playbook: any) => playbook._id === deviation.playBookId._id)?.positions;
  const standardPositions = positions?.filter((position: any) => position.type === "standard") || [];
  const fallbackPositions = positions?.filter((position: any) => position.type === "fallback") || [];
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isNonCompliantModalOpen, setIsNonCompliantModalOpen] = useState(false);
  const [isRemovePlaybookTagModalOpen, setIsRemovePlaybookTagModalOpen] = useState(false);
  const [isClauseReferenceDrawerOpen, setIsClauseReferenceDrawerOpen] = useState(false);
  const [isClauseMarkedAsCompliant, setIsClauseMarkedAsCompliant] = useState(true);
  const [isSelectedTextExpanded, setIsSelectedTextExpanded] = useState<boolean>(false);
  const [isParagraphIndexCorrect, setIsParagraphIndexCorrect] = useState<boolean>(true);

  const { docText } = useDocumentSelection();
  const { paragraphsHash } = useParagraphsData();
  const workflow = workflowData.workflowResponse.workflow;

  const { clauseApprovals } = workflowData.clauseApprovalsResponse;
  const clauseApprovalOfDeviation = clauseApprovals.find((approval) => approval.deviationId === deviation._id);
  const isDeviationApproved = (clauseApprovalOfDeviation?.status as ClauseApprovalStatus) === "approved";
  const isActionsDisabled = !isParagraphIndexCorrect || deviation.status !== "pending";

  // const isDocHashEqual = !paragraphsHash || paragraphsHash === deviation.documentHash;

  const correctionTransformed: Diff[] = getDiff({
    current: deviation.docText || "",
    correction: deviation.correction || "",
  });

  const handleMarkClause = async ({
    docText,
    isClauseCompliant,
    status,
  }: {
    docText: string;
    isClauseCompliant: boolean;
    status: string;
  }): Promise<void> => {
    if (!fetcher) {
      return;
    }
    await fetcher(`${BACKEND_URL}/api/v1/deviation/markClause`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        workflowId: workflow._id,
        deviationId: deviation._id,
        clause: Buffer.from(docText).toString("base64"),
        isMet: isClauseCompliant,
        isClauseInDocument: true,
        status,
      }),
    });
  };

  const handleMarkCompliance = async (isMet: boolean): Promise<void> => {
    if (!fetcher) {
      return;
    }
    await fetcher(`${BACKEND_URL}/api/v1/deviation/markCompliance`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        workflowId: workflow._id,
        deviationId: deviation._id,
        isMet: isMet,
      }),
    });
  };

  const handleRemovePlaybookTag = async (): Promise<void> => {
    if (!fetcher) {
      return;
    }
    await fetcher(`${BACKEND_URL}/api/v1/deviation/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        workflowId: workflow._id,
        deviationId: deviation._id,
      }),
    });
  };

  const handleExclusionApproval = async () => {
    if (!fetcher) {
      return;
    }
    const commentId = await Word.run(async (context: Word.RequestContext): Promise<string> => {
      const paragraph = await getMatchingParagraph(context, deviation.docText, deviation.paragraphIndex);
      if (!paragraph) {
        return "";
      }
      const comment = paragraph.getRange().insertComment(`(Internal) (approval) ${deviation.playBookId.description}`);
      await context.sync();
      return comment.id;
    });
    if (!commentId) {
      return;
    }
    await insertPositionApi({
      fetcher,
      workflowId: workflow._id,
      deviationId: deviation._id,
      type: "exclusion",
      commentId,
    });
    await reloadWorkflowData();
  };

  const checkIsParagraphIndexCorrect = async () => {
    // paragraph index not found by AI
    if (!deviation.paragraphIndex) {
      setIsParagraphIndexCorrect(false);
      return;
    }
    await Word.run(async (context: Word.RequestContext): Promise<void> => {
      const paragraph = await getMatchingParagraph(context, deviation.docText, deviation.paragraphIndex);
      if (paragraph) {
        setIsParagraphIndexCorrect(true);
      } else {
        setIsParagraphIndexCorrect(false);
      }
    });
  };

  useEffect(() => {
    checkIsParagraphIndexCorrect();
  }, [paragraphsHash]);

  return (
    <div
      className={`flex-grow self-stretch overflow-y-auto flex flex-col ${
        isRefetchingData ? "bg-white/40" : "bg-white/100"
      }`}
    >
      {isDeviationApproved ? (
        <div className="border-[#9FD89F] border-b bg-[#F1FAF1] text-[#242424] text-[12px] p-[12px] w-full flex items-center justify-center">
          Approved
        </div>
      ) : (
        <>
          {deviation.status === "standard" && (
            <div className="border-[#9FD89F] border-b bg-[#F1FAF1] text-[#242424] text-[12px] p-[12px] w-full flex items-center justify-center">
              You inserted standard position
            </div>
          )}
          {deviation.status === "aiSuggestion" && (
            <div className="border-[#9FD89F] border-b bg-[#F1FAF1] text-[#242424] text-[12px] p-[12px] w-full flex items-center justify-center">
              You inserted AI Suggestion
            </div>
          )}
          {deviation.status === "fallback" && (
            <div className="border-[#9FD89F] border-b bg-[#F1FAF1] text-[#242424] text-[12px] p-[12px] w-full flex items-center justify-center">
              You inserted fallback position
            </div>
          )}
          {deviation.status === "exclusion" && (
            <div className="border-[#D1D1D1] border-b bg-[#F5F5F5] text-[#242424] text-[12px] p-[12px] w-full items-center justify-center flex gap-[4px]">
              <ApprovalPendingIcon width={16} height={16} />
              <div>Approval pending</div>
            </div>
          )}
        </>
      )}

      <div className="px-[0.625rem] py-[0.75rem] flex flex-col gap-3">
        <div className="flex flex-col gap-2 justify-start">
          <div className="flex gap-2">
            <Button
              variant="secondary"
              label=""
              leftIcon={<ArrowLeftIcon fill="#171C26" />}
              handleClick={async () => navigateBack()}
              customClasses="border-none hover:bg-[#FFFFFF]"
            />
            <span className="text-[#171C26] text-[1.25rem] font-[600] leading-[2rem]">
              {deviation.playBookId.clauseType}
            </span>
          </div>
          <div className="px-[1.75rem] flex gap-2">
            <div className="flex gap-1">
              {isDeviationCompliant(deviation) ? (
                <>
                  <FilledGreenCheckIcon />
                  <span className="text-[#14804A] text-[0.75rem]">Compliant</span>
                </>
              ) : (
                <>
                  <DismissIcon fill="#C83532" />
                  <span className="text-[#C83532] text-[0.75rem]">Not compliant</span>
                </>
              )}
              <div className="bg-[#D9DBDD] h-full w-[1px]"></div>
              <span className="text-[#616161] text-[0.75rem]">
                {deviation.playBookId.complianceType === "present" ? "Mandatory clause" : "Prohibited Clause"}
              </span>
            </div>
          </div>
          <div className="pl-[1.75rem]">
            <span className="text-[0.875rem]">{deviation.playBookId.description}</span>
          </div>
          <div className="pl-[1.75rem] flex justify-start items-center gap-1">
            <Button
              variant="primary"
              label="Actions"
              leftIcon={<FrameIcon fill="#fff" />}
              rightIcon={<ChevronDownIcon fill="#fff" />}
              handleClick={async () => {
                setIsDrawerOpen(true);
              }}
              isDisabled={isActionsDisabled}
            />
            {isDeviationCompliant(deviation) ? (
              <></>
            ) : (
              <Button
                variant="secondary"
                label="Request Approval"
                leftIcon={<RequestApprovalIcon width={20} height={20} />}
                handleClick={handleExclusionApproval}
                customClasses="h-[34px] w-[160px] text-[14px] px-[0px]"
                isDisabled={isActionsDisabled}
              />
            )}
          </div>
        </div>
        {isParagraphIndexCorrect ? (
          <></>
        ) : deviation.paragraphIndex ? (
          <div className="border-[#9FD89F] border-b border-t bg-[#F1FAF1] text-[#242424] text-[12px] p-[12px] w-full flex items-center justify-center">
            Document has been edited since playbook was run, please rerun for correct predictions
          </div>
        ) : (
          <div className="border-[#9FD89F] border-b border-t bg-[#F1FAF1] text-[#242424] text-[12px] p-[12px] w-full flex items-center justify-center">
            Clause not found by AI, you can add the clause and rerun playbook
          </div>
        )}
        <div className="w-full h-[1px] bg-[#E6E6E6]"></div>
        {deviation.correction && deviation.status === "pending" && !isDeviationCompliant(deviation) ? (
          <>
            <div className="px-[0.25rem] inline-flex">
              <span className="text-[#616161] text-[0.75rem] font-[600] leading-[150%]">AI suggestion</span>
            </div>
            <div className="rounded-[0.25rem] border-[1px] border-solid border-[#E6E6E6] p-[1rem] flex flex-col justify-center gap-[1rem]">
              <DiffRenderer diffs={correctionTransformed} />
              <Button
                variant="secondary"
                label="Accept"
                rightIcon={<AcceptIcon />}
                handleClick={async () => {
                  await Word.run(async (context) => {
                    await diffReplace(context, deviation.docText, deviation.correction, deviation.paragraphIndex);
                  });
                  await handleMarkClause({
                    docText: deviation.correction,
                    isClauseCompliant: true,
                    status: "aiSuggestion",
                  });
                  await reloadWorkflowData();
                }}
                customClasses="w-fit flex justify-center items-center"
                isDisabled={isActionsDisabled}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      {standardPositions.length > 0 || fallbackPositions.length > 0 ? (
        <div className="p-2 flex flex-col gap-3">
          <div className="px-1">
            <span className="text-[#616161] text-[0.75rem] font-[600] leading-[1.125rem]">Recommended positions</span>
          </div>
          <div className="flex flex-col gap-2">
            {standardPositions[0] ? (
              <StandardPosition deviationId={deviationId} isActionsDisabled={isActionsDisabled} />
            ) : (
              <></>
            )}
            {fallbackPositions[0] ? (
              <FallbackPosition deviationId={deviationId} isActionsDisabled={isActionsDisabled} />
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      <Drawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <div className="flex flex-col gap-3">
          <div className="border-b border-[#E6E6E6 p-2">Actions</div>
          <div className="flex flex-col gap-3">
            {/* <button
              className="flex gap-3 items-center cursor-pointer hover:bg-[#F5F5F5] p-2"
              onClick={() => {
                setIsClauseReferenceDrawerOpen(true);
                setIsDrawerOpen(false);
              }}
              disabled={isActionsDisabled}
            >
              <ClauseReferenceIcon height={20} width={20} />
              <span className="text-[#333333] text-[14px] font-[400] leading-[1.125rem]">Change clause reference</span>
            </button> */}
            {deviation.isMet ? (
              <>
                <button
                  className="flex gap-3 items-center hover:bg-[#F5F5F5] p-2"
                  onClick={() => {
                    setIsNonCompliantModalOpen(true);
                  }}
                  disabled={isActionsDisabled}
                >
                  <NonCompliantIcon height={20} width={20} />
                  <span className="text-[#333333] text-[14px] font-[400] leading-[1.125rem]">
                    Mark as non-compliant
                  </span>
                </button>
                <button
                  className="flex gap-3 items-center cursor-pointer hover:bg-[#F5F5F5] p-2"
                  onClick={() => {
                    setIsRemovePlaybookTagModalOpen(true);
                  }}
                  disabled={isActionsDisabled}
                >
                  <TrashIcon height={20} width={20} />
                  <span className="text-[#333333] text-[14px] font-[400] leading-[1.125rem]">Remove playbook tag</span>
                </button>
              </>
            ) : (
              <button
                className="flex gap-3 items-center hover:bg-[#F5F5F5] p-2"
                onClick={async (e) => {
                  e.stopPropagation();
                  await handleExclusionApproval();
                }}
                disabled={isActionsDisabled}
              >
                <RequestApprovalIcon height={20} width={20} />
                <span className="text-[#333333] text-[14px] font-[400] leading-[1.125rem]">
                  Request approval for exclusion
                </span>
              </button>
            )}
          </div>
        </div>
      </Drawer>
      <Modal
        isOpen={isNonCompliantModalOpen}
        onClose={() => {
          setIsNonCompliantModalOpen(false);
          setIsDrawerOpen(true);
        }}
        title="Mark as non-compliant"
        primaryAction={{
          label: "Confirm",
          onClick: async () => {
            await handleMarkCompliance(false);
            await reloadWorkflowData();
            setIsNonCompliantModalOpen(false);
          },
        }}
        secondaryAction={{
          label: "Cancel",
          onClick: async () => {
            setIsNonCompliantModalOpen(false);
            setIsDrawerOpen(true);
          },
        }}
        stackButtons={false}
      >
        <p>
          This will change the compliance status of this clause to non-compliant. To show compliance, mark the right
          clause using the menu.
        </p>
      </Modal>
      <Modal
        isOpen={isRemovePlaybookTagModalOpen}
        onClose={() => {
          setIsRemovePlaybookTagModalOpen(false);
          setIsDrawerOpen(true);
        }}
        title="Remove tag"
        primaryAction={{
          label: "Confirm",
          onClick: async () => {
            await handleRemovePlaybookTag();
            await reloadWorkflowData();
            setIsRemovePlaybookTagModalOpen(false);
            navigateBack();
          },
        }}
        secondaryAction={{
          // label: "Mark different clause instead",
          label: "Cancel",
          onClick: async () => {
            setIsRemovePlaybookTagModalOpen(false);
            // setIsClauseReferenceDrawerOpen(true);
          },
        }}
        stackButtons={true}
      >
        <p>Removing the tag placed on this clause by the AI playbook will make it not compliant.</p>
      </Modal>
      <Drawer
        isOpen={isClauseReferenceDrawerOpen}
        onClose={() => setIsClauseReferenceDrawerOpen(false)}
        hideCloseButton
      >
        <div className="flex flex-col gap-3 p-4">
          <div className="flex flex-col gap-[4px]">
            <div className="text-[#333333] text-[18px] font-[600] leading-[1.125rem] pb-2">Change clause reference</div>
            <div className="text-[#616161] text-[14px] font-[400] leading-[1.125rem]">
              Highlight the text in the contract indicating the Limited Liability clause and save the selection here
            </div>
          </div>
          <div className="flex items-center justify-start bg-[#F5F5F5] rounded-[4px] p-2 w-full border-l-[2px] border-solid border-[#616161] ">
            <div
              className={`cursor-pointer text-[#616161] text-[14px] font-[400] leading-[1.125rem] px-3 ${
                isSelectedTextExpanded ? "" : "line-clamp-3"
              }`}
              onClick={() => setIsSelectedTextExpanded((prev) => !prev)}
            >
              {docText ? docText : "Selected text..."}
            </div>
          </div>
          {docText ? (
            <div className="flex items-center justify-start p-2">
              <div className="flex gap-1 items-center">
                <div className="flex items-center justify-center h-2 w-2 gap-3">
                  <Checkbox
                    checked={isClauseMarkedAsCompliant}
                    onChange={(e: any) => {
                      setIsClauseMarkedAsCompliant(e.target.checked);
                    }}
                  />
                </div>
                <div className="text-[#616161] text-[14px] font-[400] leading-[1.125rem] ml-3">
                  Also mark clause as compliant
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="flex items-center justify-end gap-2">
            <Button
              variant="secondary"
              label="Cancel"
              handleClick={async () => {
                setIsClauseReferenceDrawerOpen(false);
                setIsDrawerOpen(true);
              }}
              customClasses="w-fit"
            />
            <Button
              variant="primary"
              label="Save selection"
              handleClick={async () => {
                await handleMarkClause({ docText, isClauseCompliant: isClauseMarkedAsCompliant, status: "pending" });
                await reloadWorkflowData();
                setIsClauseReferenceDrawerOpen(false);
              }}
              customClasses="w-fit"
              isDisabled={!docText}
            />
          </div>
        </div>
      </Drawer>
    </div>
  );
}
