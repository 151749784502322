import React from "react";

function PlaybookRunningLoader({ playbooksLength }: { playbooksLength: number }): JSX.Element {
  return (
    <div className="flex flex-grow justify-center items-center">
      <div className="py-[2.5rem] px-[0.75rem] flex flex-col gap-[2.5rem]">
        <div className="px-[1rem] flex flex-col items-center gap-[1.5rem]">
          <span className="text-[#616161] text-[0.875rem] font-[400]">AI is scanning the document...</span>
          <div className="flex gap-[1rem] self-stretch items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="239" height="5" fill="none">
              <path stroke="#D9DBDD" strokeLinecap="round" strokeWidth={4} d="M2 2.5h235" />
              <path stroke="#16A962" strokeLinecap="round" strokeWidth={4} d="M2 2.5h101.554" />
            </svg>
            <span className="text-[#616161] text-[0.875rem] font-[600]">{`${Math.floor(
              playbooksLength / 2
            )}/${playbooksLength}`}</span>
          </div>
        </div>
        <div className="flex flex-col items-start gap-[0.5rem] self-stretch">
          {[100, 60, 20].map((opacity, i) => (
            <div
              className={`rounded-[0.25rem] shadow-md ${
                opacity === 100 ? "bg-[#FFF]" : opacity === 60 ? "bg-[#ffffff99]" : "bg-[#ffffff33]"
              } px-[1.25rem] py-[1.5rem] self-stretch flex flex-col gap-[1.5rem]`}
              key={i}
            >
              <div className="animate-pulse rounded-[0.125rem] bg-[#0f6cbd1f] w-[12.5rem] h-[0.5rem]"></div>
              <div className="animate-pulse rounded-[0.125rem] bg-[#0f6cbd1f] w-[5rem] h-[0.5rem]"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PlaybookRunningLoader;
