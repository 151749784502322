import joi from "joi";

export const conditionSchema = joi
  .object({
    name: joi.string(),
    conditions: joi.array().items(
      joi
        .object({
          fact: joi.string(),
          operator: joi.string(),
          value: joi.any(),
        })
        .options({ presence: "required" })
        .min(1)
    ),
  })
  .options({ presence: "required" });
