import React from "react";
import SelectionIndicatorIcon from "../icons/SelectionIndicatorIcon";

function TemplateNavBarItem({
  text,
  isSelected,
  onClick,
  icon,
}: {
  text: string;
  isSelected: boolean;
  // eslint-disable-next-line no-unused-vars
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  icon?: JSX.Element;
}): JSX.Element {
  return (
    <button
      className="p-[0.75rem_0.5rem] flex flex-col items-center justify-center gap-[0.375rem]"
      key={text}
      onClick={onClick}
    >
      <div className="flex flex-row">
        {icon ? icon : <></>}
        <div className="p-[0rem_0.125rem] flex items-start text-center">
          <span className="text-[#242424] text-[0.875rem] font-[600] leading-[1.25rem]">{text}</span>
        </div>
      </div>
      {isSelected ? <SelectionIndicatorIcon width="56" height="3" color="#0F6CBD" /> : <></>}
    </button>
  );
}

export default TemplateNavBarItem;
