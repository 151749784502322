import React, { SVGProps } from "react";

type TableIconSizes = "small" | "large";

const getIconForSize = (variant: TableIconSizes, fill: string | undefined): JSX.Element => {
  switch (variant) {
    case "small":
      return (
        <path
          fill={fill}
          d="M3 5.5C3 4.11929 4.11929 3 5.5 3H14.5C15.8807 3 17 4.11929 17 5.5V14.5C17 15.8807 15.8807 17 14.5 17H5.5C4.11929 17 3 15.8807 3 14.5V5.5ZM4 10.5V14.5C4 15.3284 4.67157 16 5.5 16H9.5V10.5H4ZM9.5 9.5V4H5.5C4.67157 4 4 4.67157 4 5.5V9.5H9.5ZM10.5 10.5V16H14.5C15.3284 16 16 15.3284 16 14.5V10.5H10.5ZM16 9.5V5.5C16 4.67157 15.3284 4 14.5 4H10.5V9.5H16Z"
        />
      );
    case "large":
      return (
        <path
          d="M0.700195 5.4502C0.700195 2.82684 2.82684 0.700195 5.4502 0.700195H22.5502C25.1735 0.700195 27.3002 2.82684 27.3002 5.4502V22.5502C27.3002 25.1735 25.1735 27.3002 22.5502 27.3002H5.4502C2.82684 27.3002 0.700195 25.1735 0.700195 22.5502V5.4502ZM2.6002 14.9502V22.5502C2.6002 24.1242 3.87618 25.4002 5.4502 25.4002H13.0502V14.9502H2.6002ZM13.0502 13.0502V2.6002H5.4502C3.87618 2.6002 2.6002 3.87618 2.6002 5.4502V13.0502H13.0502ZM14.9502 14.9502V25.4002H22.5502C24.1242 25.4002 25.4002 24.1242 25.4002 22.5502V14.9502H14.9502ZM25.4002 13.0502V5.4502C25.4002 3.87618 24.1242 2.6002 22.5502 2.6002H14.9502V13.0502H25.4002Z"
          fill={fill}
        />
      );
  }
};

const TableIcon = ({ size = "small", ...props }: SVGProps<SVGSVGElement> & { size?: TableIconSizes }): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width={props.width || 20}
      height={props.height || 20}
      {...props}
    >
      {getIconForSize(size, props.fill)}
    </svg>
  );
};

export default TableIcon;
