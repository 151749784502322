const getFileAsyncHandler = async (result: Office.AsyncResult<Office.File>): Promise<Uint8Array> => {
  if (result.status !== Office.AsyncResultStatus.Succeeded) {
    throw Error("Failed to get file");
  }
  const file = result.value;
  const sliceCount = file.sliceCount;
  const fileDataArray: number[][] = Array.from({ length: sliceCount }, () => []);
  for (let i = 0; i < fileDataArray.length; i++) {
    await new Promise((resolve, reject) => {
      file.getSliceAsync(i, (result: Office.AsyncResult<Office.Slice>) => {
        if (result.status !== Office.AsyncResultStatus.Succeeded) {
          reject(result.error.message);
          return;
        }
        fileDataArray[i] = result.value.data;
        resolve("");
      });
    });
  }
  file.closeAsync();
  let fileData: number[] = [];
  for (const data of fileDataArray) {
    fileData = fileData.concat(data);
  }
  const bytes = new Uint8Array(fileData.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = fileData[i];
  }
  return bytes;
};

export default getFileAsyncHandler;
