import React, { useEffect } from "react";

import { useNavigation } from "./features/Navigation/NavigationContext";
import FullPageSpinner from "./components/FullPageSpinner";
import TemplateNotFound from "./Pages/NotFound/TemplateNotFound";
import Fields from "./Pages/Fields";
import TagFieldPage from "./Pages/TagFieldPage";
import getWebDavPayload from "./utils/getWebDavPayload";
import changeTrackingMode from "./utils/wordApi/changeTrackingMode";
import Conditions from "./Pages/conditions";
import TagConditionPage from "./Pages/TagCondition";
import CreateField from "./Pages/CreateField";
import Tables from "./Pages/Tables";
import CreateCondition from "./Pages/CreateCondition";
import CreateTable from "./Pages/CreateTable";
import EditConditions from "./Pages/CreateCondition/EditConditions";
import EditField from "./Pages/CreateField/EditField";
import EditTable from "./Pages/CreateTable/EditTable";
import { useSSEFetch } from "./api/FetchSSEContext";

function TemplateRoutes(): JSX.Element {
  const selectedtab = useNavigation();
  const { templateId } = getWebDavPayload(Office?.context?.document?.url || "");
  const {
    data: templateResponse,
    isLoading: templateLoading,
    error: templateError,
    reload: templateReload,
  } = useSSEFetch(`template/sse/${templateId}`, {}, {});

  useEffect(() => {
    Word.run(async (context) => changeTrackingMode(context, "Off"));
  }, []);

  if (templateLoading) {
    return <FullPageSpinner />;
  }

  if (templateError) {
    return <div>Something went wrong, Please try again later</div>;
  }

  if (!templateId) {
    return <TemplateNotFound templateId={templateId} />;
  }

  if (!templateResponse.template) {
    return <FullPageSpinner />;
  }

  const tabURL = new URL(`http:hyperstart.com/${selectedtab}`);
  switch (tabURL.pathname) {
    case "/Fields":
      return <Fields tags={templateResponse.template.tags} templateId={templateId} reloadTags={templateReload} />;
    case "/Conditions":
      return <Conditions conditions={templateResponse.template.conditions} reloadConditions={templateReload} />;
    case "/Tables":
      return <Tables tags={templateResponse.template.tags} templateId={templateId} reloadTags={templateReload} />;
    case "/TagField":
      return (
        <TagFieldPage
          tags={templateResponse.template.tags}
          templateId={templateId}
          reloadTags={templateReload}
          url={tabURL}
        />
      );
    case "/TagCondition":
      return <TagConditionPage conditions={templateResponse.template.conditions} />;
    case "/CreateField":
      return <CreateField tags={templateResponse.template.tags} templateId={templateId} reloadTags={templateReload} />;
    case "/EditField":
      return (
        <EditField
          tags={templateResponse.template.tags}
          templateId={templateId}
          reloadTags={templateReload}
          url={tabURL}
        />
      );
    case "/CreateCondition":
      return (
        <CreateCondition
          tags={templateResponse.template.tags}
          templateId={templateId}
          reloadConditions={templateReload}
        />
      );
    case "/EditCondition":
      return (
        <EditConditions
          tags={templateResponse.template.tags}
          reloadConditions={templateReload}
          url={tabURL}
          conditionsData={templateResponse.template.conditions}
        />
      );
    case "/CreateTable":
      return (
        <CreateTable
          tags={templateResponse.template.tags}
          templateId={templateId}
          reloadTags={templateReload}
          url={tabURL}
        />
      );
    case "/EditTable":
      return (
        <EditTable
          tags={templateResponse.template.tags}
          templateId={templateId}
          reloadTags={templateReload}
          url={tabURL}
        />
      );
    default:
      return <div>not found</div>;
  }
}

export default TemplateRoutes;
