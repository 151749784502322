import React, { SVGProps } from "react";

const PostIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} fill={props.fill} {...props}>
    <path
      fill="#fff"
      d="M17.47 11.302 4.203 16.929a.848.848 0 0 1-.816-.075.8.8 0 0 1-.387-.718V4.864a.8.8 0 0 1 .387-.718.848.848 0 0 1 .816-.075L17.47 9.698c.353.157.53.424.53.802 0 .378-.177.645-.53.802Zm-13.044 3.98L15.69 10.5 4.426 5.719v3.53L9.58 10.5l-5.155 1.25v3.531Z"
    />
  </svg>
);

export default PostIcon;
