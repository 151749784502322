import React from "react";
import Author from "./Author";
import LineIcon from "../../icons/LineIcon";

export default function CommentRenderer({
  authorName,
  creationDate,
  text,
  rightMenu,
  isLastComment,
}: {
  authorName: string;
  creationDate: number;
  text: string;
  rightMenu?: JSX.Element;
  isLastComment: boolean;
}): JSX.Element {
  // reference for regex: https://stackoverflow.com/questions/60737672/email-regex-pattern-in-nodejs
  const mentionsRegex = /@[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/g;
  const textsWithoutMentions = text.split(mentionsRegex);
  const mentions = Array.from(text.match(mentionsRegex) || []);

  return (
    <div className="flex flex-col gap-[0.75rem] self-stretch">
      <div className="flex justify-between self-stretch">
        <Author user={{ firstName: authorName, lastName: "", imageUrl: "" }} creationDate={creationDate} />
        {rightMenu ? rightMenu : <></>}
      </div>
      <div>
        {textsWithoutMentions.map((textValue, i) => {
          if (i + 1 === textsWithoutMentions.length) {
            return (
              <span key={i} className="text-[#333333] text-[0.875rem] font-[400] leading-[150%]">
                {textValue}
              </span>
            );
          } else {
            return (
              <>
                <span key={i} className="text-[#333333] text-[0.875rem] font-[400] leading-[150%]">
                  {textValue}
                </span>
                <span
                  key={i.toString() + "_mention"}
                  className="text-[#0F6CBD] text-[0.875rem] font-[600] leading-[150%]"
                >
                  {mentions[i]}
                </span>
              </>
            );
          }
        })}
      </div>
      {isLastComment ? (
        <></>
      ) : (
        <div className="self-stretch h-0">
          <LineIcon stroke="#E0E0E0" height={"5px"} />
        </div>
      )}
    </div>
  );
}
