import replaceAll from "string.prototype.replaceall";

export default async function getAllContentControls(
  context: Word.RequestContext,
  availableTags: Array<any>
): Promise<Map<string, Array<any>>> {
  const tagsMap = new Map<string, Array<any>>();
  const contentControls = context.document.contentControls;
  if (!contentControls) {
    return tagsMap;
  }
  contentControls.load("items");
  await contentControls.context.sync();
  for (const contentControl of contentControls.items) {
    contentControl.load(["id", "text", "tag", "title"]);
  }
  await contentControls.context.sync();
  for (const contentControl of contentControls.items) {
    const currentTag = contentControl.tag;
    const text = contentControl.text;
    // figure out if second condition should be kept and whether we should insert content control by default.
    const isTag = Boolean(
      availableTags.find((tag) => tag._id === currentTag || `{{HS_FIELD_${replaceAll(tag.name, " ", "_")}}}` === text)
    );
    if (isTag) {
      const array = tagsMap.get(currentTag) || [];
      array.push({
        id: contentControl.id,
        text: contentControl.text,
        tag: contentControl.tag,
        title: contentControl.title,
      });
      tagsMap.set(currentTag, array);
    }
  }
  return tagsMap;
}
