import React, { createContext, useContext } from "react";
import { useFetch } from "../../api/FetchContext";
import FullPageSpinner from "../../components/FullPageSpinner";
import getWebDavPayload from "../../utils/getWebDavPayload";
import WorkflowNotFound from "../../Pages/Overview/WorkflowNotFound";
import { NavigationProvider } from "../Navigation/NavigationContext";

const initialData = {
  workflowResponse: {
    workflow: {},
  },
  documentVersionsResponse: { allDocuments: [] },
  clauseApprovalsResponse: { clauseApprovals: [] },
  playbooks: [],
  deviations: [],
};

const WorkflowDataContext = createContext<{
  data: {
    workflowResponse: { workflow: any };
    documentVersionsResponse: { allDocuments: Array<any> };
    clauseApprovalsResponse: { clauseApprovals: Array<any> };
    playbooks: Array<any>;
    deviations: Array<any>;
  };
  reload: () => Promise<void>;
  isRefetchingData: boolean;
}>({
  data: initialData,
  reload: async () => {
    return;
  },
  isRefetchingData: false,
});

export function WorkflowDataProvider({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element {
  const { workflowId } = getWebDavPayload(Office?.context?.document?.url || "");
  const {
    data,
    isLoading,
    error,
    reload,
    isRefetchingData,
  }: {
    data: {
      workflowResponse: { workflow: any };
      documentVersionsResponse: { allDocuments: Array<any> };
      clauseApprovalsResponse: { clauseApprovals: Array<any> };
      playbooks: Array<any>;
      deviations: Array<any>;
    };
    isLoading: boolean;
    error: any;
    reload: () => Promise<void>;
    isRefetchingData: boolean;
  } = useFetch(
    `bff/wordAddIn/workflow/${workflowId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    },
    initialData
  );

  // don't show spinner if reloading data
  // if (isLoading && !data.workflowResponse.workflow.name) {
  if (isLoading) {
    return (
      <NavigationProvider mode="Workflow">
        <FullPageSpinner />
      </NavigationProvider>
    );
  }

  if (error || !data.workflowResponse.workflow?.name) {
    return (
      <NavigationProvider mode="Workflow">
        <WorkflowNotFound workflowId={workflowId} />
      </NavigationProvider>
    );
  }

  return (
    <WorkflowDataContext.Provider value={{ data, reload, isRefetchingData }}>{children}</WorkflowDataContext.Provider>
  );
}

export function useWorkflowData(): {
  data: {
    workflowResponse: { workflow: any };
    documentVersionsResponse: { allDocuments: Array<any> };
    clauseApprovalsResponse: { clauseApprovals: Array<any> };
    playbooks: Array<any>;
    deviations: Array<any>;
  };
  reload: () => Promise<void>;
  isRefetchingData: boolean;
} {
  return useContext(WorkflowDataContext);
}
