import React from "react";
import WorkflowDetailsRow from "./WorkflowDetailsRow";

export default function WorkflowDetails({
  workflowName,
  workflowDetails,
}: {
  workflowName: string;
  workflowDetails: Array<{ key: string; value: string; valueIcon?: JSX.Element }>;
}): JSX.Element {
  return (
    <div className="p-[0.75rem] flex flex-col gap-[0.75rem] bg-[#FFF] rounded-[0.25rem] shadow-md">
      <span className="text-[#333333] font-[600]">{workflowName}</span>
      <div className="flex flex-col self-stretch rounded-[0.125rem] border-[1px] border-solid border-[#E9EDF5]">
        {workflowDetails.map(({ key, value, valueIcon }, i) => (
          <WorkflowDetailsRow
            key={key}
            keyItem={key}
            value={value}
            valueIcon={valueIcon}
            isLast={i === workflowDetails.length - 1}
          />
        ))}
      </div>
    </div>
  );
}
