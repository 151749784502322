import React, { createContext, useContext } from "react";
import { User } from "../../Pages/Comments/interface";
import { useFetch } from "../../api/FetchContext";
import FullPageSpinner from "../../components/FullPageSpinner";

const UsersContext = createContext<{
  data: { users: Array<User>; groups: Array<any>; orgResponse: { organisation: any } };
  reload: () => Promise<void>;
}>({
  data: { users: [], groups: [], orgResponse: { organisation: {} } },
  reload: async () => {
    return;
  },
});

export function OrgDataProvider({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element {
  const {
    data,
    isLoading,
    reload,
  }: {
    data: { users: Array<User>; groups: Array<any>; orgResponse: { organisation: any } };
    isLoading: boolean;
    reload: () => Promise<void>;
  } = useFetch(
    "bff/wordAddIn/getOrgData",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    },
    []
  );

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return <UsersContext.Provider value={{ data, reload }}>{children}</UsersContext.Provider>;
}

export function useOrgData(): {
  data: { users: Array<User>; groups: Array<any>; orgResponse: { organisation: any } };
  reload: () => Promise<void>;
} {
  return useContext(UsersContext);
}
