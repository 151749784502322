import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useDetectClickOutside } from "react-detect-click-outside";

import KebabMenuIcon from "../../icons/KebabMenuIcon";
import OpenInBrowserIcon from "../../icons/OpenInBrowserIcon";
import ArrowExitIcon from "../../icons/ArrowExitIcon";
import { WEB_APP_URL } from "../../constants";

export default function Menu({ workflowId }: { workflowId: string }): JSX.Element {
  const [isDropDownOpen, setDropDownOpen] = useState<boolean>(false);
  const { logout } = useAuth0();
  const ref = useDetectClickOutside({ onTriggered: () => setDropDownOpen(false) });
  return (
    <div className="relative p-[0.75rem] flex flex-row justify-end" ref={ref}>
      <KebabMenuIcon className="cursor-pointer" onClick={() => setDropDownOpen((prev) => !prev)} />
      {isDropDownOpen ? (
        <div className="absolute top-[1.5rem] bg-[#FFF] py-[0.5rem] shadow-sm rounded-[0.25rem] border-[1px] border-solid border-[#D9DBDD] inline-flex flex-col gap-[0.25rem]">
          <div
            className="cursor-pointer py-[0.25rem] px-[0.5rem] flex gap-[0.5rem]"
            onClick={(e) => {
              e.stopPropagation();
              if (workflowId) {
                Office.context.ui.openBrowserWindow(`${WEB_APP_URL}/activity/${workflowId}`);
              } else {
                Office.context.ui.openBrowserWindow(`${WEB_APP_URL}/workflow`);
              }
            }}
          >
            <OpenInBrowserIcon width="1.25rem" height="1.25rem" fill="#333" />
            <span className="text-[#333333] text-[0.875rem] leading-[1.25rem] whitespace-nowrap">
              Open in HyperStart
            </span>
          </div>
          <div
            className="cursor-pointer py-[0.25rem] px-[0.5rem] flex gap-[0.5rem]"
            onClick={() => {
              logout({ logoutParams: { returnTo: `${window.location.origin}/taskpane.html` } });
              sessionStorage.clear();
            }}
          >
            <ArrowExitIcon width="1.25rem" height="1.25rem" fill="#C83532" />
            <span className="text-[#C83532] text-[0.875rem] leading-[1.25rem]">Logout</span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
