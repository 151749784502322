import React from "react";
import NavBarItem from "./NavBarItem";
import { Mode, NavigationTabs } from "../features/Navigation/NavigationContext";
import HeadlessDropDown from "./HeadlessDropDown";
import GroupListIcon from "../icons/GroupListIcon";
import BranchIcon from "../icons/BranchIcon";
import TableIcon from "../icons/TableIcon";
import PlusIcon from "../icons/PlusIcon";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutIcon from "../icons/LogoutIcon";
import NavBarHamburgerMenu from "./NavBarHamburgerMenu";
import TemplateNavBarItem from "./TemplateNavBarItem";

export type NavBarItemProps = {
  text: NavigationTabs;
  icon?: JSX.Element;
};

function NavBar({
  mode,
  tabs,
  selectedTab,
  setSelectedTab,
}: {
  mode: Mode;
  tabs: Array<NavBarItemProps>;
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<NavigationTabs>>;
}): JSX.Element {
  const { logout } = useAuth0();

  switch (mode) {
    case "Workflow":
      if ("Create Clause Approval" === selectedTab) {
        return <></>;
      }
      return (
        <div className="flex items-center h-[40px] border-b-[1px] border-solid border-b-[#E0E0E0] bg-[#FFFFFF]">
          <NavBarHamburgerMenu setSelectedTab={setSelectedTab} />
          {tabs.map((tab) => (
            <NavBarItem
              text={tab.text}
              isSelected={selectedTab === tab.text}
              key={tab.text}
              onClick={() => {
                setSelectedTab(tab.text);
              }}
              icon={tab.icon}
            />
          ))}
        </div>
      );
    case "Template": {
      if (["CreateField", "CreateCondition", "TagField", "TagCondition", "CreateTable"].includes(selectedTab)) {
        return <></>;
      }
      const optionsClasses = "px-[0.25rem] py-[0.75rem] flex gap-[0.5rem] hover:bg-[#F5F5F5]";
      return (
        <div className="pr-[20px] flex items-center justify-between border-b-[1px] border-solid border-b-[#E0E0E0] bg-[#FFFFFF00]">
          <div className="flex items-start">
            {tabs.map((tab) => (
              <TemplateNavBarItem
                text={tab.text}
                isSelected={selectedTab === tab.text}
                key={tab.text}
                onClick={() => {
                  setSelectedTab(tab.text);
                }}
                icon={tab.icon}
              />
            ))}
          </div>
          <div
            className="rounded-[0.25rem] border-[1px] border-solid border-[#B3B3B3] py-[0.3125rem] px-[0.5rem] flex gap-[0.25rem] bg-[#FFF] cursor-pointer"
            onClick={async () =>
              await logout({ logoutParams: { returnTo: `${window.location.origin}/taskpane.html` } })
            }
          >
            <span className="text-[0.875rem] text-[#242424] font-[600] leading-[1.25rem]">
              <LogoutIcon color="#000" />
            </span>
          </div>
          {
            <HeadlessDropDown
              selectElement={
                <div className="rounded-[0.25rem] border-[1px] border-solid border-[#B3B3B3] py-[0.3125rem] px-[0.5rem] flex gap-[0.25rem] bg-[#FFF]">
                  <span className="text-[0.875rem] text-[#242424] font-[600] leading-[1.25rem]">
                    <PlusIcon stroke="#000" />
                  </span>
                </div>
              }
              customContainerClasses="active:bg-[#E0E0E0] focus:bg-[#E0E0E0] focus-within:bg-[#E0E0E0]"
              customOptionsContainerClasses="rounded-[0.25rem] shadow-md border-[1px] border-solid border-[#D9DBDD] bg-[#FFF] right-0"
              options={[
                {
                  children: (
                    <>
                      <GroupListIcon fill="#212121" />
                      <span className="text-[#333333] text-[0.875rem] leading-[1.25rem] whitespace-nowrap">
                        Create Field
                      </span>
                    </>
                  ),
                  clickHandler: async () => setSelectedTab("CreateField"),
                  customClasses: `${optionsClasses}`,
                },
                {
                  children: (
                    <>
                      <TableIcon fill="#212121" />
                      <span className="text-[#333333] text-[0.875rem] leading-[1.25rem] whitespace-nowrap">
                        Create Table
                      </span>
                    </>
                  ),
                  clickHandler: async () => setSelectedTab("CreateTable"),
                  customClasses: `${optionsClasses}`,
                },
                {
                  children: (
                    <>
                      <BranchIcon fill="#212121" />
                      <span className="text-[#333333] text-[0.875rem] leading-[1.25rem] whitespace-nowrap">
                        Create Condition
                      </span>
                    </>
                  ),
                  clickHandler: async () => setSelectedTab("CreateCondition"),
                  customClasses: `${optionsClasses}`,
                },
              ]}
            />
          }
        </div>
      );
    }
    default:
      return <></>;
  }
}

export default NavBar;
