import replaceAll from "string.prototype.replaceall";

import { User } from "../Pages/Comments/interface";

export default function convertMentionText(users: Array<User>, text: string): string {
  let transformedText = text;
  for (const user of users) {
    transformedText = replaceAll(transformedText, `@(${user.email})`, `@${user.email}`);
  }
  return transformedText;
}
