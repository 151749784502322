import React from "react";
import Button from "../../components/Button";
import PlusIcon from "../../icons/PlusIcon";
import { useNavigationDispatch } from "../../features/Navigation/NavigationContext";
import BranchIcon from "../../icons/BranchIcon";

const NotFoundPage = () => {
  const setSelectedTab = useNavigationDispatch();
  return (
    <div className="flex flex-col items-center justify-center h-full text-center gap-4 px-5 bg-[#FAFAFA]">
      <div className="flex flex-col items-center justify-center gap-2">
        <BranchIcon fill="#242424" width={28} height={32} size="large" />
        <h1 className="text-[1rem] font-[600]">Use logical conditions</h1>
        <p className="text-[1rem] font-[400]">Define trigger for decisions and contract content using conditions.</p>
      </div>
      <Button
        variant="secondary"
        label="Create new condition"
        handleClick={async () => {
          if (setSelectedTab) {
            setSelectedTab("CreateCondition");
          }
        }}
        leftIcon={<PlusIcon stroke="#242424" />}
      />
    </div>
  );
};

export default NotFoundPage;
