import React, { useContext, useState } from "react";
import { Dropdown, Option, FluentProvider, webLightTheme } from "@fluentui/react-components";

import HeadlessDropDown from "../../components/HeadlessDropDown";
import AddPersonIcon from "../../icons/AddPersonIcon";
import { User } from "../Comments/interface";
import PeopleIcon from "../../icons/PeopleIcon";
import { ClauseApproverAssigned, ClauseType } from "./interface";
import Button from "../../components/Button";
import Avatar from "./Avatar";
import SelectedCard from "./SelectedCard";
import { useNavigationDispatch } from "../../features/Navigation/NavigationContext";
import { FetchContext } from "../../api/FetchContext";
import { BACKEND_URL, bgColorOptions } from "../../constants";
import { useNotificationToastDispatch } from "../../features/NotificationToast/NotificationContext";
import findCommentById from "../../utils/wordApi/findCommentById";
import useDocumentSelection from "../../hooks/useDocumentSelection";
import { useOrgData } from "../../features/OrgDataProvider/OrgDataProvider";

export default function RequestApproval({ workflowId }: { workflowId: string }): JSX.Element {
  const setSelectedTab = useNavigationDispatch();
  const fetcher = useContext(FetchContext);
  const notificationDispatch = useNotificationToastDispatch();

  const { data: orgData } = useOrgData();
  const { users, groups } = orgData;

  const [shouldShowFullClause, setShouldShowFullClause] = useState<boolean>(false);
  const { docText } = useDocumentSelection();
  const [approvers, setApprovers] = useState<Array<ClauseApproverAssigned>>([]);
  const [description, setDescription] = useState<string>("");
  const [clauseType, setClauseType] = useState<ClauseType>(ClauseType.governingLaw);

  const clauseTypes = Object.values(ClauseType);

  const isSubmitDisabled = approvers.length === 0 || !description || !docText;

  const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (fetcher) {
      const commentId = await Word.run(async (context): Promise<string> => {
        const doc = context.document;
        const body = context.document.body;
        body.load(["text"]);
        await context.sync();
        const originalRange = doc.getSelection();
        const comment = originalRange.insertComment(`(Internal) (approval) ${description}`);
        comment.load(["id"]);
        await context.sync();
        return comment.id;
      });
      const res = await fetcher(`${BACKEND_URL}/api/v1/clauseApproval/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          workflowId,
          docText,
          description,
          approvers,
          commentId,
          clauseType,
        }),
      }).catch(async (e: unknown) => {
        if (notificationDispatch && e instanceof Error) {
          notificationDispatch({ type: "error", message: e.message || "Something went wrong" });
        }
        await Word.run(async (context): Promise<void> => {
          const commentContext = await findCommentById(context, commentId);
          if (commentContext) {
            commentContext.delete();
            await context.sync();
          }
        });
      });

      if (res?.status === "success" && setSelectedTab) {
        setSelectedTab("Clause Approvals");
      }
    }
  };

  return (
    <form className="bg-[#FAFAFA] px-[1rem] h-full overflow-y-auto flex flex-col justify-between">
      <div className="flex flex-col gap-[1rem]">
        <div className="pt-[0.625rem]">
          <span className="text-[#171C26] text-[1.25rem] font-[600] leading-[2rem]">Request approval</span>
        </div>
        <div className="flex flex-col gap-[1.5rem]">
          <div className="p-[0.5rem] rounded-[0.5rem] bg-[#F0F0F0] flex flex-col gap-[0.5rem] self-stretch">
            <div className="px-[0.25rem]">
              <span className="text-[#333] text-[0.75rem]">Clause in this contract</span>
            </div>
            <div
              className="p-[0.75rem] rounded-[0.25rem] border-l-[1px] border-l-solid border-l-[#838383] bg-[#FFF] self-stretch"
              onClick={async () => {
                if (docText) {
                  setShouldShowFullClause((prev) => !prev);
                }
                // await getSelectionFromDocument();
              }}
            >
              {docText ? (
                <span
                  className={`cursor-pointer text-[#616161] text-[0.875rem] leading-[1.25rem] ${
                    shouldShowFullClause ? "" : "line-clamp-2"
                  }`}
                >
                  {docText}
                </span>
              ) : (
                <div className="animate-pulse bg-slate-200 h-4 w-full"></div>
              )}
            </div>
          </div>
          <div>Clause Type</div>
          <FluentProvider theme={webLightTheme} style={{ background: "#FAFAFA" }}>
            <Dropdown
              placeholder="Clause Type"
              value={clauseType}
              onOptionSelect={(_e, data) => {
                if (data.optionText) {
                  setClauseType(data.optionText as ClauseType);
                }
              }}
            >
              {clauseTypes.map((option: string, i) => {
                return <Option key={i}>{option}</Option>;
              })}
            </Dropdown>
          </FluentProvider>
          <div className="flex flex-col self-stretch gap-[0.5rem]">
            <div className="flex flex-col self-stretch gap-[0.25rem]">
              <span className="text-[#242424] text-[0.875rem] leading-[1.25rem]">Approver</span>
              <span className="text-[#616161] text-[0.75rem] leading-[1.05rem]">Who should review this clause?</span>
            </div>
            <div className="rounded-[0.25rem]">
              <HeadlessDropDown
                selectElement={
                  <div className="rounded-[0.25rem] border-[1px] border-solid border-[#D1D1D1] bg-[#FFF] px-[0.5rem] flex self-stretch items-center gap-[0.25rem]">
                    {approvers.length === 0 ? (
                      <>
                        <AddPersonIcon fill="#707070" />
                        <div className="p-[0.3125rem_0.125rem_0.4375rem_0.125rem]">
                          <span className="text-[#707070] text-[0.875rem] leading-[1.25rem]">Select multiple</span>
                        </div>
                      </>
                    ) : (
                      <div className="flex flex-wrap">
                        {approvers.map((approver, i) => {
                          switch (approver.type) {
                            case "user": {
                              const user = users.find((user) => user._id === approver.approverId) as User;
                              return (
                                <SelectedCard
                                  key={approver.approverId}
                                  avatarBgColor={bgColorOptions[i % bgColorOptions.length]}
                                  name={user.firstName}
                                  shouldShowGroupIcon={false}
                                  onRemove={async () => {
                                    setApprovers((prev) => {
                                      const newApprovers = JSON.parse(JSON.stringify(prev));
                                      newApprovers.splice(i, 1);
                                      return newApprovers;
                                    });
                                  }}
                                />
                              );
                            }
                            case "group": {
                              const group = groups.find((group) => group._id === approver.approverId);
                              return (
                                <SelectedCard
                                  key={approver.approverId}
                                  avatarBgColor={bgColorOptions[i % bgColorOptions.length]}
                                  name={group.name}
                                  shouldShowGroupIcon={true}
                                  onRemove={async () => {
                                    setApprovers((prev) => {
                                      const newApprovers = JSON.parse(JSON.stringify(prev));
                                      newApprovers.splice(i, 1);
                                      return newApprovers;
                                    });
                                  }}
                                />
                              );
                            }
                          }
                        })}
                      </div>
                    )}
                  </div>
                }
                options={users
                  .filter((user) => !approvers.find((approver) => approver.approverId === user._id))
                  .map((user, i) => ({
                    children: (
                      <>
                        <Avatar bgColor={bgColorOptions[i % bgColorOptions.length]} name={user.firstName} />
                        <div className="flex flex-col items-start">
                          <span className="text-[#333] text-[0.875rem] font-[600] leading-[1.25rem]">{`${user.firstName} ${user.lastName}`}</span>
                          <span className="text-[#616161] text-[0.75rem] leading-[1.25rem]">
                            {groups.find((group) => group._id === user.groups[0])?.name || ""}
                          </span>
                        </div>
                      </>
                    ),
                    clickHandler: async () => {
                      setApprovers((prev) => {
                        const newApprovers = JSON.parse(JSON.stringify(prev));
                        newApprovers.push({ type: "user", approverId: user._id });
                        return newApprovers;
                      });
                    },
                    customClasses: `p-[0.5rem] flex self-stretch gap-[0.75rem] hover:bg-[#F5F5F5]`,
                  }))
                  .concat(
                    groups
                      .filter((group) => !approvers.find((approver) => approver.approverId === group._id))
                      .map((group, i) => ({
                        children: (
                          <>
                            <Avatar bgColor={bgColorOptions[i % bgColorOptions.length]} name={group.name} />
                            <span className="text-[#333] text-[0.875rem] font-[600] leading-[1.25rem]">
                              {group.name}
                            </span>
                            <PeopleIcon fill="#838383" />
                          </>
                        ),
                        clickHandler: async () => {
                          setApprovers((prev) => {
                            const newApprovers = JSON.parse(JSON.stringify(prev));
                            newApprovers.push({
                              type: "group",
                              approverId: group._id,
                            });
                            return newApprovers;
                          });
                        },
                        customClasses: `p-[0.5rem] flex self-stretch gap-[0.75rem] hover:bg-[#F5F5F5]`,
                      }))
                  )}
                customContainerClasses="shrink-0 w-full"
                customOptionsContainerClasses="w-full bg-[#FFF] top-[2.4rem] p-[0.5rem] shadow-md rounded-[0.25rem] border-[1px] border-solid border-[#D9DBDD] gap-[0.25rem]"
              />
            </div>
          </div>
          <div className="flex flex-col self-stretch gap-[0.5rem]">
            <label className="flex flex-col self-stretch gap-[0.25rem]" htmlFor="additional comments">
              <span className="text-[#333] text-[0.875rem] leading-[1.25rem]">Additional comments</span>
              <span className="text-[#616161] text-[0.75rem] leading-[1.05rem]">
                Share a bit of context to help the approver make a well-informed decision about this clause
              </span>
            </label>
            <textarea title="additional comments" onChange={(e) => setDescription(e.target.value)}>
              {description}
            </textarea>
          </div>
        </div>
      </div>
      <div className="p-[1rem] flex gap-[0.5rem] bg-[#fff]">
        <Button
          buttonType="submit"
          variant="primary"
          label="Send request"
          handleClick={handleSubmit}
          isDisabled={isSubmitDisabled}
        />
        <Button
          variant="secondary"
          label="Cancel"
          handleClick={async () => {
            if (setSelectedTab) {
              setSelectedTab("Clause Approvals");
            }
          }}
        />
      </div>
    </form>
  );
}
