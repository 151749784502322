import React from "react";

import CloseActionIcon from "../../icons/CloseActionIcon";
import Avatar from "./Avatar";
import Button from "../../components/Button";
import PeopleIcon from "../../icons/PeopleIcon";

export default function SelectedCard({
  avatarBgColor,
  name,
  shouldShowGroupIcon,
  onRemove,
}: {
  avatarBgColor: string;
  name: string;
  shouldShowGroupIcon: boolean;
  onRemove: () => Promise<void>;
}): JSX.Element {
  return (
    <div className="rounded-[7.5rem] border-[1px] border-solid border-[#F2F2F2] bg-[#F8F8FA] p-[0.25rem] flex gap-[0.5rem]">
      <div className="flex gap-[0.25rem]">
        <Avatar bgColor={avatarBgColor} name={name} />
        <div className="self-stretch flex items-center justify-center">
          <span className="text-[#333] text-[0.75rem] font-[600] leading-[1.25rem]">{name}</span>
        </div>
        {shouldShowGroupIcon ? <PeopleIcon fill="#A5A7AB" /> : <></>}
      </div>
      <Button
        variant="secondary"
        leftIcon={<CloseActionIcon fill="#838383" />}
        label=""
        handleClick={onRemove}
        customClasses="!bg-[#F8F8FA] border-none"
      />
    </div>
  );
}
