import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import Layout, { AppProps } from "./Layout";
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from "./constants";
import { NotificationToastProvider } from "./features/NotificationToast/NotificationContext";

export default function App(props: AppProps): JSX.Element {
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: `${window.location.origin}/taskpane.html`,
        audience: "https://clm.dev.hyperverge",
        scope: "openid profile email offline_access",
      }}
      onRedirectCallback={(appState) => {
        window.location.href = appState && appState.returnTo ? appState.returnTo : window.location.pathname;
      }}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
    >
      <NotificationToastProvider>
        <Layout {...props} />
      </NotificationToastProvider>
    </Auth0Provider>
  );
}
