import React from "react";
import TickIcon from "../../icons/TickIcon";

function AppDescriptionList({ description }: { description: string }): JSX.Element {
  return (
    <div className="flex items-start gap-[0.75rem]">
      <div className="rounded-[6.1875rem] bg-[#FFFFFF]">
        <TickIcon width="21" height="21" fillColor="#13A10E" />
      </div>
      <span className="text-[#333333] font-normal text-center">{description}</span>
    </div>
  );
}

export default AppDescriptionList;
