import React, { useState } from "react";
import LoadingSpinner from "./LoadingSpinner";
import { useNotificationToastDispatch } from "../features/NotificationToast/NotificationContext";

export type ButtonType = "button" | "submit" | "reset";

function HeadlessButton({
  handleClick,
  isDisabled,
  type,
  customClasses,
  children,
}: {
  // eslint-disable-next-line no-unused-vars
  handleClick: (e: React.MouseEvent<HTMLElement>) => Promise<void>;
  children: JSX.Element | JSX.Element[];
  type?: ButtonType;
  isDisabled?: boolean;
  customClasses?: string;
}): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const notificationDispatch = useNotificationToastDispatch();

  const clickHandler = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setIsLoading(true);
    try {
      await handleClick(e);
    } catch (err: unknown) {
      if (notificationDispatch && err instanceof Error) {
        console.error(err);
        notificationDispatch({ type: "error", message: err.message });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      type={type ? type : "button"}
      className={`cursor-pointer disabled:cursor-not-allowed ${customClasses ? customClasses : ""}`}
      onClick={clickHandler}
      disabled={isLoading || isDisabled}
    >
      {isLoading ? <LoadingSpinner shade="Light" size="Small" /> : <></>}
      {children}
    </button>
  );
}

export default HeadlessButton;
