import React from "react";
import { DirectionalHint, TooltipHost } from "@fluentui/react";

export default function Avatar({ bgColor, name }: { bgColor: string; name: string }): JSX.Element {
  /** NOTE:- bg color not working with tailwind due to a weird bug in it being in runtime, so moved to style as well.
   * reference:- https://stackoverflow.com/questions/68728360/tailwind-css-doesnt-apply-custom-background-color
   * */
  const className = `rounded-full bg-[${bgColor}] w-[1.75rem] h-[1.75rem] flex justify-center items-center`;
  const letter = name[0];
  return (
    <TooltipHost
      content={<span className="text-[#FFF] text-[0.75rem]">{name}</span>}
      directionalHint={DirectionalHint.bottomCenter}
      tooltipProps={{
        calloutProps: {
          styles: {
            beak: { background: "#333333" },
            beakCurtain: { background: "#333333" },
            calloutMain: { background: "#333333" },
          },
        },
      }}
    >
      <div className={className} style={{ backgroundColor: bgColor }}>
        <span className="text-[#fff] text-[0.75rem]">{letter}</span>
      </div>
    </TooltipHost>
  );
}
