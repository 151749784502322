import React from "react";
import Dropdown from "../../components/Dropdown";
import HeadlessButton from "../../components/HeadlessButton";
import DeleteIcon from "../../icons/DeleteIcon";
import { FormattedJoiErrors } from "../../utils/formatJoiErrors";
import RenderErrors from "../../components/RenderErrors";
import FieldTypes from "../CreateField/FieldTypes";
import { IConditionOption, IConditionType } from "./interface";

const ConditionBuilder = ({
  conditions,
  setConditions,
  tags,
  conditionType,
  errors,
}: {
  conditions: IConditionOption[];
  setConditions: React.Dispatch<React.SetStateAction<IConditionOption[]>>;
  tags: any[];
  conditionType: IConditionType;
  errors: FormattedJoiErrors | null;
}) => {
  const relationshipOptions = [
    { label: "Is Equal To", value: "equal" },
    { label: "Is Not Equal To", value: "notEqual" },
  ];

  const relationshipOptionsNumber = [
    { label: "Is Equal To", value: "equal" },
    { label: "Greater than or equal to", value: "greaterThanInclusive" },
    { label: "Less than or equal to", value: "lessThanInclusive" },
  ];

  const getFieldOptions = (tagId: string): any => {
    const tag = tags.find((tag) => tag._id === tagId);
    switch (tag?.type) {
      case "number":
        return relationshipOptionsNumber;
      default:
        relationshipOptions;
    }

    return relationshipOptions;
  };

  const EXCLUDED_FIELDS = ["table", "file", "address", "duration", "currency", "date"];

  const handleDeleteCondition = async (index: number) => {
    setConditions((prev) => {
      if (conditions.length == 1) {
        return [
          {
            field: "",
            relationship: "",
            value: "",
          },
        ];
      }
      const newConditions = JSON.parse(JSON.stringify(prev));
      newConditions.splice(index, 1);
      return newConditions;
    });
  };
  return (
    <>
      {conditions.map((condition: IConditionOption, index: number) => (
        <>
          <div className="flex flex-col gap-[0.5rem] px-[1rem] py-[1rem] bg-[#F0F0F0] rounded-[0.25rem]">
            <div className="flex justify-between">
              <span className="leading-[2rem] text-[1rem]">Rule {index + 1}</span>
              <HeadlessButton handleClick={async () => await handleDeleteCondition(index)}>
                <DeleteIcon color="#B10E1C" />
              </HeadlessButton>
            </div>
            <div className="flex flex-col gap-[0.5rem]">
              <div className="flex gap-[0.25rem]">
                <span className="text-[0.875rem] leading-[1.25rem]">Field</span>
                <span className="text-[#B10E1C] text-[0.875rem] leading-[1.25rem]">*</span>
              </div>
              <Dropdown
                width="w-full"
                option={condition.field.label}
                setOption={(value) => {
                  setConditions((prev) => {
                    const newConditions = JSON.parse(JSON.stringify(prev));
                    newConditions[index].field = value;
                    newConditions[index].value = "";
                    newConditions[index].relationship = "";
                    return newConditions;
                  });
                }}
                options={tags
                  .filter((tag) => !EXCLUDED_FIELDS.includes(tag.type))
                  .map((tag) => ({
                    label: tag.name,
                    value: tag._id,
                  }))}
              />
              <RenderErrors errors={errors} fieldName={`conditions.${index}.field`} />
            </div>
            <div className="flex flex-col gap-[0.5rem]">
              <div className="flex gap-[0.25rem]">
                <span className="text-[0.875rem] leading-[1.25rem]">Relationship</span>
                <span className="text-[#B10E1C] text-[0.875rem] leading-[1.25rem]">*</span>
              </div>
              <Dropdown
                width="w-full"
                option={condition.relationship.label}
                setOption={(value) => {
                  setConditions((prev) => {
                    const newConditions = JSON.parse(JSON.stringify(prev));
                    newConditions[index].relationship = value;
                    return newConditions;
                  });
                }}
                options={getFieldOptions(condition.field.value)}
              />
              <RenderErrors errors={errors} fieldName={`conditions.${index}.relationship`} />
            </div>
            <div className="flex flex-col gap-[0.5rem]">
              <div className="flex gap-[0.25rem]">
                <span className="text-[0.875rem] leading-[1.25rem]">Value</span>
                <span className="text-[#B10E1C] text-[0.875rem] leading-[1.25rem]">*</span>
              </div>
              <FieldTypes
                defaultValue={condition.value}
                setDefaultValue={(value) => {
                  setConditions((prev) => {
                    const newConditions = JSON.parse(JSON.stringify(prev));
                    newConditions[index].value = value;
                    return newConditions;
                  });
                }}
                inputType={tags.find((tag) => tag._id === condition.field.value)?.type || "text"}
                options={tags.find((tag) => tag._id === condition.field.value)?.options || []}
              />
              <RenderErrors errors={errors} fieldName={`conditions.${index}.value`} />
            </div>
          </div>
          {conditions.length > 1 && index < conditions.length - 1 && (
            <div className="flex justify-center align-center">
              <span className="text-[0.875rem] leading-[1.25rem]">{conditionType}</span>
            </div>
          )}
        </>
      ))}
    </>
  );
};

export default ConditionBuilder;
