import { BACKEND_URL } from "../../constants";

export const insertPositionApi = async ({
  fetcher,
  workflowId,
  deviationId,
  commentId,
  positionId,
  type,
}: {
  // eslint-disable-next-line no-unused-vars
  fetcher: ((url: string, config: RequestInit) => Promise<any>) | null;
  workflowId: string;
  deviationId: string;
  commentId: string;
  positionId?: string;
  type: "exclusion" | "standard" | "fallback";
}): Promise<void> => {
  if (!fetcher) {
    return;
  }
  await fetcher(`${BACKEND_URL}/api/v1/deviation/usePosition`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      workflowId,
      deviationId,
      commentId,
      positionId,
      type,
    }),
  });
};
