import React, { useContext, useState } from "react";
import BranchIcon from "../../icons/BranchIcon";
import HeadlessDropDown from "../../components/HeadlessDropDown";
import KebabMenuIcon from "../../icons/KebabMenuIcon";
import EditIcon from "../../icons/Editcon";
import { useNavigationDispatch } from "../../features/Navigation/NavigationContext";
import DeleteIcon from "../../icons/DeleteIcon";
import { FetchContext } from "../../api/FetchContext";
import { useNotificationToastDispatch } from "../../features/NotificationToast/NotificationContext";
import { BACKEND_URL } from "../../constants";

export default function RenderCondition({
  condition,
  contentControlProp,
  reloadConditions,
}: {
  condition: any;
  contentControlProp: Array<any>;
  reloadConditions: () => void;
}): JSX.Element {
  const [indexToScrollTo, setIndexToScrollTo] = useState<number>(0);
  const setSelectedTab = useNavigationDispatch();
  const fetcher = useContext(FetchContext);
  const notificationDispatch = useNotificationToastDispatch();

  const handleDeleteCondition = async (conditionId: string) => {
    if (fetcher && notificationDispatch) {
      await fetcher(`${BACKEND_URL}/api/v1/condition/deleteCondition/${conditionId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).catch((e) => {
        if (e instanceof Error) {
          notificationDispatch({ type: "error", message: e.message });
        }
        return e;
      });
    }
    if (reloadConditions) {
      reloadConditions();
    }
  };

  return (
    <div
      className={`py-[0.5rem] px-[0.25rem] flex hover:bg-[#FAFAFA] justify-between items-center ${
        contentControlProp.length == 0 ? "cursor-pointer active:bg-[#E0E0E0]" : "cursor-not-allowed"
      }`}
      onClick={async () => {
        if (contentControlProp.length > 0) {
          await Word.run(async (context) => {
            const contentControlId = contentControlProp[indexToScrollTo].id;
            const contentControl = context.document.contentControls.getById(contentControlId);
            await contentControl.context.sync();
            contentControl.getRange().select();
            setIndexToScrollTo((prev) => (prev + 1) % contentControlProp.length);
          });
        }
      }}
    >
      <div className="flex flex-row gap-[0.5rem] justify-between w-full items-center">
        <div className="flex flex-row gap-[0.5rem] items-center justify-cent">
          <BranchIcon fill="#212121" />
          <span className="text-[0.875rem] leading-[1.25rem]">{condition.name}</span>
        </div>
        <HeadlessDropDown
          selectElement={
            <div className="w-[2rem] h-[2rem] flex items-center justify-center">
              <KebabMenuIcon />
            </div>
          }
          customContainerClasses="active:bg-[#E0E0E0] focus:bg-[#E0E0E0] focus-within:bg-[#E0E0E0]"
          customOptionsContainerClasses="rounded-[0.25rem] shadow-md border-[1px] border-solid border-[#D9DBDD] bg-[#FFF] right-[calc(100%-32px)] w-[10rem]"
          options={[
            {
              children: (
                <div className="flex flex-row gap-[0.5rem] items-center py-[0.5rem] px-[0.75rem] hover:bg-[#FAFAFA]">
                  <EditIcon color="#212121" />
                  <span className="text-[#333333] text-[0.875rem] leading-[1.25rem] whitespace-nowrap">
                    Edit Condition
                  </span>
                </div>
              ),
              clickHandler: async () => {
                if (setSelectedTab) {
                  setSelectedTab(`EditCondition?conditionId=${condition._id}`);
                }
              },
            },
            {
              children: (
                <div className="flex flex-row gap-[0.5rem] items-center py-[0.5rem] px-[0.75rem] hover:bg-[#FAFAFA]">
                  <DeleteIcon color="#212121" />
                  <span className="text-[#333333] text-[0.875rem] leading-[1.25rem] whitespace-nowrap">
                    Delete Condition
                  </span>
                </div>
              ),
              clickHandler: () => handleDeleteCondition(condition._id),
            },
          ]}
        />
      </div>
    </div>
  );
}
