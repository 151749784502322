import React, { useContext, useState } from "react";
import { Buffer } from "buffer";

import KebabMenuIcon from "../../icons/KebabMenuIcon";
import ArrowDownloadIcon from "../../icons/ArrowDownloadIcon";
import { FetchContext } from "../../api/FetchContext";
import { BACKEND_URL } from "../../constants";
import { useNotificationToastDispatch } from "../../features/NotificationToast/NotificationContext";
import OpenInBrowserIcon from "../../icons/OpenInBrowserIcon";
import HeadlessDropDown from "../../components/HeadlessDropDown";

export default function DocumentVersion({
  _id,
  workflowId,
  fileName,
  isOpenDoc,
  versionNo,
  isLatest,
  createdAt,
  createdBy,
}: {
  _id: string;
  workflowId: string;
  fileName: string;
  isOpenDoc: boolean;
  versionNo: number;
  isLatest: boolean;
  createdAt: number;
  createdBy: string;
}): JSX.Element {
  const fetcher = useContext(FetchContext);
  const notificationDispatch = useNotificationToastDispatch();
  const createdDateString = new Date(createdAt * 1000).toLocaleDateString(undefined, {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  const [onHover, setHover] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const downloadDocumentHandler = async (): Promise<string> => {
    if (fetcher) {
      const res = await fetcher(`${BACKEND_URL}/api/v1/documentVersion/getDocVersionSignedUrl/${workflowId}/${_id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).catch((e) => {
        if (notificationDispatch) {
          notificationDispatch({ type: "error", message: e.message || "Something went wrong" });
        }
      });
      if (res?.signerUrl && fileName) {
        return res.signerUrl;
      }
    }
    return "";
  };

  const downloadHandler = async (): Promise<void> => {
    const signedUrl = await downloadDocumentHandler();
    if (signedUrl) {
      Office.context.ui.openBrowserWindow(signedUrl);
    }
  };

  const openDocHandler = async (): Promise<void> => {
    const signedUrl = await downloadDocumentHandler();
    if (signedUrl) {
      const response = await fetch(signedUrl, {
        method: "GET",
      });
      const base64Doc = Buffer.from(await response.arrayBuffer()).toString("base64");
      await Word.run(async (context): Promise<void> => {
        context.application.createDocument(base64Doc).open();
      });
    }
  };

  const options = [
    {
      children: (
        <>
          <ArrowDownloadIcon width="1.5rem" height="1.25rem" fill="#333" />
          <span className="text-[#333333] text-[0.875rem] leading-[1.25rem] whitespace-nowrap">Download version</span>
        </>
      ),
      clickHandler: downloadHandler,
      customClasses: "py-[0.25rem] px-[0.5rem] flex gap-[0.5rem] hover:bg-[#F5F5F5]",
    },
    {
      children: <></>,
      clickHandler: async () => {
        //**empty handler */
      },
      customClasses: "h-[0.0625rem] bg-[#E0E0E0]",
    },
    {
      children: (
        <>
          <OpenInBrowserIcon width="1.25rem" height="1.25rem" fill="#4073C4" className="flex-shrink-0" />
          <span className="text-[#4073C4] text-[0.875rem] leading-[1.25rem] whitespace-nowrap">Open in new window</span>
        </>
      ),
      clickHandler: openDocHandler,
      customClasses: "py-[0.25rem] px-[0.5rem] flex gap-[0.5rem] hover:bg-[#F5F5F5]",
    },
  ];

  return (
    <div
      className={`cursor-pointer shadow-md rounded-[0.25rem] ${
        isOpenDoc ? "border-b-[1px] border-b-solid border-b-[#0F6CBD]" : ""
      } bg-[#FFF] p-[0.5rem] flex flex-col gap-[2rem]`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => {
        setHover(false);
        setShowOptions(false);
      }}
    >
      <div className="p-[0.5rem_0.75rem] flex flex-col gap-[0.375rem] self-stretch">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row">
            <span
              className={`${
                isOpenDoc
                  ? "text-[#4073C4]"
                  : "text-[#333333] focus:text-[#4073C4] hover:text-[#4073C4] active:text-[#4073C4]"
              } text-[0.875rem] font-[600] leading-[150%]`}
            >
              Version {versionNo}
            </span>
            {isLatest ? (
              <span className="text-[#838383] text-[0.875rem] font-[400] leading-[150%] pl-[0.25rem]">(latest)</span>
            ) : (
              <></>
            )}
          </div>
          {onHover ? (
            <HeadlessDropDown
              selectElement={<KebabMenuIcon />}
              options={options}
              customContainerClasses={`p-[0.25rem] rounded-[0.25rem] focus:bg-[#F5F5F5] ${
                showOptions ? "bg-[#F5F5F5]" : ""
              } flex items-center`}
              customOptionsContainerClasses="top-[1rem] right-[1rem] shadow-sm bg-[#FFF] rounded-[0.25rem] border-[1px] border-solid border-[#D9DBDD] py-[0.5rem] gap-[0.25rem]"
            />
          ) : (
            <></>
          )}
        </div>
        <div className="flex flex-col gap-[0.125rem]">
          <span className="text-[#616161] text-[0.6875rem] font-[400] leading-[150%]">{createdDateString}</span>
          <span className="text-[#616161] text-[0.6875rem] font-[400] leading-[150%]">{createdBy}</span>
        </div>
      </div>
    </div>
  );
}
