import * as React from "react";
import { SVGProps } from "react";
const ApprovalPendingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" {...props}>
    <path
      stroke="#616161"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.175}
      d="M8 1.6v1.325M12.526 3.473l-.936.937M14.4 8h-1.324M12.526 12.527l-.936-.937M8 13.076v1.325M4.41 11.59l-.936.937M2.923 8H1.6M4.41 4.41l-.936-.937"
    />
  </svg>
);
export { ApprovalPendingIcon };
