import React from "react";
import HeadlessButton from "./HeadlessButton";

const SecondaryButton = ({
  handleClick,
  label,
  icon,
}: {
  label: string;
  icon: React.ReactNode;
  handleClick: () => Promise<void>;
}) => {
  return (
    <HeadlessButton handleClick={handleClick}>
      <div className="flex gap-[0.25rem] items-center text-[#0f6cbd] hover:text-[#115EA3]">
        {icon}
        <span className="text-[0.875rem] leading-[1.25rem]">{label}</span>
      </div>
    </HeadlessButton>
  );
};

export default SecondaryButton;
