import React, { useEffect } from "react";

import { useNavigation } from "./features/Navigation/NavigationContext";
import Comments from "./Pages/Comments";
import ExtractTerms from "./Pages/ExtractTerms/ExtractTerms";
import Overview from "./Pages/Overview";
import getWebDavPayload from "./utils/getWebDavPayload";
import changeTrackingMode from "./utils/wordApi/changeTrackingMode";
import ClauseApprovalsPage from "./Pages/ClauseApprovals";
import RequestApproval from "./Pages/RequestApproval";
import Playbook from "./Pages/Playbook";
import { ParagraphsProvider } from "./Providers/ParagraphsProvider";

function WorkflowRoutes(): JSX.Element {
  const selectedtab = useNavigation();
  const { workflowId } = getWebDavPayload(Office?.context?.document?.url || "");

  useEffect(() => {
    Word.run(async (context) => changeTrackingMode(context, "TrackAll"));
  }, []);

  switch (selectedtab) {
    case "Overview":
      return <Overview />;
    case "Comments":
      return <Comments />;
    case "Clause Approvals":
      return <ClauseApprovalsPage workflowId={workflowId} />;
    case "Create Clause Approval":
      return <RequestApproval workflowId={workflowId} />;
    case "AI Assist":
      return <ExtractTerms />;
    case "Playbook":
      return (
        <ParagraphsProvider>
          <Playbook />
        </ParagraphsProvider>
      );
    default:
      return <div>not found</div>;
  }
}

export default WorkflowRoutes;
