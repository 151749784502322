import React, { SVGProps } from "react";

const ArrowLeftIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" {...props}>
    <path
      stroke={props.fill}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8.542 5.625 3.958 10l4.584 4.375M16.042 10H4.167"
    />
  </svg>
);

export default ArrowLeftIcon;
