import React, { SetStateAction, useState } from "react";
import { Checkbox } from "@fluentui/react";

import DuplicateIcon from "../../icons/DuplicateIcon";
import Button from "../../components/Button";
import { Tab, TabList } from "@fluentui/react-tabs";

export default function JustificationBox({
  note,
  justification,
  shouldShowComment,
  shouldAddComment,
  setShouldAddComment,
}: {
  note: string;
  justification: string;
  shouldShowComment: boolean;
  shouldAddComment: boolean;
  setShouldAddComment: React.Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
  const [standardPositionTab, setStandardPositionTab] = useState("note");
  return (
    <div className="rounded-[0.25rem] border-[1px] border-solid border-[#E6E6E6] bg-[#F8F8Fa]">
      <TabList
        selectedValue={standardPositionTab}
        onTabSelect={(_event, data) => {
          setStandardPositionTab(data.value as string);
        }}
      >
        <Tab value="note">
          <div className="flex flex-col items-center justify-center">
            <div className="p-[0.75rem]">
              <span
                className={`${
                  standardPositionTab === "note" ? "text-[#242424] font-600" : ""
                } text-[0.875rem] leading-[1.25rem]`}
              >
                Note to counterparty
              </span>
            </div>
            {standardPositionTab === "note" ? <div className="w-[80%] h-[2px] bg-[#0F6CBD]"></div> : <></>}
          </div>
        </Tab>
        <Tab value="justification">
          <div className="flex flex-col items-center justify-center">
            <div className="p-[0.75rem]">
              <span
                className={`${
                  standardPositionTab === "justification" ? "text-[#242424] font-600" : ""
                } text-[0.875rem] leading-[1.25rem]`}
              >
                Justification
              </span>
            </div>
            {standardPositionTab === "justification" ? <div className="w-[80%] h-[2px] bg-[#0F6CBD]"></div> : <></>}
          </div>
        </Tab>
      </TabList>
      <div className="rounded-b-[0.25rem] p-[0.75rem] flex flex-col items-start gap-3">
        <span className="text-[0.875rem] leading-[1.25rem] font-[400]">
          {standardPositionTab === "note" ? note : justification}
        </span>
        {standardPositionTab === "note" ? (
          <div className="self-stretch flex justify-between">
            {shouldShowComment ? (
              <div className="flex gap-1">
                <Checkbox
                  checked={shouldAddComment}
                  onChange={(e: any) => {
                    setShouldAddComment(e.target.checked);
                  }}
                />
                <span>Add as comment</span>
              </div>
            ) : (
              <></>
            )}
            <Button
              variant="secondary"
              label=""
              leftIcon={<DuplicateIcon fill="#616161" />}
              customClasses="border-none !bg-inherit !p-0"
              handleClick={async () => {
                navigator.clipboard.writeText(note);
              }}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
