import React from "react";
import Button from "./Button";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  primaryAction?: {
    label: string;
    onClick: () => Promise<void>;
  };
  secondaryAction?: {
    label: string;
    onClick: () => Promise<void>;
  };
  stackButtons?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  title,
  children,
  primaryAction,
  secondaryAction,
  onClose,
  stackButtons,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg w-[90%] max-w-md">
        <div className="pt-4 px-4 flex justify-between items-center relative">
          <h2 className="text-lg font-semibold">{title}</h2>
          <button
            onClick={onClose}
            className="absolute top-2 right-2 hover:text-gray-700 w-6 h-6 text-2xl text-[#838383]"
          >
            &times;
          </button>
        </div>
        <div className="pt-4 px-4">{children}</div>
        <div className={`p-4 flex ${stackButtons ? "flex-col space-y-2" : "flex-row justify-between space-x-2"}`}>
          {primaryAction && (
            <Button
              variant="primary"
              label={primaryAction.label}
              handleClick={async (e) => {
                e.preventDefault();
                await primaryAction.onClick();
              }}
              customClasses={stackButtons ? "w-full" : "flex-1"}
            />
          )}
          {secondaryAction && (
            <Button
              variant="secondary"
              label={secondaryAction.label}
              handleClick={async (e) => {
                e.preventDefault();
                await secondaryAction.onClick();
              }}
              customClasses={stackButtons ? "w-full" : "flex-1"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
