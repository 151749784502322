import React, { useContext, useState } from "react";

import ChevronDownIcon from "../../icons/ChevronDownIcon";
import ChevronUpIcon from "../../icons/ChevronUpIcon";
import Button from "../../components/Button";
import JustificationBox from "./JustificationBox";
import isDeviationCompliant from "../../utils/isCompliant";
import getMatchingParagraph from "../../utils/wordApi/getMatchingParagraph";
import { FetchContext } from "../../api/FetchContext";
import { insertPositionApi } from "./usePosition";
import { useWorkflowData } from "../../features/WorkflowDataProvider/WorkflowDataProvider";

export default function StandardPosition({
  deviationId,
  isActionsDisabled,
}: {
  deviationId: string;
  isActionsDisabled: boolean;
}): JSX.Element {
  const fetcher = useContext(FetchContext);

  const { data: workflowData, reload: reloadWorkflowData } = useWorkflowData();
  const workflow = workflowData.workflowResponse.workflow;
  const { deviations: allDeviations, playbooks } = workflowData;
  const deviation = allDeviations.find((deviation) => deviation._id === deviationId);
  const positions = playbooks.find((playbook: any) => playbook._id === deviation.playBookId._id)?.positions;
  const standardPositions = positions?.filter((position: any) => position.type === "standard") || [];
  const standardPosition = standardPositions[0];

  const [shouldShowStandardPosition, setShouldShowStandardPosition] = useState<boolean>(false);
  const [shouldAddComment, setShouldAddComment] = useState<boolean>(true);

  return (
    <div className="flex flex-col">
      <div className="rounded-[0.25rem] bg-[#fff] border-[1px] border-solid border-[#D9DBDD] p-[0.75rem] flex flex-col">
        <div
          className="cursor-pointer self-stretch flex justify-between"
          onClick={(e) => {
            e.stopPropagation();
            setShouldShowStandardPosition((prev) => !prev);
          }}
        >
          <div className="px-[0.125rem]">
            <span className="text-[0.875rem] leading-[1.25rem] font-[600]">Standard position</span>
          </div>
          {shouldShowStandardPosition ? <ChevronUpIcon fill="#616161" /> : <ChevronDownIcon fill="#616161" />}
        </div>
      </div>
      {shouldShowStandardPosition ? (
        <div className="rounded-b-[0.25rem] bg-[#fff] border-x-[1px] border-b-[1px] border-solid border-[#E6E6E6] p-[0.75rem] flex flex-col gap-4">
          <span className="text-[0.875rem] leading-[1.25rem]">{standardPosition.clause}</span>
          <JustificationBox
            note={standardPosition.note}
            justification={standardPosition.justification}
            shouldShowComment={!isDeviationCompliant(deviation)}
            shouldAddComment={shouldAddComment}
            setShouldAddComment={setShouldAddComment}
          />
          {deviation.isMet || isActionsDisabled ? (
            <div className="w-[180px]">
              <Button
                variant="secondary"
                label="Copy standard position"
                handleClick={async () => {
                  navigator.clipboard.writeText(standardPosition.clause);
                }}
              />
            </div>
          ) : (
            <div className="w-[180px]">
              <Button
                variant="primary"
                label="Insert standard position"
                handleClick={async () => {
                  if (!fetcher) {
                    return;
                  }
                  const commentId = await Word.run(async (context: Word.RequestContext): Promise<string> => {
                    const paragraph = await getMatchingParagraph(context, deviation.docText, deviation.paragraphIndex);
                    if (!paragraph) {
                      return "";
                    }
                    paragraph.insertText(standardPosition.clause, "Replace");
                    if (shouldAddComment) {
                      paragraph.getRange().insertComment(standardPosition.note);
                    }
                    const comment = paragraph
                      .getRange()
                      .insertComment(`(Internal) (approval) ${standardPosition.justification}`);
                    await context.sync();
                    return comment.id;
                  });
                  if (!commentId) {
                    return;
                  }
                  await insertPositionApi({
                    fetcher,
                    workflowId: workflow._id,
                    deviationId: deviation._id,
                    type: "standard",
                    commentId,
                    positionId: standardPosition._id,
                  });
                  await reloadWorkflowData();
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
