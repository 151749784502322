import React, { useEffect, useState } from "react";

import "./NotificationToast.css";
import { NotificationData } from "../../features/NotificationToast/NotificationContext";

function NotificationToast({ message, timeOut, color, type, ...props }: NotificationData): JSX.Element {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => setIsVisible(false), timeOut);
    return () => clearTimeout(timeoutId);
  }, [message, timeOut]);

  if (isVisible) {
    return (
      <div className="z-[100] w-full flex items-center justify-center absolute top-[90vh]">
        <div className={`${color ? color : "light"}-toast notification-container w-fit`}>
          {(() => {
            if (type && type === "success") {
              return (
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="8" cy="8" r="8" fill="#50A97C" />
                  <path
                    d="M5.16797 8.50065L6.66797 10.1673L10.8346 5.83398"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              );
            } else if (type && type === "error") {
              return (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 1.875C5.51992 1.875 1.875 5.51992 1.875 10C1.875 14.4801 5.51992 18.125 10 18.125C14.4801 18.125 18.125 14.4801 18.125 10C18.125 5.51992 14.4801 1.875 10 1.875ZM10 14.3715C9.84548 14.3715 9.69444 14.3257 9.56596 14.2398C9.43748 14.154 9.33735 14.032 9.27822 13.8892C9.21909 13.7465 9.20362 13.5894 9.23376 13.4378C9.26391 13.2863 9.33831 13.1471 9.44757 13.0378C9.55683 12.9285 9.69604 12.8541 9.84759 12.824C9.99913 12.7939 10.1562 12.8093 10.299 12.8685C10.4417 12.9276 10.5637 13.0277 10.6496 13.1562C10.7354 13.2847 10.7812 13.4357 10.7812 13.5902C10.7812 13.7974 10.6989 13.9961 10.5524 14.1427C10.4059 14.2892 10.2072 14.3715 10 14.3715ZM10.8484 6.51406L10.6242 11.2797C10.6242 11.4454 10.5584 11.6044 10.4412 11.7216C10.3239 11.8388 10.165 11.9047 9.99922 11.9047C9.83346 11.9047 9.67449 11.8388 9.55728 11.7216C9.44007 11.6044 9.37422 11.4454 9.37422 11.2797L9.15 6.51641V6.51445C9.14509 6.39997 9.16339 6.28568 9.20381 6.17846C9.24424 6.07124 9.30594 5.97331 9.38521 5.89056C9.46448 5.80782 9.55967 5.74197 9.66506 5.69699C9.77045 5.65201 9.88385 5.62882 9.99844 5.62882C10.113 5.62882 10.2264 5.65201 10.3318 5.69699C10.4372 5.74197 10.5324 5.80782 10.6117 5.89056C10.6909 5.97331 10.7526 6.07124 10.7931 6.17846C10.8335 6.28568 10.8518 6.39997 10.8469 6.51445L10.8484 6.51406Z"
                    fill="#D77C65"
                  />
                </svg>
              );
            } else if (type && type === "info") {
              return (
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.0013 17.3327C4.3988 17.3327 0.667969 13.6018 0.667969 8.99935C0.667969 4.39685 4.3988 0.666016 9.0013 0.666016C13.6038 0.666016 17.3346 4.39685 17.3346 8.99935C17.3346 13.6018 13.6038 17.3327 9.0013 17.3327ZM8.16797 8.16602V13.166H9.83463V8.16602H8.16797ZM8.16797 4.83268V6.49935H9.83463V4.83268H8.16797Z"
                    fill={color ? "#ffffff" : "#4945C6"}
                  />
                </svg>
              );
            } else {
              return <></>;
            }
          })()}

          <p className={`${props.cta ? "" : "w-[437px] max-w-[70vw]"}`}>
            {message}{" "}
            {props.cta && (
              <span role="button" className="text-[#ffffff]  mx-[20px] text-[14px] font-[600]" onClick={props.cta}>
                {props.ctaMessage}
              </span>
            )}
          </p>

          <svg
            onClick={() => {
              setIsVisible(false);
            }}
            role="button"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.375 5.625L5.625 14.375"
              stroke="#A5A7AB"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.625 5.625L14.375 14.375"
              stroke="#A5A7AB"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default NotificationToast;
