import { useState, useEffect } from "react";

export default function useDocumentSelection(): { docText: string } {
  const [docText, setDocText] = useState<string>("");

  const getSelectionFromDocument = async () => {
    const selectedText = await Word.run(async (context): Promise<string> => {
      const selectedRange = context.document.getSelection();
      context.load(selectedRange, "text");
      selectedRange.load("text");
      await context.sync();
      return selectedRange.text;
    });
    setDocText(selectedText);
  };

  useEffect(() => {
    getSelectionFromDocument();
    const handler = () => {
      getSelectionFromDocument();
    };
    Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, handler);
    return () => {
      Office.context.document.removeHandlerAsync(Office.EventType.DocumentSelectionChanged, { handler });
    };
  }, []);

  return { docText };
}
