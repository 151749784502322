import React from "react";
import RenderErrors from "./RenderErrors";

const TextArea = ({
  label,
  fieldDescription,
  required = false,
  description,
  setDescription,
  errors,
  fieldName,
}: {
  errors: { [key: string]: string[] } | null;
  fieldName: string;
  label: string;
  fieldDescription: string;
  required?: boolean;
  description: string;
  // eslint-disable-next-line no-unused-vars
  setDescription: (description: string) => void;
}) => {
  return (
    <div className="flex flex-col gap-[1rem]">
      <div className="flex flex-col gap-[0.5rem]">
        <div className="flex flex-col gap-[0.25rem]">
          <div className="flex gap-[0.25rem]">
            <span className="text-[0.875rem] leading-[1.25rem]">{label}</span>
            <span className={`text-[0.875rem] text-[#838383] leading-[1.25rem] ${required ? "text-[#B10E1C]" : ""}`}>
              {required ? "*" : "(Optional)"}
            </span>
          </div>
          <span className="text-[0.875rem] text-[#838383] leading-[1.25rem]">{fieldDescription}</span>
        </div>
        <textarea
          value={description}
          className="w-full border-[1px] border-solid border-[#D1D1D1] rounded-[0.25rem] p-[0.5rem] text-[0.875rem] leading-[1.25rem] placeholder:text-[#707070] focus:outline-none"
          onChange={(e) => setDescription(e.target.value)}
          placeholder="About the field"
        />
        {errors && errors[fieldName] && <RenderErrors errors={errors} fieldName={fieldName} />}
      </div>
    </div>
  );
};

export default TextArea;
