import joi from "joi";

export const tableSchema = joi
  .object({
    name: joi.string().required(),
    tableColumns: joi.array().items(
      joi
        .object({
          value: joi.string(),
          type: joi.string().valid("string"),
        })
        .options({ presence: "required" })
        .min(1)
    ),
  })
  .options({
    presence: "required",
  });
