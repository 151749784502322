import React, { SVGProps } from "react";

const ArrowDownloadIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} fill="none" {...props}>
    <path
      fill={props.fill}
      d="M15.5 16.999a.5.5 0 0 1 .09.992l-.09.008h-11a.5.5 0 0 1-.09-.992l.09-.008h11ZM10 2a.5.5 0 0 1 .492.41l.008.09-.004 11.794 3.645-3.648a.5.5 0 0 1 .638-.059l.07.058a.5.5 0 0 1 .058.638l-.058.07-4.492 4.5a.497.497 0 0 1-.255.136l-.106.01a.5.5 0 0 1-.395-.194l-4.457-4.452a.5.5 0 0 1 .637-.766l.07.058 3.645 3.641L9.5 2.501A.5.5 0 0 1 10 2Z"
    />
  </svg>
);

export default ArrowDownloadIcon;
