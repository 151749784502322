import React, { useState } from "react";

import CloseActionIcon from "../../icons/CloseActionIcon";
import ChevronDownIcon from "../../icons/ChevronDownIcon";
import ChevronUpIcon from "../../icons/ChevronUpIcon";
import PostIcon from "../../icons/PostIcon";
import { Comment, User } from "./interface";
import CommentInput from "./CommentInput";
import Button from "../../components/Button";
import CommentRenderer from "./CommentRenderer";
import convertMentionText from "../../utils/convertMentionText";
import CommentOptions from "./CommentOptions";
import findCommentById from "../../utils/wordApi/findCommentById";

export default function CommentBox({
  comment,
  users,
  reloadComments,
}: {
  comment: Comment;
  users: Array<User>;
  reloadComments: () => Promise<void>;
}): JSX.Element {
  const [showAllReplies, setShowAllReplies] = useState<boolean>(comment.replies.length < 2);
  const [replyText, setReplyText] = useState<string>("");
  const shouldShowSubmit = Boolean(replyText);

  const handleReplyToComment = async (e: React.FormEvent) => {
    e.preventDefault();
    await Word.run(async (context): Promise<void> => {
      const commentContext = await findCommentById(context, comment.id);
      if (commentContext) {
        const transformedReplyText = convertMentionText(users, replyText);
        commentContext.reply(transformedReplyText);
        commentContext.load();
        await context.sync();
      }
    });
    setReplyText("");
    await reloadComments();
  };

  const handleEditComment = async () => {
    /**TODO */
  };

  return (
    <form
      className="cursor-pointer bg-[#FFF] shadow-md rounded-[0.25rem] p-[0.75rem] flex flex-col items-start gap-[0.5rem]"
      onClick={async () => {
        await Word.run(async (context) => {
          const commentRef = await findCommentById(context, comment.id);
          if (!commentRef) {
            await reloadComments();
            return;
          }
          commentRef.getRange().select();
        });
      }}
    >
      <CommentRenderer
        key={0}
        authorName={comment.author.name}
        creationDate={comment.dateUtc}
        text={comment.content}
        rightMenu={
          <CommentOptions
            isResolved={comment.isResolved}
            commentId={comment.id}
            handleEditCommentProp={handleEditComment}
            refreshComments={reloadComments}
          />
        }
        isLastComment={comment.replies.length === 0}
      />
      {showAllReplies ? (
        <>
          {comment.replies.map((reply, i) => (
            <CommentRenderer
              key={i + 1}
              authorName={reply.author.name}
              creationDate={reply.dateUtc}
              text={reply.content}
              isLastComment={i + 1 === comment.replies.length}
            />
          ))}
        </>
      ) : (
        <></>
      )}
      {!comment.isResolved && showAllReplies ? (
        <CommentInput
          key={comment.id}
          isNewComment={false}
          users={users}
          commentText={replyText}
          setCommentText={setReplyText}
          placeholder="Reply"
        />
      ) : (
        <></>
      )}
      {comment.replies.length > 1 ? (
        <div
          className="cursor-pointer flex gap-[0.25rem]"
          onClick={(e) => {
            e.stopPropagation();
            setShowAllReplies((prev) => !prev);
          }}
        >
          <span className="text-[#0F6CBD] text-[0.875rem] font-[400] leading-[150%]">
            {comment.replies.length} Replies
          </span>
          {showAllReplies ? (
            <ChevronUpIcon width="1.25rem" height="1.25rem" fill="#0F6CBD" />
          ) : (
            <ChevronDownIcon width="1.25rem" height="1.25rem" fill="#0F6CBD" />
          )}
        </div>
      ) : (
        <></>
      )}
      {shouldShowSubmit && replyText.length > 0 ? (
        <div className="self-stretch flex justify-end gap-[0.25rem]">
          <Button
            key="clear"
            variant="secondary"
            label=""
            leftIcon={<CloseActionIcon width="1.25rem" height="1.25rem" fill="#616161" />}
            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
            handleClick={async (_e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              setReplyText("");
            }}
            isDisabled={replyText === ""}
          />
          <Button
            key="sendReply"
            buttonType="submit"
            variant="primary"
            label=""
            leftIcon={<PostIcon width="1.25rem" height="1.25rem" fill="none" />}
            handleClick={handleReplyToComment}
            isDisabled={replyText === ""}
          />
        </div>
      ) : (
        <></>
      )}
    </form>
  );
}
